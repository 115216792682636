import { createContainer } from '@Components/miscComponents';
import { createLabel } from '@Components/fields';
import { createProxyConfig, createStore } from '@Components/storeComponents';
import { createCheckbox, createCombo, createTextField } from '@Components/fields';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';
import { PTYPE_COMBO_ITEMS_ACTION } from '@UIkit/plugins';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createModalForm } from '@UIkit/components/panels';
import './ModuleFilterForm.scss';

Ext.apply(BUTTON_CLS, {
	chip_btn: 'ui-button-chip',
	expand_btn: 'ui-button-expand'
});

Ext.define('UI.components.moduleFilterForm', {
	extend: 'Ext.form.Panel',
	baseCls: 'ui-filter',
	backdropCls: 'ui-filter-backdrop',
	saveComboCls: 'ui-filter-saved-combobox',
	hiddenFieldCls: 'ui-filter-hidden-field',
	autoSearchCheckBox: null,

	searchBtnConf: null,
	resetBtnConf: null,
	saveBtnConf: null,
	saveComboConf: null,

	//saveFilter: {name: 'filter_name_in_extra_data'}, что бы активировать сохранение фильтров нужен name
	saveFilter: null,

	region: 'north',
	collapseMode: 'placeholder',
	header: false,
	hideCollapseTool: true,
	maxPanelHeightPercent: 0.7,
	padding: 24,
	chipMaxWidth: 400,
	showExtendedFilterButtons: true,
	extendedFilterButtonWidth: 200,
	autosearchEnabled: true,
	scrollable: 'vertical',
	onFloatedPointerEvent: () => {},
	isLayoutRoot: () => true,

	initComponent() {
		let __self = this;
		__self.setOwnConfig();
		__self.callParent();
		__self.afterInit();
	},

	/**
	 * Sets default config and process external params in current configuration
	 */
	setOwnConfig() {
		let __self = this;
		let externalConfig = __self.externalConfig;
		delete __self.externalConfig;
		let externalParams = __self.externalParams;
		delete __self.externalParams;
		//Записываем конфиги по умолчанию
		Ext.merge(__self, {
			autoCloseTime: __self.getAutoCloseTime(),
			collapsible: edi.constants.FILTERS_COLLAPSIBLE,
			disableChips: !edi.constants.FILTERS_COLLAPSIBLE,
			collapsed: edi.constants.FILTERS_COLLAPSIBLE ? edi.constants.FILTERS_COLLAPSED : false,
			fieldWidthInModalBase: MODAL_SIZE.widthSmall,
			fireSearch: externalParams?.fireSearch,
			customButtons: externalParams?.customButtons,
			externalOnFieldChange: externalParams?.onChange,
			searchBtnConf: externalParams?.searchBtnConf || {},
			resetBtnConf: externalParams?.resetBtnConf || {},
			saveBtnConf: externalParams?.saveBtnConf || {},
			saveComboConf: externalParams?.saveComboConf || {}
		});
		//Перетираем конфиги теми, что были переданы в функцию создания компонента
		Ext.merge(__self, externalConfig);

		Object.values(__self.formItemsMap || {}).forEach((item) => {
			if (item?.hideFromPanel === true) {
				item.addCls(__self.hiddenFieldCls);
			}
		});

		__self.configureAddFilterButton();
		__self.configureChipsContainer();
		__self.configureTools();
		__self.configureBottomToolbar();
		__self.configureDockedItems();
		__self.configureListeners();
		__self.configurePlaceholder();
	},

	/**
	 * Configure "quick add" button after chips
	 */
	configureAddFilterButton() {
		let __self = this;

		__self.addFilterButton = __self.collapsible
			? createButton({
					cls: [BUTTON_CLS.secondary, BUTTON_CLS.withoutArrow],
					text: edi.i18n.getMessage('form.btn.add.filter'),
					glyph: edi.constants.ICONS.PLUS,
					hideTrigger: true,
					menu: Ext.menu.Menu({
						plain: true,
						hideMode: 'display',
						items: []
					})
			  })
			: null;
	},

	/**
	 * Configure chips container and place "quick add" button into it
	 */
	configureChipsContainer() {
		let __self = this;
		__self.chipsContainer = __self.collapsible
			? createContainer({
					layout: 'column',
					width: '100%',
					maxWidth: '100%',
					margin: '0 0 0 8'
			  })
			: null;

		if (__self.chipsContainer && __self.addFilterButton) {
			__self.chipsContainer.add(__self.addFilterButton);
		}
	},

	/**
	 * Configure tools (filter button, chipsContainer and additional tools from config)
	 */
	configureTools() {
		let __self = this;

		let additionalToolsFromConfig = __self.additionalTools;
		delete __self.additionalTools;

		let tools = undefined;
		if (__self.collapsible) {
			tools = [__self.chipsContainer];

			if (additionalToolsFromConfig && Ext.isArray(additionalToolsFromConfig)) {
				tools = tools.concat(additionalToolsFromConfig);
			}

			if (__self.showExpandCollapseButton === true) {
				tools.push(
					createButton({
						cls: [BUTTON_CLS.secondary, 'edi-button-filter'],
						glyph: edi.constants.ICONS.FILTER_LIST,
						hidden:
							!__self.showExpandCollapseButton ||
							!!__self.getAutoCloseTime() ||
							(__self.hasOwnProperty('collapsible') && !__self.collapsible),
						handler() {
							__self.toggleHandler();
						}
					})
				);
			}
		} else if (additionalToolsFromConfig && Ext.isArray(additionalToolsFromConfig)) {
			tools = additionalToolsFromConfig;
		}

		__self.__tools = tools;
	},

	/**
	 * Creates search button
	 * @returns {Object}
	 */
	createSearchBtn: function () {
		const __self = this;
		return createButton(
			Object.assign(
				{
					cls: BUTTON_CLS.primary,
					margin: '0 16 0 0',
					text: edi.i18n.getMessage('form.btn.search'),
					formBind: true,
					disabled: true,
					handler() {
						if (!__self.isDestroyed && !__self.collapsed) {
							__self.toggleHandler();
						}
						if ('function' == typeof __self.fireSearch) {
							__self.fireSearch();
						}
					}
				},
				__self.searchBtnConf
			)
		);
	},

	/**
	 * Creates reset buttons
	 * @returns {Object}
	 */
	createResetBtn: function () {
		const __self = this;
		return createButton(
			Object.assign(
				{
					cls: BUTTON_CLS.secondary,
					margin: '0 16 0 0',
					text: edi.i18n.getMessage('form.btn.reset'),
					handler() {
						const autoSearchCheckBoxValue = __self.autosearchEnabled;
						__self.getForm().reset();
						__self.processHeaderChips(__self, __self.getValues());

						if (typeof __self.setFormDefaults === 'function') {
							__self.setFormDefaults();
						}
						__self.autoSearchCheckBox?.setValue(autoSearchCheckBoxValue);
						if ('function' == typeof __self.fireSearch) {
							__self.fireSearch();
						}
					}
				},
				__self.resetBtnConf
			)
		);
	},

	/**
	 * Creates auto save checkbox
	 * @returns {Object}
	 */
	createAutoSearchCheckbox: function () {
		const __self = this;
		return (__self.autoSearchCheckBox = createCheckbox({
			boxLabel: edi.i18n.getMessage('filter.form.auto.search'),
			name: 'filterFormAutoSearchCheckbox',
			qtipText: edi.i18n.getMessage('filter.form.auto.search.hint'),
			margin: '0 24 0 0',
			ignoreAutoFilter: true,
			ignoreChips: true,
			checked: __self.autosearchEnabled !== false, // выключение автопоиска работает только через явный запрет
			listeners: {
				change: function (comp, checked) {
					__self.autosearchEnabled = !!checked;
				}
			}
		}));
	},

	/**
	 * Creates button and combo for saving filters
	 * @returns {{saveCombo?: {Object}, saveBtn?: {Object}}}
	 */
	createSaveFilterItems: function () {
		const __self = this;
		const saveFilterConfig = __self.saveFilter && 'object' == typeof __self.saveFilter ? __self.saveFilter : null;
		if (!saveFilterConfig) {
			return { saveBtn: null, saveCombo: null };
		}

		let filterData = [];
		if (edi.utils.getObjectProperty(edi.utils.getData('filtersSave'), saveFilterConfig.name)) {
			filterData = edi.utils.getData(`filtersSave.${saveFilterConfig.name}`);
			filterData = filterData && typeof filterData === 'string' ? JSON.parse(filterData) : filterData;
		}

		let modalSave = function (dataFilter, valuesFilter, config, callback) {
			let gridName = config?.name;

			let save = function () {
				let formValues = form.getValues();
				let valuesFilter = edi.filters.getValueWithRange(__self);
				let data = dataFilter || [];
				let nextId = data.length ? data[data.length - 1].id + 1 : 1;

				let indexRecord = data.findIndex((item) => item.name === formValues.name);
				if (indexRecord !== -1) {
					data[indexRecord] = Ext.merge(data[indexRecord], {
						createDate: new Date().getTime(),
						filters: valuesFilter
					});

					edi.core.confirm(null, 'filter.form.comfirm.record.already.exists', function () {
						edi.utils.setData(`filtersSave.${gridName}`, JSON.stringify(data), callback);
					});
				} else {
					data.push({
						id: nextId,
						name: formValues.name,
						createDate: new Date().getTime(),
						filters: valuesFilter
					});
					edi.utils.setData(`filtersSave.${gridName}`, JSON.stringify(data), callback);
				}

				modal.destroy();
			};

			let nameField;
			let form = createModalForm({
				items: [
					(nameField = createTextField({
						fieldLabel: edi.i18n.getMessage('filter.form.saveFilter.field.name'),
						name: 'name',
						allowBlank: false,
						listeners: {
							specialkey: function (field, e) {
								if (e.getKey() === e.ENTER) {
									save();
								}
							}
						}
					}))
				]
			});

			let modal = createModalPanel({
				title: edi.i18n.getMessage('filter.form.saveFilter.modal.title'),
				width: MODAL_SIZE.widthSmall,
				listeners: {
					show: function () {
						nameField.focus();
					}
				},
				items: [form],
				buttonsBefore: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('form.btn.save'),
						formBind: true,
						disabled: true,
						bindToForm: form,
						handler: function () {
							save();
						}
					})
				]
			});
			modal.show();
		};

		let saveBtn = createButton(
			Object.assign(
				{
					cls: BUTTON_CLS.secondary,
					margin: '0 16 0 0',
					text: edi.i18n.getMessage('filter.form.saveFilter.btn'),
					disabled: filterData.length === 5,
					handler() {
						modalSave(filterData, __self.getValues(), saveFilterConfig, function () {
							comboStore.loadData(filterData);
							saveBtn.setDisabled(comboStore.getCount() === 5);
							saveCombo.setDisabled(comboStore.getCount() === 0);
						});
					}
				},
				__self.saveBtnConf
			)
		);

		let comboStore = createStore({
			proxy: createProxyConfig({
				type: 'memory',
				data: filterData
			}),
			fields: ['id', 'name'],
			listeners: {
				load(store) {
					if (typeof saveCombo?.setDisabled === 'function') {
						saveCombo.setDisabled(store.getCount() <= 0);
					}
				}
			}
		});

		let saveCombo = createCombo(
			Ext.merge(
				{
					fieldLabel: edi.i18n.getMessage('filter.form.saved.filters'),
					editable: false,
					cls: __self.saveComboCls,
					name: 'selectSaveFilter',
					ignoreAutoFilter: true,
					ignoreChips: true,
					width: 240,
					emptyText: edi.i18n.getMessage('filter.form.saved.filters'),
					store: comboStore,
					disabled: comboStore.getCount() === 0,
					plugins: [
						{
							ptype: PTYPE_COMBO_ITEMS_ACTION,
							glyph: edi.constants.ICONS.DELETE,
							handler: function (btn, record) {
								let deletedItem = filterData.find((item) => item.id === record.get('id'));
								deletedItem ? Ext.Array.remove(filterData, deletedItem) : undefined;
								edi.utils.setData(
									`filtersSave.${saveFilterConfig.name}`,
									JSON.stringify(filterData),
									function () {
										comboStore.loadData(filterData);
										saveBtn.setDisabled(comboStore.getCount() === 5);
										saveCombo.setDisabled(comboStore.getCount() === 0);
									}
								);
							}
						}
					],
					listeners: {
						select: function (comp, record) {
							let recordData = record && record.getData ? record.getData() : null;
							recordData?.filters
								? edi.filters.defaultRestoreFilterValuesMethod(__self, recordData?.filters)
								: null;
							comp.setValue(null);
						}
					}
				},
				__self.saveComboConf
			)
		);

		return { saveBtn, saveCombo };
	},

	/**
	 * Configure bottom toolbar for form (default buttons, "save filter" and customButtons from config)
	 */
	configureBottomToolbar() {
		let __self = this;

		let defaultButtons = [__self.createSearchBtn(), __self.createResetBtn()];
		const { saveBtn, saveCombo } = __self.createSaveFilterItems();
		if (!!saveBtn) {
			defaultButtons.push(saveBtn);
		}

		let filterButtons = [];
		if ('object' == typeof __self.customButtons) {
			if (Ext.isArray(__self.customButtons?.replaceDefault)) {
				filterButtons = filterButtons.concat(__self.customButtons.replaceDefault);
			} else {
				if (Ext.isArray(__self.customButtons?.beforeDefault)) {
					filterButtons = filterButtons.concat(__self.customButtons.beforeDefault);
				}
				filterButtons = filterButtons.concat(defaultButtons);
				if (Ext.isArray(__self.customButtons?.afterDefault)) {
					filterButtons = filterButtons.concat(__self.customButtons.afterDefault);
				}
			}
		} else {
			filterButtons = filterButtons.concat(defaultButtons);
		}

		filterButtons.push('->');

		let showAutoSearch = __self.hasOwnProperty('toggleAutoSearch')
			? __self.toggleAutoSearch
			: edi.constants.TOGGLE_FILTER_AUTOSEARCH;
		if (showAutoSearch) {
			filterButtons.push(__self.createAutoSearchCheckbox());
		}

		if (!!saveCombo) {
			filterButtons.push(saveCombo);
		}

		__self.filterButtons = filterButtons;
	},

	/**
	 * Configure bottom docked toolbars (filterButtons and collapse\expand)
	 */
	configureDockedItems() {
		let __self = this;
		__self.dockedItems = [
			__self.collapsible !== false
				? {
						xtype: 'toolbar',
						cls: `${__self.baseCls}-toolbar-collapse`,
						layout: {
							type: 'hbox',
							align: 'bottom',
							pack: 'center'
						},
						dock: 'bottom',
						height: 0,
						items: [
							__self.createExtendedFilterButton({
								glyph: edi.constants.ICONS.ARROW_DOWN_UP,
								text: edi.i18n.getMessage('action.collapse_extended_filter')
							})
						]
				  }
				: null,
			{
				xtype: 'toolbar',
				dock: 'bottom',
				ui: 'footer',
				cls: `${__self.baseCls}-toolbar-buttons`,
				listeners: edi.constants.FILTERS_COLLAPSIBLE
					? {
							render: function (footer) {
								footer.getEl().on('mouseenter', function () {
									if (__self.autoCloseTime) {
										__self.preventClose();
									}
								});
								footer.getEl().on('mouseleave', function () {
									__self.autoClose();
								});
							}
					  }
					: undefined,
				items: __self.filterButtons
			}
		];
	},

	/**
	 * Configure listeners for filter panel and process additional "render" listener from config
	 */
	configureListeners() {
		let __self = this;

		__self.listeners = __self.listeners || {};

		__self.originalRenderFromConfig = __self.listeners.render || (() => {});
		__self.listeners.render = function (p, eOpts) {
			if (edi.constants.FILTERS_COLLAPSIBLE) {
				__self.onPanelRender(p);
			}
			__self.originalRenderFromConfig(p, eOpts);
		};

		__self.keyNav = Ext.apply(
			{
				forceKeyDown: true,
				target: Ext.getDoc(),
				enabled: false,
				key: true,
				esc: {
					handler(e) {
						e.browserEvent.stopPropagation();
						!__self.isDestroyed && !__self.collapsed && __self.escHandler();
					},
					scope: __self,
					defaultEventAction: false
				}
			},
			__self.keyNavConfig
		);

		__self.listeners.reset = __self.onFormReset;
		__self.listeners.validitychange = __self.onFormValidityChange;
		__self.listeners.float = __self.onPanelFloat;
		__self.listeners.unfloat = __self.onPanelUnfloat;
		__self.listeners.beforedestroy = __self.onBeforeDestroy;
		__self.listeners.show = __self.onPanelShow;
		__self.listeners.hide = __self.onPanelHide;
	},

	/**
	 * Creates extended filter collapse/expand button
	 * @param cfg
	 * @return {null|*}
	 */
	createExtendedFilterButton(cfg) {
		let __self = this;
		if (__self.showExtendedFilterButtons !== true) {
			return null;
		}

		let defaultCfg = {
			cls: BUTTON_CLS.expand_btn,
			glyph: edi.constants.ICONS.ARROW_UP_DOWN,
			iconAlign: 'right',
			text: edi.i18n.getMessage('action.expand_extended_filter'),
			handler() {
				__self.toggleHandler();
			}
		};

		let effectiveConf = Ext.merge({}, defaultCfg, cfg);
		return createButton(effectiveConf);
	},

	/**
	 * Configure placeholder (the panel instead of header) for collapsible filter panel
	 */
	configurePlaceholder() {
		let __self = this;

		__self.floatingExpandButton = __self.createExtendedFilterButton();

		__self.placeholder = Ext.widget({
			xtype: 'panel',
			cls: `${__self.baseCls}-header`,
			flexTitle: !edi.constants.FILTERS_COLLAPSIBLE,
			items: [
				createContainer({
					layout: {
						type: 'hbox',
						align: 'middle',
						pack: 'start'
					},
					width: '100%',
					maxWidth: '100%',
					items: [
						createButton({
							cls: BUTTON_CLS.secondary,
							glyph: edi.constants.ICONS.MODULE_FILTER,
							itemId: 'expandCollapseTool',
							handler() {
								__self.toggleHandler();
							}
						})
					].concat(__self.__tools)
				}),
				__self.floatingExpandButton
					? createContainer({
							cls: `${__self.baseCls}-floating-container-expand`,
							width: '100%',
							layout: {
								type: 'hbox',
								align: 'middle',
								pack: 'center'
							},
							items: [__self.floatingExpandButton]
					  })
					: null
			],
			defaultFocus: '[itemId="expandCollapseTool"]',
			listeners: edi.constants.FILTERS_COLLAPSIBLE
				? {
						afterrender(placeholder) {
							placeholder.getEl().on('mouseenter', function () {
								__self.preventClose();
							});
							placeholder.getEl().on('mouseleave', function () {
								__self.autoClose();
							});
							placeholder.getEl().on('dblclick', function () {
								__self.toggleHandler();
							});
						},
						resize(placeholder, w, h, oldW, oldH) {
							if (!__self.collapsed) {
								//когда меняется ширина плейсхолдера, то нужно свернуть панель, что бы
								//при следующем разворачивании ей просчитались новые размеры.
								//Установка размера через setWidth и updateLayout не работает
								//т.к. лэйаут панели считается нормально посчитанным, а как его
								//инвалидировать я не разобрался =(
								if (w !== oldW) {
									__self.toggleHandler();
								}
								//при этом если меняется только высота плейсхолдера за счет добавления
								//чипсов, то просто подвинем панель ниже на высоту плейсхолдера
								else if (h !== oldH) {
									__self.setPosition(0, h);
								}
							}
						},
						//при наличии плейсхолдера и если панель свернута вызывается
						//событие именно в плейсхолдере, а не в панели
						hide() {
							__self.onPanelHide(__self);
						}
				  }
				: undefined
		});
	},

	/**
	 * Process actions after component's initialization
	 */
	afterInit() {
		let __self = this;
		__self.initInputListeners();
	},

	/**
	 * Cleans up before destroy
	 * @returns	{boolean}	continue destroy
	 */
	onBeforeDestroy(__self) {
		__self.closePreviouslyOpenedModal();
		return true;
	},

	/**
	 * Add listeners to inputs on form for starting search after changes
	 */
	initInputListeners() {
		let __self = this;
		let inputs = __self.query('textfield,numberfield,checkbox,datefield,combobox');
		if (typeof __self.onFilterFieldChange !== 'function' || !inputs.length) {
			return;
		}

		for (let i = 0; i < inputs.length; i++) {
			let input = inputs[i];
			if (input.ignoreAutoFilter) {
				continue;
			}
			if (input.hasOwnProperty('filterListeners')) {
				let customListeners = input.filterListeners;
				//pass filterListeners: null, false, undefined to prevent binding on default events
				if (!customListeners || (Ext.isArray(customListeners) && !customListeners.length)) {
					continue;
				}

				customListeners.forEach((event) => input.on(event, __self.onFilterFieldChange));
			} else if (input.xtype === 'combobox') {
				input.on('select', function () {
					__self.onFilterFieldChange();
					return true;
				});
			} else {
				input.on('change', function () {
					__self.onFilterFieldChange();
					return true;
				});
			}
			//когда поля скрываются/показываются будем обновлять меню "+Добавить"
			input.on('show', function () {
				__self.updateAddFilterMenu();
			});
			input.on('hide', function () {
				__self.updateAddFilterMenu();
			});
		}
	},

	/**
	 * Extracts fieldLabel from form item (it can be a simple input and a container as well)
	 * @param	{Object}	formItem
	 * @returns	{string}	field or container label
	 */
	findFilterItemLabel(formItem) {
		// если есть useFieldLable возвращать только chipTitle т.к. нет необходимости склеивать label комбика и поля ввода
		if (formItem?.fieldConf?.useFieldLable && formItem?.fieldConf?.chipTitle) {
			return formItem.fieldConf.chipTitle;
		}
		//ищем заголовок поля или если это сложное поле (контейнер), то все заголовки и склеиваем их
		let title = formItem?.fieldLabel || '';
		if (!title && typeof formItem?.query === 'function') {
			title = formItem
				.query('field')
				.filter((f) => f.fieldLabel)
				.map((f) => f.fieldLabel)
				.join(' ');
		}

		return title;
	},

	/**
	 * Update "quick add" menu items based on formItems states
	 */
	updateAddFilterMenu() {
		let __self = this;
		if (!__self.addFilterButton?.menu) {
			return;
		}

		let menuItems = Object.values(__self.formItemsMap || {})
			.filter((formItem) => {
				return (
					!!formItem &&
					(typeof formItem.isHiddenFromMenu === 'function'
						? !formItem.isHiddenFromMenu(__self)
						: !formItem.hidden)
				);
			})
			.map((formItem) => {
				return {
					text: formItem.chipsModalTitle || __self.findFilterItemLabel(formItem) || 'unknown field',
					handler() {
						__self.openAddFilterModal(__self.addFilterButton, formItem);
					}
				};
			});

		__self.addFilterButton.menu.removeAll();
		__self.addFilterButton.menu.add(menuItems);
	},

	/**
	 * Creates backdrop and attach it after filter panel
	 */
	createBackdrop() {
		let __self = this;
		__self.backdrop = document.createElement('div');
		__self.backdrop.style.setProperty('display', 'none');
		__self.backdrop.classList.add(__self.backdropCls);
		__self.backdrop.addEventListener('click', function () {
			!__self.collapsed && __self.toggleHandler();
		});
		__self.el.dom.parentElement.appendChild(__self.backdrop);
	},

	/**
	 * Shows/hides backdrop (if it exists)
	 * @param	{Boolean}	visible
	 */
	setBackdropVisible(visible) {
		if (typeof this.backdrop?.style?.setProperty === 'function') {
			this.backdrop.style.setProperty('display', visible ? 'block' : 'none');
		}
	},

	/**
	 * Fires after filterPanel rendered, adds backdrop and listeners for auto hide
	 */
	onPanelRender() {
		let __self = this;

		//для случаев, когда фильтр находится в ТАБе его модалки и слушатели события esc
		//надо вкл\выкл синхронно с активацией таба
		let parentTabPanel = __self.up('tabpanel');
		let parentTab = parentTabPanel ? parentTabPanel.getActiveTab() : null;
		if (parentTab && typeof parentTab.on === 'function') {
			parentTab.on('activate', function () {
				typeof __self.keyNav?.enable === 'function' && __self.keyNav.enable();
			});
			parentTab.on('deactivate', function () {
				__self.closePreviouslyOpenedModal();
				typeof __self.keyNav?.disable === 'function' && __self.keyNav.disable();
			});
		}

		__self.updateAddFilterMenu();
		__self.onMouseLeaveFloated = () => {};
		__self.setTitle = () => {};

		if (__self.collapsible !== false) {
			__self.createBackdrop();
		}

		__self.body.on('mousemove', function () {
			__self.preventClose();
		});

		let pickerEventsSetter = function (picker) {
			picker.getEl().on('mouseenter', function () {
				__self.preventClose();
			});
			picker.getEl().on('mouseleave', function () {
				__self.autoClose();
			});
		};

		let fieldMonitorEventsSetter = function (field) {
			field.mon(__self, 'unfloat', function () {
				field.collapse();
			});
		};

		let fields = __self.getForm().getFields().getRange();
		fields.forEach((field) => {
			if ('function' == typeof field?.getPicker) {
				let picker = field.getPicker();
				let el = picker.getEl();
				if (!el) {
					picker.on('render', pickerEventsSetter);
				} else {
					pickerEventsSetter(picker);
				}
				fieldMonitorEventsSetter(field);
			}
		});
	},

	/**
	 * Esc button handler, collapses modal or panel
	 */
	escHandler() {
		let __self = this;
		//сначала закроем окна, уже потом можно и саму панель фильтров
		if (__self.lastOpenedModal && !__self.lastOpenedModal.isDestroyed) {
			__self.lastOpenedModal.close();
		} else {
			!__self.isDestroyed && !__self.collapsed && __self.toggleHandler();
			window.removeEventListener('keydown', __self.__registeredEscHandler);
		}
	},

	/**
	 * Fires after panel expand, sync floating elements' z-indexes and shows backdrop
	 * @param	{Object}	__self
	 */
	onPanelFloat(__self) {
		__self.inheritedStateInner.collapsed = false;
		let fields = __self.getForm().getFields().getRange();
		fields.forEach((field) => {
			let picker = field.getPicker && field.getPicker();
			picker && picker.syncHidden && picker.syncHidden();
		});
		__self.setBackdropVisible(true);

		typeof __self.keyNav?.enable === 'function' && __self.keyNav.enable();
	},

	/**
	 * Fires after panel collapse, sync floating elements' z-indexes and hides backdrop
	 * @param	{Object}	__self
	 */
	onPanelUnfloat(__self) {
		__self.inheritedStateInner.collapsed = true;
		let fields = __self.getForm().getFields().getRange();
		fields.forEach((field) => {
			let picker = field.getPicker && field.getPicker();
			picker && picker.syncHidden && picker.syncHidden();
		});
		__self.setBackdropVisible(false);

		typeof __self.keyNav?.disable === 'function' && __self.keyNav.disable();
		typeof __self.floatingExpandButton?.show === 'function' && __self.floatingExpandButton.show();
		if (
			__self.searchOnFilterCollapse === true &&
			!__self.autosearchEnabled &&
			typeof __self.fireSearch === 'function'
		) {
			__self.fireSearch();
		}
	},

	/**
	 * Fires after panel show()
	 * @param	{Object}	__self
	 */
	onPanelShow(__self) {
		typeof __self.keyNav?.enable === 'function' && __self.keyNav.enable();
	},

	/**
	 * Fires after panel hide()
	 * @param	{Object}	__self
	 */
	onPanelHide(__self) {
		__self.closePreviouslyOpenedModal();
		typeof __self.keyNav?.disable === 'function' && __self.keyNav.disable();
	},

	/**
	 * Fires after form reset event, updates chips
	 */
	onFormReset() {
		let __self = this;
		__self.processHeaderChips(__self, __self.getValues());
	},

	/**
	 * Expands filter panel if we made form invalid to be able see what happened (remove chips, i.e)
	 * @param	{Object}	form
	 * @param	{Boolean}	valid
	 */
	onFormValidityChange(form, valid) {
		let __self = this;
		if (!valid && __self.collapsible && __self.collapsed) {
			__self.toggleHandler();
		}
	},

	/**
	 * Fires after field value changed, initiates search and chips update
	 */
	onFilterFieldChange() {
		let __self = this;
		if (__self.autosearchEnabled) {
			let searchFn = __self.externalOnFieldChange ? __self.externalOnFieldChange : __self.fireSearch;
			if (typeof searchFn === 'function') {
				searchFn();
			}
		}
		if (typeof __self.processHeaderChips === 'function') {
			__self.processHeaderChips();
		}
	},

	/**
	 * Retrieve autoCloseTime from user settings and store it in __self
	 * @returns	{Number}	auto close timeout
	 */
	getAutoCloseTime() {
		let __self = this;
		__self.autoCloseTime =
			+edi.utils.getObjectProperty(edi.core.getUserData(), 'userData.user.autoCloseFilterPanel') || 0;
		return __self.autoCloseTime;
	},

	/**
	 * Stops auto close process
	 */
	preventClose() {
		let __self = this;
		window.clearTimeout(__self.closeTimeout);
	},

	/**
	 * Check states and collapse panel if needed
	 */
	autoClose() {
		let __self = this;
		let autoCloseTime = __self.getAutoCloseTime(); //User could change value in personal settings
		__self.preventClose();
		if (autoCloseTime && !__self.isDestroyed) {
			__self.closeTimeout = setTimeout(function () {
				if (!__self.isDestroyed && !__self.collapsed) {
					__self.toggleHandler();
				}
			}, parseInt(autoCloseTime));
		}
	},

	/**
	 * Collapse/expand handler, also sets maximum height of filter panel in expanded state
	 */
	toggleHandler() {
		let __self = this;
		if (!__self.isDestroyed && __self.isVisible() && __self.collapsible) {
			let parent = __self.up();
			const isCollapsed = __self.inheritedStateInner.collapsed === true;
			__self.maxHeight = parent.el.dom.clientHeight * __self.maxPanelHeightPercent;
			__self.floatCollapsedPanel();
			if (__self.floatingExpandButton?.hide && isCollapsed) {
				__self.floatingExpandButton.hide();
			}

			if (__self.lastOpenedModal && !__self.lastOpenedModal.isDestroyed) {
				__self.lastOpenedModal.close();
			}
		}
	},

	/**
	 * Sets values from "quick filter" modal to form
	 * @param	{Object}	newValues
	 */
	setFilterValueFromModal(newValues) {
		let __self = this;
		__self.getForm().setValues(newValues || {});
	},

	/**
	 * Closes last opened "quick filter" modal
	 */
	closePreviouslyOpenedModal() {
		let __self = this;
		if (
			!__self.lastOpenedModal?.isDestroyed &&
			__self.lastOpenedModal?.isVisible() &&
			typeof __self.lastOpenedModal.close === 'function'
		) {
			__self.lastOpenedModal.close();
		}
	},
	isKeyFromInitialConfig: (key) => ['layout', 'items'].some((i) => i === key),
	/**
	 * Clones component and it's children (with actual values)
	 * @param	{Object}	originalItem
	 * @param	{Object}	[overrides]
	 * @returns	{Object}	cloned component
	 */
	cloneComponent: function (originalItem, overrides) {
		if (!originalItem) {
			return null;
		}

		let __self = this;
		// т.к. значения в originalItem могут изменяться, берем текущие значения напрямую из originalItem, в случае если их нет из initialConfig
		const originalItemCfg = Object.keys(originalItem.initialConfig).reduce(function (resultCfg, valueKey) {
			if (__self.isKeyFromInitialConfig(valueKey)) {
				resultCfg[valueKey] = Ext.clone(originalItem.initialConfig[valueKey]);
			} else {
				resultCfg[valueKey] = Ext.clone(originalItem[valueKey] ?? originalItem.initialConfig[valueKey]);
			}
			return resultCfg;
		}, {});
		let cfg = Ext.merge({}, overrides, originalItemCfg);
		Ext.merge(cfg, cfg.modalCloneConf);

		//скопируем текущие значения полей
		Ext.apply(cfg, {
			id: Ext.id(),
			hidden: originalItem.hidden,
			value: originalItem.value,
			rawValue: originalItem.rawValue,
			checked: originalItem.checked,
			lastSelectedRecord: originalItem.lastSelectedRecord
		});
		//тут нужно покопаться в исходниках, чтобы понять как устанавливаются значения при создании
		//если не задать текущий диапазон, то он будет браться по умолчанию и стирать актуальные значения
		if (originalItem.xtype === 'edi-date-range') {
			Ext.merge(
				cfg,
				{
					activeRange: originalItem.dateFrom?.activeRange || originalItem.dateTo?.activeRange,
					fieldFromConf: Ext.merge(
						{
							value: originalItem.dateFrom?.value,
							rawValue: originalItem.dateFrom?.rawValue
						},
						originalItem.dateFrom?.modalCloneConf
					),
					fieldToConf: Ext.merge(
						{
							value: originalItem.dateTo?.value,
							rawValue: originalItem.dateTo?.rawValue
						},
						originalItem.dateTo?.modalCloneConf
					)
				},
				originalItem.modalCloneConf
			);
		} else if (originalItem.xtype === 'daterangefield') {
			Ext.merge(
				cfg,
				{
					period: originalItem.period,
					fieldsConfig: {
						from: {
							value: originalItem.dateFromField?.value,
							rawValue: originalItem.dateFromField?.rawValue
						},
						to: {
							value: originalItem.dateToField?.value,
							rawValue: originalItem.dateToField?.rawValue
						}
					}
				},
				originalItem.modalCloneConf
			);
		} else if (originalItem.xtype === 'combotreefield') {
			cfg.isCloneWithSharedStore = true;
		}

		if (Ext.isArray(cfg.items) && cfg.items.length > 0) {
			cfg.items = cfg.items.map((child) => __self.cloneComponent(child));
		}

		let clone = null;
		let cloneClass = Ext.getClass(originalItem);
		if (cloneClass) {
			clone = new cloneClass(cfg);
		} else if (originalItem.xtype) {
			clone = Ext.widget(originalItem.xtype, cfg);
		} else {
			edi.core.logMessage('Cannot create the clone of:', 'error');
			edi.core.logMessage(originalItem, 'error');
		}

		if (clone && clone.hideFromPanel === true) {
			clone.removeCls(__self.hiddenFieldCls);
		}

		return clone;
	},

	/**
	 * Calculate width for modal based on count of fields need to be shown
	 * @param	{Object}	formItem
	 * @returns	{Number}	modal width
	 */
	calcModalWidth(formItem) {
		let __self = this;

		let fields = typeof formItem?.query === 'function' ? formItem.query('field') : [];
		let fieldsCount = fields.length;
		//т.к. диапазон дат маленький, то его будем считать как одно поле
		if (fields[0]?.xtype === 'datefieldranged') {
			fieldsCount -= 1;
		}

		if (fieldsCount < 1) {
			fieldsCount = 1;
		} else if (fieldsCount > 2) {
			fieldsCount = 2;
		}

		return __self.fieldWidthInModalBase * fieldsCount;
	},

	/**
	 * Opens "quick filter" modal and clone original form item there
	 * @param	{Object|undefined}	initiator
	 * @param	{Object}			originalItem
	 */
	openAddFilterModal(initiator, originalItem) {
		let __self = this;

		__self.closePreviouslyOpenedModal();

		let itemToShow = __self.cloneComponent(originalItem);
		let modalForm = createModalForm({
			layout: 'fit',
			items: [itemToShow]
		});

		let modal = createModalPanel({
			cls: 'edi-filter-chip-modal',
			title: itemToShow?.chipsModalTitle || __self.findFilterItemLabel(itemToShow) || '',
			modal: false,
			layout: 'fit',
			items: [modalForm],
			width: __self.calcModalWidth(itemToShow),
			buttons: [
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('form.btn.search'),
					handler() {
						__self.setFilterValueFromModal(modalForm.getValues(false, false, false, true));
						modal.query('field')?.forEach((f) => {
							let original = __self.down(`field[name="${f.name}"]`);
							if (original) {
								original.lastSelectedRecord = f.lastSelectedRecord;
							}
						});

						modal.query('datefield')?.forEach((f) => {
							const modalNewDateRange = f.up('daterangefield');
							const original = __self.down(`field[name="${f.name}"]`);
							const originalNewDateRange = original?.up('daterangefield');
							if (
								typeof modalNewDateRange?.getCurrentPeriod === 'function' &&
								typeof originalNewDateRange?.setCurrentPeriod === 'function' &&
								modalNewDateRange.getCurrentPeriod()
							) {
								//TODO единственное место где используется setCurrentPeriod вместо activatePeriod
								//заменить на setCurrentPeriod когда будет пофикшен баг при котором
								//при изменении даты вручную в input не сбрасывается период
								originalNewDateRange.setCurrentPeriod(modalNewDateRange.getCurrentPeriod());
							}
						});
						modal.close();
						if (typeof __self.fireSearch === 'function') {
							__self.fireSearch();
						}
					}
				})
			]
		});

		__self.lastOpenedModal = modal;
		modal.show();
		//если возможно, то подвинем окно фильтра ближе к месту открытия
		if (typeof initiator?.getXY === 'function') {
			modal.setPosition(initiator.getXY());
		}
	},

	/**
	 * Gets chip identification
	 * @param	{Object}	formItem
	 * @returns	{String}	identification
	 */
	getChipsIdentification(formItem) {
		return 'chip_' + (formItem.id || formItem.name);
	},

	/**
	 * Generates chip's text from fom item
	 * @param	{Object}	formItem
	 * @returns {string}	chip's text
	 */
	getItemChipValue(formItem) {
		let __self = this;
		if (!formItem) {
			return null;
		}

		if (typeof formItem.getChipValue === 'function') {
			return formItem.getChipValue(formItem);
		}

		const getChipTitle = function (field) {
			return typeof field.getChipTitle === 'function'
				? field.getChipTitle(field, __self)
				: field.chipTitle
				? field.chipTitle
				: field.getFieldLabel();
		};

		let txt = '';
		//если поле без всяких контейнеров само по себе на форме
		let fieldValue = typeof formItem.getRawValue === 'function' && formItem.getRawValue();
		const isFieldValueEmpty =
			typeof fieldValue === 'object'
				? Ext.Object.isEmpty(fieldValue)
				: typeof fieldValue === 'number'
				? !isFinite(fieldValue)
				: !fieldValue;
		if (!isFieldValueEmpty && !formItem.ignoreChips) {
			txt = `${formItem.fieldLabel}: ${formItem.getRawValue()}`;
		}
		//для сложных полей или полей в контейнере, то возьмем их по отдельности и склеим значения
		else if (typeof formItem.query === 'function') {
			let fields = formItem.query('field');
			//если это диапазона дат, то запишем обе даты в 1 чипс
			if (fields[0]?.xtype === 'datefieldranged') {
				txt = fields
					.filter((f) => f.getRawValue() && !f.ignoreChips)
					.map((f) => {
						return typeof f.getChipText === 'function'
							? f.getChipText(f, __self)
							: `${getChipTitle(f)} ${f.getRawValue()}`;
					})
					.join('');
			} else {
				//если просто несколько полей в контейнере, то склеим их значения, если это разрешено
				let containerLabel = __self.findFilterItemLabel(formItem);

				let fieldText = fields
					.filter((f) => f.getRawValue() && !f.ignoreChips)
					.map((f) => {
						let label = getChipTitle(f);
						if (label) {
							containerLabel = edi.utils.safeString(label, true, true);
						}
						return f.getRawValue();
					})
					.join('');

				if (fieldText) {
					txt = `${containerLabel}: ${fieldText}`;
				}
			}

			if (formItem.xtype === 'daterangefield') {
				txt = fields
					.filter((f) => f.getRawValue() && !f.ignoreChips)
					.map((f) => {
						return typeof f.getChipText === 'function'
							? f.getChipText(f, __self)
							: `${getChipTitle(f)} ${f.getRawValue()}`;
					})
					.join('');
			}
		}
		return txt;
	},

	formatChipValue: function (value) {
		let separatorIndex = value?.indexOf(':');
		if (separatorIndex > 0) {
			let fieldName = value.slice(0, separatorIndex);
			let fieldValue = value.slice(separatorIndex + 1);
			return edi.utils.formatString('{0}: <span class="ui-filter-chip-value">{1}</span>', [
				fieldName,
				Ext.String.htmlEncode(fieldValue)
			]);
		} else {
			return Ext.String.htmlEncode(value);
		}
	},

	/**
	 * Removes chip by it's identification
	 * @param	{String}	chipIdent
	 */
	removeChip(chipIdent) {
		let __self = this;
		let chip = __self.chips[chipIdent];
		if (chip) {
			__self.chipsContainer.remove(chip, true);
			delete __self.chips[chipIdent];
		}
	},

	/**
	 * Creates new or update existing chip with new text value
	 * @param	{Object}	formItem
	 * @param	{String}	chipIdent
	 * @param	{String}	newValue
	 */
	addOrUpdateChip(formItem, chipIdent, newValue) {
		let __self = this;
		let chip = __self.chips[chipIdent];
		if (chip) {
			chip.setText(__self.formatChipValue(newValue), true);
			chip.setToolTip(newValue);
		} else {
			chip = __self.chips[chipIdent] = __self.createChip(formItem, chipIdent, newValue);
			if (__self.chipsContainer) {
				//считаем что, если кнопка создана значит она добавлена в конец после чипсов
				//все новые чипсы добавляем перед ней, что б она оставалась в конце строки
				if (__self.addFilterButton) {
					let insertPosition = __self.chipsContainer.items.length - 1;
					if (insertPosition < 0) {
						insertPosition = 0;
					}
					__self.chipsContainer.insert(insertPosition, chip);
				} else {
					__self.chipsContainer.add(chip);
				}
				chip.setText(__self.formatChipValue(newValue), true);
			}
		}
	},

	/**
	 * Close chip handler
	 * @param	{Object}	formItem
	 * @param	{String}	chipIdent
	 */
	closeChipHandler(formItem, chipIdent) {
		let __self = this;
		__self.removeChip(chipIdent);

		let forceStartSearch = false;
		if (typeof formItem.reset === 'function') {
			formItem.reset();
			forceStartSearch = formItem.forceSelection;
		} else if (typeof formItem.query === 'function') {
			formItem
				.query('field')
				.filter((f) => typeof f.reset === 'function')
				.forEach((f) => {
					f.reset();
					if (f.forceSelection) {
						forceStartSearch = true;
					}
				});
		}

		if (forceStartSearch && typeof __self.fireSearch === 'function') {
			__self.fireSearch();
		}
	},

	/**
	 * Creates new chip object
	 * @param	{Object}	formItem
	 * @param	{String}	chipIdent
	 * @param	{String}	value
	 * @returns	{Object}	new chip (Ext.button.Button instance)
	 */
	createChip(formItem, chipIdent, value) {
		let __self = this;

		let textComp, closeBtn;
		return createContainer({
			field: formItem,
			chipIdent,
			maxWidth: __self.chipMaxWidth || 400,
			margin: '0 8 0 0',
			cls: 'ui-filter-chip',
			items: [
				(textComp = createLabel({
					cls: 'ui-filter-chip-title',
					qtipText: value || 'x',
					text: value || 'x',
					listeners: {
						afterrender: function (comp) {
							comp.getEl().on('click', function () {
								__self.openAddFilterModal(comp, formItem);
							});
						}
					}
				})),
				(closeBtn = createButton({
					cls: [BUTTON_CLS.chip_btn, BUTTON_CLS.icon],
					margin: '0 0 0 8',
					glyph: edi.constants.ICONS.CANCEL,
					handler: function () {
						__self.closeChipHandler(formItem, chipIdent);
						if ('function' === typeof __self.fireSearch) {
							__self.fireSearch();
						}
					}
				}))
			],
			setText: function (text, encode) {
				if (!Ext.isEmpty(text)) {
					//стирание text или html перед установкой нового значения необходимо
					//из-за особенностей работы экста - свойство text приоритетнее и поэтому при первом рендеринге
					//отображается оно, а нее актуальный html
					textComp[encode ? 'setText' : 'setHtml'](null);
					textComp[encode ? 'setHtml' : 'setText'](text);
				}
			},
			setToolTip: function (text) {
				textComp.setToolTip(text);
			}
		});
	},

	/**
	 * Updates chips based on formItems states
	 */
	processHeaderChips() {
		let __self = this;
		if (__self.disableChips === true || !__self.chipsContainer) {
			return;
		}

		if (!__self.chips) {
			__self.chips = {};
		}

		Object.values(__self.formItemsMap || {}).forEach((item) => {
			if (!Ext.isObject(item)) {
				return;
			}

			let chipIdent = __self.getChipsIdentification(item);
			let chipValue = __self.getItemChipValue(item);
			if (!item || !item.isVisible() || !chipValue) {
				__self.removeChip(chipIdent);
			} else {
				__self.addOrUpdateChip(item, chipIdent, chipValue);
			}
		});
	},
	setFormDefaults() {}
});

/**
 * Create filter panel
 * @param	{Object}	config
 * @param	{Function}	[fireSearch]
 * @param	{Function}	[onChange]
 * @param	{Object}	[customButtons]
 * @returns	{Object}	Core.components.moduleFilterForm instance
 */
const createModuleFilterForm = function (config, fireSearch, onChange, customButtons) {
	return Ext.create('UI.components.moduleFilterForm', {
		externalConfig: config,
		externalParams: { fireSearch, onChange, customButtons }
	});
};

export { createModuleFilterForm };
