import { createLabel, createTriggerField } from '@Components/fields';
import { createPanel, createTab } from '@Components/panels';
import { createModalRemoteSelect, createModalWithLocalGrid } from './modals';
import { BADGE_ALIGN, BADGE_PRESETS, PTYPE_BADGE } from '@UIkit/components/badge/Badge';

/**
 * Inserts component into tabPanel and add listeners or/and title
 * @param	{String}	tabName		Tab name
 * @param	{Object}	component	Component to view in tab
 * @param	{Object}	tabPanel	Tab panel
 * @param	{Object}	[options]	Additional options
 */
const initTabWithComponent = function (tabName, component, tabPanel, options) {
	options = Object.assign(
		{
			title: '',
			glyph: null,
			isDefault: false,
			showRecordsCountInTitle: false,
			customCountFn: null,
			callback: null
		},
		options
	);
	const title = edi.i18n.getMessage(options.title || tabName);

	var onActivate =
		'function' == typeof options.callback
			? function (component, eOpts) {
					options.callback(tabName, component, eOpts);
			  }
			: null;

	var tabConfig = {};
	if (options.glyph) {
		tabConfig.glyph = options.glyph;
	}
	var tab = createTab({
		title: title,
		layout: 'fit',
		closable: false,
		tabConfig: tabConfig,
		activate: onActivate,
		plugins: [
			{
				ptype: PTYPE_BADGE,
				cls: [BADGE_PRESETS.tabCounter],
				align: BADGE_ALIGN.r,
				hidden: true
			}
		],
		items: [component]
	});

	tab.component = component;
	component.tab = tab;

	if (options.showRecordsCountInTitle && component.getStore()) {
		var store = component.getStore();
		store.on('load', function () {
			var storeCount = store.getTotalCount();
			if (typeof options.customCountFn === 'function') {
				const filterStore = store.data.items.filter(function (rec) {
					return options.customCountFn(rec);
				});
				storeCount = filterStore ? filterStore.length : store.getTotalCount();
			}
			if (!tab.isDestroyed) {
				tab.badgeCmp.setVisible(storeCount !== 0);
				tab.badgeCmp.setText(storeCount >= 100 ? '99+' : storeCount);
				tab.tab.updateLayout();
			}
		});
	}
	tabPanel.addCls('edi-module-tab-panel');
	tabPanel.add(tab);

	var targetTab = edi.utils.getURLParams().tab;
	if ((!targetTab && options.isDefault) || targetTab === tabName) {
		tabPanel.setActiveTab(tab);
	}
};

/**
 * Inserts grid into tabPanel and add listeners or/and title
 * @param	{String}	tabName
 * @param	{Object}	grid
 * @param	{Boolean}	isDefault
 * @param	{Object}	tabPanel
 * @param	{Object}	[tabConfig]
 * @param	{Object}	[options]
 */
const initTabWithGrid = function (tabName, grid, isDefault, tabPanel, tabConfig, options) {
	let opts = Object.assign(
		{
			badgeCount: false
		},
		options
	);
	tabConfig = tabConfig || {};
	var urlParams = edi.utils.getURLParams(),
		tabToActivate = null,
		onActivate =
			'function' == typeof tabConfig.callback
				? function (component, eOpts) {
						tabConfig.callback(tabName, component, eOpts);
				  }
				: null;

	if (urlParams.tab) {
		tabToActivate = urlParams.tab;
	}

	var tabCfg = {
		cls: tabConfig.hasOwnProperty('cls') ? tabConfig.cls : 'edi-documents-grid-tab-' + tabName
	};

	delete tabConfig.cls;

	var config = Ext.merge(
		{
			title: 'module.tab.' + tabName,
			closable: false,
			tabConfig: tabCfg,
			tabName: tabName,
			items: [grid],
			activate: onActivate,
			layout: 'fit',
			plugins: [
				{
					ptype: PTYPE_BADGE,
					cls: [BADGE_PRESETS.tabCounter],
					align: BADGE_ALIGN.r,
					hidden: true
				}
			]
		},
		tabConfig
	);

	config.title = edi.i18n.getMessage(config.title);
	var tab = createTab(config);

	tab.grid = grid;
	grid.tab = tab;

	if (opts.badgeCount) {
		grid.getStore &&
			grid.getStore().on('load', function (store) {
				let count = store.getTotalCount();
				if (!tab.isDestroyed) {
					tab.badgeCmp.setVisible(count !== 0);
					tab.badgeCmp.setText(count >= 100 ? '99+' : count);
					tab.tab.updateLayout();
				}
			});
	}

	tabPanel.add(tab);

	if ((!tabToActivate && isDefault) || tabName === tabToActivate) {
		tabPanel.setActiveTab(tab);
	}
};

/**
 * Creates string by values and their's names map
 * @param	{Object}	infoValues	values object
 * @param	{Object}	hash		map for output values keys
 * @returns	{String}	values separated by ";"
 */
const createInfoValuesToString = function (infoValues, hash) {
	var info = [];
	if (infoValues) {
		for (var i in hash) {
			if (hash.hasOwnProperty(i) && infoValues[hash[i]]) {
				info.push(i + ':' + infoValues[hash[i]]);
			}
		}
	}
	return info.length > 0 ? info.join(';') : '';
};

/**
 * Collect object with values from string (separated by ";") and names map
 * @param	{String}	Info
 * @param	{Object}	hash
 * @returns	{Object}	Object with 'key from hash': 'value from string'
 */
const createInfoValuesFromString = function (Info, hash) {
	var infoValues = {};

	for (var key in hash) {
		if (hash.hasOwnProperty(key)) {
			infoValues[hash[key]] = '';
		}
	}

	if (Info) {
		var data = Info.split(';');
		if (data.length > 0) {
			for (var i = 0; i < data.length; i++) {
				data[i] = data[i].split(':');
				if (data[i].length > 1 && hash[data[i][0]]) {
					key = data[i].shift();
					infoValues[hash[key]] = data[i].join(':');
				}
			}
		}
	}
	return infoValues;
};

/**
 * Creates input field or panel with label and field for selectionModal
 * @param	{Object}	config
 * @returns {Object}	field or panel with label and field
 */
const createPanelWithModalSelect = function (config) {
	config = config || {};

	var valueInput,
		selectedValue = config.value || null,
		valueFieldName = config.valueField || 'id',
		inputWidth = config.inputColumnWidth || 0.7,
		displayField = config.displayField || valueFieldName,
		modalOptions = config.modalOptions,
		url;

	if ('function' == typeof config.createURL) {
		url = config.createURL();
	}
	if (!url && config.modalURL) {
		url = config.modalURL;
	}

	var showSelectionModal = function () {
		if ('function' == typeof config.changeConfigBeforeShow) {
			config = config.changeConfigBeforeShow(config);
		}

		if ('function' == typeof config.createUrlBeforeShow) {
			url = config.createUrlBeforeShow();
		}

		var currentSelection = null;

		if ('function' == typeof config.getCurrentSelection) {
			currentSelection = config.getCurrentSelection();
		} else if (valueInput && valueInput.getValue()) {
			currentSelection = valueFieldName == 'id' ? parseInt(valueInput.getValue()) : valueInput.getValue();
		}

		var createArgs = 'function' == typeof config.createArgs ? config.createArgs : undefined;
		//if no createArgs fn is specified, but modal has filter
		if (!createArgs && !config.noFilter) {
			createArgs = edi.filters.config.generic.createArgs;
		}
		const getFilterFormConfig = () => {
			const { filterItemsMethodArgs = {}, filterItemsMethod } = config;
			if ('function' == typeof filterItemsMethod) {
				const { formItemsMap, items } = filterItemsMethod(...filterItemsMethodArgs);
				return {
					createFilterFormItems: items,
					createFormItemsMap: formItemsMap
				};
			}
		};

		let modal;
		const selectionCallback = function (data) {
			if (Ext.isArray(data)) {
				let displayValues = [];
				let idValues = [];
				data.forEach((el) => {
					idValues.push(el[valueFieldName]);
					displayValues.push(el[displayField]);
				});
				selectedValue = idValues.join(',');
				valueInput.setValue(displayValues.join(','));
				valueInput.removeCls('error-description');
			} else if (valueInput) {
				selectedValue = data[valueFieldName];
				valueInput.setValue(data[displayField]);
				valueInput.removeCls('error-description');
			}

			modal.close();
			if ('function' == typeof config.afterSelect) {
				config.afterSelect(data);
			}
		};

		if (config?.useLocalFilters) {
			modal = createModalWithLocalGrid(
				Ext.merge(
					{
						title: 'user.select.title',
						data: [],
						model: config.model,
						columns: config.columns,
						disablePaging: true,
						sorters: [],
						doNotClose: true,
						...getFilterFormConfig(),
						createArgs: createArgs
					},
					modalOptions
				),
				selectionCallback
			);
			modal.setLoading();

			edi.rest.sendRequest(url, 'GET', undefined, function (data) {
				const grid = modal.down('grid');
				const store = grid.getStore();
				const proxy = store.getProxy();

				proxy.setData(data);
				store.read();
				modal.setLoading(false);
			});
		} else {
			modal = createModalRemoteSelect(
				url,
				selectionCallback,
				Ext.merge(
					{
						storeConfig: {
							autoLoad: !!config.noFilter && !!url,
							listeners: {
								load: function (store) {
									if (currentSelection && modal) {
										var grid = modal.down('gridpanel'),
											selectedRec = store.findRecord(
												valueFieldName,
												currentSelection,
												0,
												false,
												true,
												true
											);

										if (grid && selectedRec) {
											grid.getSelectionModel().select(selectedRec);
										}
									}
								}
							}
						},
						proxyConfig:
							config.proxyConfig ||
							(typeof config.getProxyConfig === 'function' && config.getProxyConfig()),
						title: config.modalTitle,
						enableTextSelection: true,
						disablePaging: config.hasOwnProperty('disablePaging') ? config.disablePaging : true,
						groupOperation: config?.groupOperation ?? false,
						checkboxes: config?.checkboxes ?? false,
						model: config.model,
						columns: config.columns,
						noFilter: config.noFilter || false,
						...getFilterFormConfig(),
						createArgs: createArgs
					},
					modalOptions
				)
			);
		}
	};

	var inputConfig = {
		fieldLabel: config.fieldLabel,
		msgTarget: config.tooltip ? 'under' : 'qtip',
		readOnly: !!config.readOnly,
		qtipText: config.tooltip || null,
		disabled: !!config.hidden || !!config.disabled,
		allowBlank: config.allowBlank,
		regex: config.regex,
		editable: config.editable,
		columnWidth: inputWidth,
		value: selectedValue,
		name: config.fieldName,
		emptyText: config.emptyText, //<-- OVERRIDE Ediweb
		triggers: {
			search: {
				hidden: !url && !config.createUrlBeforeShow, //<-- OVERRIDE Ediweb
				extraCls: 'edi-icon edi-icon-SEARCH test-action-form-trigger-search',
				tooltip: config.triggerQtip || null,
				handler: showSelectionModal
			}
		},
		setSelectedValue: function (value) {
			selectedValue = value;
		},
		getSubmitValue: function () {
			return config.editable ? valueInput.getValue() : selectedValue;
		}
	};
	if (config.listeners) {
		inputConfig.listeners = config.listeners;
	}
	if (config.isClearValue) {
		Ext.merge(inputConfig, {
			triggers: {
				clear: {
					extraCls: 'edi-icon edi-icon-CLOSE test-action-form-trigger-close',
					tooltip: config.trigger2Qtip || null,
					handler() {
						selectedValue = '';
						valueInput.setValue(selectedValue);
						if ('function' == typeof config.afterSelect) {
							config.afterSelect();
						}
					}
				}
			}
		});
	}
	Ext.merge(inputConfig, config.inputConfig);

	//OVERRIDE Ediweb begin
	if (edi.constants.IS_EDIWEB_CLIENT) {
		var valueInput = createTriggerField(inputConfig);
	} else {
		var panelConfig = Object.assign(
			{
				layout: 'column',
				hidden: config.hidden,
				items: [
					createLabel({
						columnWidth: 1 - inputWidth,
						html: edi.i18n.getMessage(config.fieldLabel)
					}),

					(valueInput = createTriggerField(inputConfig))
				]
			},
			config.panelConfig || {}
		);
	}
	//OVERRIDE Ediweb end

	if (config.isPreloadData && url && !config.value) {
		edi.rest.sendRequest(edi.utils.compileURL(url, params), 'GET', undefined, function (data) {
			if (data && data.items && data.items.length) {
				if ('function' === typeof config.preloadConvertData) {
					var convertedRecord = config.preloadConvertData(data.items);
					if (convertedRecord) {
						config.afterSelect(convertedRecord);
					}
				}
			} else if (config.hideTriggerIfNoData) {
				valueInput.setHideTrigger(true);
			}
		});
	}

	var panel = createPanel(panelConfig);

	if (valueFieldName != displayField && selectedValue && url) {
		var params = {};
		params[valueFieldName] = selectedValue;
		const success = function (data) {
			if (selectedValue === '') {
				valueInput.setValue(selectedValue);
			} else if (data && data.items && data.items.length && data.items[0][displayField]) {
				valueInput.setValue(data.items[0][displayField]);
			} else {
				valueInput.setValue(selectedValue);
			}
			valueInput.resetOriginalValue();
		};
		if (typeof config.getInitialData === 'function') {
			config.getInitialData(success);
		} else {
			edi.rest.sendRequest(edi.utils.compileURL(url, params), 'GET', undefined, success);
		}
	}

	//OVERRIDE Ediweb begin
	if (edi.constants.IS_EDIWEB_CLIENT) {
		return valueInput;
	} else {
		panel.valueInput = valueInput;
		return config.isOnlyInput ? valueInput : panel;
	}
	//OVERRIDE Ediweb end
};

export {
	initTabWithComponent,
	initTabWithGrid,
	createInfoValuesToString,
	createInfoValuesFromString,
	createPanelWithModalSelect
};
