import { createModalRelationSelect } from '@Core/specialComponents/modals';
import { createModalCatalogSelect } from '@Edi/specialComponents/modalCatalogSelect';
import { getOrgSelectorTpl } from './orgSelectorInfoPanelTpl';
import { createPanelWithModalSelect } from '@Core/specialComponents/miscComponents';
import { createEditableGrid } from '@Components/editableGrid/EditableGrid';
import {
	createCheckbox,
	createCombo,
	createRadio,
	createDateField,
	createTextField,
	createAutocomplete
} from '@UIkit/components/fields';
import { createRowsBlock } from '@Components/createRows/create.rows';
import { createLabel } from '@UIkit/components/fields_old/Label';
import { createContainer, createForm, createPanel, FIELD_BLOCK_CLS } from '@UIkit/components/panels/';
import { createFieldBlock, createFieldSet } from '@UIkit/components/panels';
import { createTab, createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
import { BUTTON_CLS } from '@UIkit/components/buttons';

import '@UIkit/components/selectors/simpleSelector/SimpleSelector';
import './OrgSelector.scss';
import { orgSelectorMethods } from './methods';

const componentCls = 'ui-org-selector';

const ORG_SELECTOR_CLS = {
	default: `${componentCls}-default`,
	oldCP: `${componentCls}-old-cp`
};

Ext.define('UI.components.OrgSelector', {
	extend: 'UI.components.SimpleSelector',
	modalCls: `${componentCls}-modal ui-simple-selector-modal`,
	cls: ORG_SELECTOR_CLS.default,

	disableCommonAddressRenderer: false,
	disableCommonAddressTitleRenderer: false,

	mandatoryFields: null,
	allowEditButton: true,

	defaultValues: {
		type: 0,
		address_type: 0,

		//Registration data
		company_name: '',
		company_iln: '',
		company_inn: '',
		company_kpp: '',
		company_okpo: '',

		individual_inn: '',
		individual_company_name: '',
		individual_lastname: '',
		individual_firstname: '',
		individual_patronymicname: '',
		individual_certificateRegistrationIP: '',

		physical_lastname: '',
		physical_firstname: '',
		physical_patronymicname: '',
		physical_inn: '',

		company_util_reg_nr: '',
		company_code_by_sender: '',
		company_code_by_receiver: '',
		company_code_by_supplier: '',
		company_code_by_buyer: '',
		structural_subdivision: '',

		//Address
		address: '',
		addr_country: '',
		addr_zip: '',
		addr_region_code: '',
		addr_region_name: '',
		addr_area: '',
		addr_city: '',
		addr_street: '',
		addr_locality: '',
		addr_home: '',
		addr_block: '',
		addr_flat: '',

		addr_rus_zip: '',
		addr_rus_region: '',
		addr_rus_county: '',
		addr_rus_community: '',
		addr_rus_city: '',
		addr_rus_street: '',
		addr_rus_home: '',
		addr_rus_block: '',
		addr_rus_flat: '',

		addr_for_country: '',
		addr_for_country_name: '',
		addr_for_text: '',

		addr_code_gar: '',

		//Contacts
		contact_phone: '',
		contact_fax: '',
		contact_email: '',
		operator_name: '',
		operator_phone: '',
		operator_email: '',
		representative_name: '',
		representative_phone: '',
		representative_email: '',
		contact_name: '',
		contact_web: '',

		//Bank dara
		bank_acc: '',
		bank_name: '',
		bank_corr_acc: '',
		bank_id: '',
		accounts: [],
		id: '',

		//License data
		license_type: '',
		license_name: '',
		license_series: '',
		license_number: '',
		license_issuing_authority: '',
		license_date_of_issue: '',
		license_expiration_date: ''
	},

	/**
	 * Для организаций которые точно были выбраны из реляций/своя орг, блокируются некоторые поля (см disabledFieldsFromOrgCard)
	 * На основании только данных организации нельзя точно определить какая орг. была из реляций.
	 * Для явного указания передаем флаг orgFromRelation, для сторон документа (toOrg и fromOrg)
	 * (т.к. у них всегда есть id, по которому можно определить что они из реляций)
	 */
	orgFromRelation: false,
	/**
	 * Список блокируемых полей для организаций из реляций (см orgFromRelation)
	 */
	disabledFieldsFromOrgCard: {
		type: true,
		fns_id: true,

		company_name: true,
		company_iln: true,
		company_inn: true,
		company_kpp: true,

		individual_inn: true,
		individual_lastname: true,
		individual_firstname: true,
		individual_patronymicname: true,

		person_inn: true,
		person_lastname: true,
		person_firstname: true,
		person_patronymicname: true,
		person_birthday: true,
		person_snils: true
	},
	/**
	 * Оригинальные данные организации, не измененные ручками.
	 */
	originalValues: {},

	afterInit: function () {
		this.callParent();
		this.addCls(componentCls);
	},

	afterRenderFunc: function () {
		if (!this.readOnly) {
			this.checkOrgDataValid();
		}
	},

	modifyConfig: function (cfg) {
		return this.callParent([
			Ext.merge(
				{
					mandatoryFields: {}
				},
				cfg
			)
		]);
	},

	/**
	 * Recreates hidden fields by fieldsMap and updates infoPanel
	 */
	updateHiddenFields: function () {
		let __self = this;

		__self.suspendLayouts();
		let hiddenFields = Object.values(__self._hiddenFields);
		hiddenFields.forEach((f) => f.ownerCt.remove(f));

		if (__self.useHiddenFields) {
			__self.add(__self.getHiddenFieldConfigs());
		}

		__self.renderInfoPanelContents(true);
		__self.resumeLayouts();
		this.updateLayout();
	},

	/**
	 * Builds mandatory fields map base on modal mandatory fields
	 */
	createModalMandatoryFieldMap: function () {
		var __self = this,
			tabsConfig = __self.modalConf ? __self.modalConf.tabs : null;
		if (!tabsConfig) {
			return;
		}

		var processFields = function (fields) {
			for (var i in fields) {
				if (fields.hasOwnProperty(i) && i !== 'tabConfig') {
					var fieldConf = fields[i];

					if (!fieldConf) {
						continue;
					}

					if (fieldConf.type === 'fieldset' || fieldConf.type === 'panel') {
						processFields(fieldConf.fields);
					} else {
						var inputConf = fieldConf.fieldConfig || {};
						if (inputConf.allowBlank === false || inputConf.mandatory === true) {
							__self.mandatoryFields[inputConf.name] = true;
						}
					}
				}
			}
		};

		for (var i in tabsConfig) {
			if (tabsConfig.hasOwnProperty(i)) {
				processFields(tabsConfig[i]);
			}
		}
	},

	reset: function () {
		const __self = this;
		__self.callParent();
		__self.originalValues = {};
	},

	setOwnConfig: function () {
		var __self = this;

		if (!__self.ownConfigSet) {
			__self.createModalMandatoryFieldMap();

			if (__self.valuesByMap) {
				__self.applyFieldsMapToOrgValues();
			} else {
				__self.fieldValues = __self.fieldValues || {};
			}

			Ext.applyIf(__self.fieldValues, __self.defaultValues);
			__self.clearNotInMapValues();

			__self.presetCompanyAndAddressTypes(__self.fieldValues);
			__self.hasData = !__self.isEmptyValues();
			__self.getInfoPanelComponentTpl();

			__self.createSelfPanel();
			__self.ownConfigSet = true;
			__self.forceChangeFields = __self.forceChangeFields || {};
		}
	},

	/**
	 * Creates panel buttons
	 * @return {Array}
	 */
	createActionButtons: function () {
		const __self = this;
		const hasPartners = __self.relations && __self.relations.length;
		const buttons = [];
		const hasRelation = hasPartners || __self.relationsFromDelcatByOrgId || __self.relationsFromLoccatByOrgId;
		const showSelectButton = !__self.alwaysHideSelect && (hasRelation || __self.alwaysShowSelect);
		const isEdit = !__self.isEmptyValues() || __self.relationsOnly;

		if (showSelectButton) {
			var canSelectPartner = hasRelation || (__self.relationsRemoteURL && __self.createModalRemoteSelect);
			this.selectBtn = __self.createButtonCmp({
				text: 'form.btn.select',
				itemId: 'selectBtn',
				disabled: !canSelectPartner,
				cls: `${__self.actionButtonsCls} ${BUTTON_CLS.light} test-companySelector-btn-select`,
				handler: function () {
					if (hasPartners) {
						__self.showModalCompanySelect();
					} else if (__self.relationsFromDelcatByOrgId) {
						__self.showModalDelcatSelect();
					} else if (__self.relationsFromLoccatByOrgId) {
						__self.showModalLoccatSelect();
					} else if (__self.relationsRemoteURL) {
						__self.showModalRemoteSelect();
					}

					if (__self.selectionModalFunc && 'function' == typeof __self.selectionModalFunc) {
						__self.selectionModalFunc(__self.setSelectionRecord, __self);
					}
				}
			});

			buttons.push(__self.selectBtn);
		}

		if (__self.allowEditButton && !__self.manualChangeDisabled) {
			const testCls = 'test-companySelector-btn-' + (isEdit ? 'edit' : 'add');
			this.editBtn = __self.createButtonCmp({
				text: isEdit ? 'form.btn.edit' : 'form.btn.add',
				cls: `${__self.actionButtonsCls} ${BUTTON_CLS.light} ${testCls}`,
				disabled: __self.relationsOnly && !__self.selectedOrgValues,
				handler: function () {
					__self.showModalCompanyControl();
				}
			});

			buttons.push(__self.editBtn);
		}

		if (this.allowReset) {
			this.resetBtn = __self.createButtonCmp({
				text: 'form.btn.reset',
				cls: `${__self.actionButtonsCls} ${BUTTON_CLS.light} test-companySelector-btn-reset`,
				disabled: this.isEmptyValues(),
				handler: function () {
					edi.core.confirm('confirm.clear.org.title', 'confirm.clear.org', function () {
						__self.reset();
					});
				}
			});

			buttons.push(__self.resetBtn);
		}

		if (__self.ownOrg) {
			this.ownBtn = __self.createButtonCmp({
				text: 'form.btn.own.org',
				cls: `${__self.actionButtonsCls} ${BUTTON_CLS.light} test-companySelector-btn-owner`,
				title: edi.i18n.getMessage('form.btn.own.org'),
				disabled: !!(this.fieldValues && this.fieldValues.id == edi.core.getUserData().org.id),
				handler: function () {
					var setOwnOrg = function () {
						__self.setOrganization(edi.core.getUserData().org, function () {
							__self.ownBtn.setDisabled(true);
						});
					};

					if (__self.isEmptyValues()) {
						setOwnOrg();
					} else {
						edi.core.confirm('confirm.clear.org.title', 'confirm.clear.org', function () {
							setOwnOrg();
						});
					}
				}
			});

			buttons.push(__self.ownBtn);
		}

		if (__self.partnerOrg) {
			this.partnerBtn = __self.createButtonCmp({
				text: __self.partnerOrgText || 'form.btn.partner.org',
				cls: `${__self.actionButtonsCls} ${BUTTON_CLS.light} test-companySelector-btn-partner`,
				disabled: !!(
					!__self.partnerOrgValues ||
					(this.fieldValues && __self.partnerOrgValues && this.fieldValues.id == __self.partnerOrgValues.id)
				),
				handler: function () {
					var setPartnerOrg = function () {
						__self.setOrganization(__self.partnerOrgValues, function () {
							__self.partnerBtn.setDisabled(true);
						});
					};

					if (__self.isEmptyValues()) {
						setPartnerOrg();
					} else {
						edi.core.confirm('confirm.clear.org.title', 'confirm.clear.org', function () {
							setPartnerOrg();
						});
					}
				}
			});

			buttons.push(__self.partnerBtn);
		}

		return buttons;
	},

	/**
	 * auto select relation if only one organization in relations and if fieldValues not set or empty
	 * @param    {Function}    callback
	 */
	presetFromRelation: function (callback) {
		var __self = this;
		if (__self.isEmptyValues() && __self.relations && __self.relations.length === 1) {
			__self.setOrganization(__self.relations[0], callback);
		} else {
			if ('function' == typeof callback) {
				callback();
			}
		}
	},

	clearNotInMapValues: function () {
		var i;
		if (this.fieldsMapOnly) {
			for (i in this.fieldValues) {
				if (this.fieldValues.hasOwnProperty(i)) {
					if (!this.fieldsMap[i] && i !== 'accounts' && i !== 'addr_country_name') {
						this.fieldValues[i] = this.defaultValues[i];
					}
				}
			}
			if (this.valuesByMap && this.selectedRelationByMap) {
				for (i in this.selectedOrgValues) {
					if (this.selectedOrgValues.hasOwnProperty(i)) {
						if (!this.fieldsMap[i] && i !== 'addr_country_name' && this.defaultValues.hasOwnProperty(i)) {
							this.selectedOrgValues[i] = this.defaultValues[i];
						}
					}
				}
			}
		}
	},

	/**
	 * set partner org
	 * @param    {Object}     partnerOrg
	 */
	setPartnerOrg: function (partnerOrg) {
		this.partnerOrgValues = partnerOrg;
		if (this.partnerBtn) {
			this.partnerOrgValues ? this.partnerBtn.setDisabled(false) : this.partnerBtn.setDisabled(true);
		}
	},

	setOrgIdForCatalog: function (orgId, catalogType) {
		var __self = this,
			prevId;

		if (catalogType === 'DELCAT') {
			prevId = __self.relationsFromDelcatByOrgId;
			__self.relationsFromDelcatByOrgId = orgId;
		} else if (catalogType === 'LOCCAT') {
			prevId = __self.relationsFromLoccatByOrgId;
			__self.relationsFromLoccatByOrgId = orgId;
		}

		if (this.selectBtn) {
			this.selectBtn.setDisabled(!orgId);
			if (__self.selectedOrgValues && (!orgId || prevId != orgId)) {
				__self.selectedOrgValues = undefined;
				__self.selectedOrg = null;
				__self.setValues({});
				__self.resetBtn ? __self.resetBtn.setDisabled(true) : null;
			}
		}
	},
	/**
	 * set org id for delcat select
	 * @param    {String}     id
	 */
	setOrgIdForDelcat: function (id) {
		this.setOrgIdForCatalog(id, 'DELCAT');
	},
	/**
	 * Returns currently set org id for delcat usage
	 * @returns {String|*}
	 */
	getOrgIdForDelcat: function () {
		return this.relationsFromDelcatByOrgId;
	},
	showModalDelcatSelect: function () {
		var __self = this;

		createModalCatalogSelect(
			__self.relationsFromDelcatByOrgId,
			function (org) {
				__self.setOrganization(org);
			},
			'delcat',
			__self.ownCatalog
		);
	},
	/**
	 * set org id for loccat select
	 * @param    {String}     id
	 */
	setOrgIdForLoccat: function (id) {
		this.setOrgIdForCatalog(id, 'LOCCAT');
	},
	/**
	 * Returns currently set org id for loccat usage
	 * @returns {String|*}
	 */
	getOrgIdForLoccat: function () {
		return this.relationsFromLoccatByOrgId;
	},
	showModalRemoteSelect: function () {
		var __self = this;
		if ('function' == typeof __self.createModalRemoteSelect) {
			__self.createModalRemoteSelect(
				__self.relationsRemoteURL,
				function (org) {
					__self.setOrganization(org);
				},
				'object' == typeof __self.createModalRemoteSelectOptions
					? Ext.apply({}, __self.createModalRemoteSelectOptions)
					: __self.createModalRemoteSelectOptions
			);
		}
	},
	showModalLoccatSelect: function () {
		var __self = this;

		createModalCatalogSelect(
			__self.relationsFromLoccatByOrgId,
			function (org) {
				__self.setOrganization(org);
			},
			'loccat',
			__self.ownCatalog
		);
	},
	showModalCompanySelect: function () {
		var __self = this;
		createModalRelationSelect(__self.relations, function (org) {
			__self.setOrganization(org);
		});
	},
	/**
	 * convert organization values  to values suitable for control. To be replaced by exact methods for exact instances.
	 * @param    {Object}    org
	 * @returns    {Object}
	 */
	processCompanyValues: function (org) {
		var __self = this,
			values;
		const defaultOrgConverter = orgSelectorMethods.getDefaultOrgConverter();
		values = __self.processValues ? __self.processValues(org) : defaultOrgConverter(org);
		__self.presetCompanyAndAddressTypes(values);
		return values;
	},

	/**
	 * Sets company type & address type if it is missing in parameters
	 * @param values
	 */
	presetCompanyAndAddressTypes: function (values) {
		var __self = this,
			isNewOrg = __self.isEmptyValues();

		if ('object' !== typeof values) {
			return;
		}

		var countriesStore = edi.stores.initCountryFullStore(),
			record = null;

		// legacy docs
		if (values['addr_country'] && !values['addr_country_name']) {
			record = countriesStore.findRecord('iso_2', values['addr_country']);

			if (record) {
				values['addr_country_name'] = record.get('name');
			}
		}

		//fns docs - foreign
		if (values['addr_for_country'] && !values['addr_for_country_name']) {
			record = countriesStore.findRecord('iso_number_3', values['addr_for_country']);
			if (record) {
				values['addr_for_country_name'] = record.get('name');
			}
		}

		//fns docs - russian
		if (values['addr_rus_region'] && !values['addr_for_country']) {
			record = countriesStore.findRecord('iso_2', edi.constants.DEFAULT.COUNTRY);
			if (record) {
				values['addr_country_name'] = record.get('name');
			}
		}

		if (0 !== values['address_type'] && 1 !== values['address_type'] && 2 !== values['address_type']) {
			var addrType = 0;
			if (!isNewOrg) {
				//Russian address by default for new org
				var country = values['addr_country'],
					fnsForeignCountry = values['addr_for_country'],
					garAddr = values['addr_code_gar'],
					isLegacyAddressForeign = country && country !== edi.constants.DEFAULT.COUNTRY_ISO_3,
					isForeignAddress = isLegacyAddressForeign || !!fnsForeignCountry,
					isFiasAddress = !!values['addr_fias_region'];
				if (isForeignAddress) {
					addrType = 1;
				}

				if (!isForeignAddress) {
					addrType = 0;
				}

				if (garAddr) {
					addrType = 2;
				} else if (isFiasAddress) {
					addrType = 3;
				}
			}

			values['address_type'] = addrType;
		}

		if (values['address_type'] == 0 || values['addr_country'] === edi.constants.DEFAULT.COUNTRY) {
			var regionCode = values['addr_rus_region'] || values['addr_region_code'];
			if (regionCode) {
				var regionsStore = edi.stores.initRegionsStore();
				record = regionsStore.findRecord('id', regionCode);
				if (record) {
					values['addr_region_name'] = record.get('name');
				}
			}
		}

		const isDefaultType = (edi.selectors.orgConfig?.DEFAULT_NAMES || []).some((it) => it === values['type']);
		if (!isDefaultType) {
			var value = 'company'; // for new org
			if (!__self.isEmptyValues()) {
				var typeConfig = (edi.selectors.orgConfig?.DEFAULT || []).find(function (obj) {
					var companyType;
					var orgKey = Object.keys(obj)[0];
					var orgValues = obj[orgKey];
					if (!Array.isArray(orgValues)) {
						orgValues = [orgValues];
					}
					orgValues.forEach(function (orgValue) {
						if (values[orgValue]) {
							companyType = orgKey;
						}
					});
					return !!companyType;
				});
				if (typeConfig) {
					value = Object.keys(typeConfig)[0];
				}
			}

			values['type'] = value;
		}
	},

	/**
	 * Set organization to selected relation & disable buttons
	 * @param org
	 * @param callback
	 */
	setOrganization: function (org, callback) {
		var __self = this,
			continueSetOrganization = function () {
				var values = __self.processCompanyValues(org);
				__self.selectedOrgValues = values;
				__self.selectedOrg = org;
				__self.originalValues = __self.selectedOrgValues;

				__self.setValues(values);
				__self.editBtn ? __self.editBtn.setDisabled(false) : null;
				__self.resetBtn ? __self.resetBtn.setDisabled(false) : null;
				'function' == typeof callback ? callback() : null;
			};

		if (__self.downloadBankAccountsData && org?.id && (!org.accounts || !org.accounts.length)) {
			__self.setDisabled(true);
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.USER.BANK_ACCOUNTS.GET, { orgId: org.id }),
				'GET',
				undefined,
				function (data) {
					if (data && data.items) {
						org.accounts = data.items;
					}
				},
				undefined,
				function () {
					__self.setDisabled(false);
					continueSetOrganization();
				}
			);
		} else {
			continueSetOrganization();
		}
	},

	preConvertFormValues: function (values) {
		var deleteIPValues = function () {
			delete values.individual_lastname;
			delete values.individual_firstname;
			delete values.individual_patronymicname;
			delete values.individual_inn;
			delete values.individual_certificateRegistrationIP;
		};
		var deleteIParticipantValues = function () {
			delete values.physical_lastname;
			delete values.physical_firstname;
			delete values.physical_patronymicname;
		};
		var deleteLegalValues = function () {
			// для legacy документов individual_inn хранится в company_inn (см edi.converters.convertOrgToPartie)
			if (values.individual_inn) {
				delete values.company_inn;
			}
			delete values.company_kpp;
		};
		var deleteNonLegalValues = function () {
			delete values.foreign_name;
			delete values.foreign_info;
			delete values.notregistered_name;
			delete values.notregistered_info;
		};
		var deletePersonValues = function () {
			delete values.person_lastname;
			delete values.person_firstname;
			delete values.person_patronymicname;
			delete values.person_snils;
			delete values.edisoft_id;
			delete values.person_inn;
			delete values.birthday;
		};

		switch (values.type) {
			case 'company':
				deleteIPValues();
				deleteNonLegalValues();
				deleteIParticipantValues();
				deletePersonValues();
				break;
			case 'individual':
				deleteLegalValues();
				deleteNonLegalValues();
				deleteIParticipantValues();
				deletePersonValues();
				break;
			case 'individualParticipant':
				deleteIPValues();
				deleteLegalValues();
				deleteNonLegalValues();
				deletePersonValues();

				delete values.company_name;
				break;
			case 'legalForeigner':
				deleteIPValues();
				deleteLegalValues();
				deleteIParticipantValues();
				deletePersonValues();

				delete values.company_name;
				delete values.notregistered_name;
				delete values.notregistered_info;
				break;
			case 'companyNotRegistered':
				deleteIPValues();
				deleteLegalValues();
				deleteIParticipantValues();
				deletePersonValues();

				delete values.company_name;
				delete values.foreign_name;
				delete values.foreign_info;
				break;

			case 'person':
				deleteIPValues();
				deleteLegalValues();
				deleteNonLegalValues();
				deleteIParticipantValues();

				delete values.foreign_name;
				delete values.foreign_info;
				break;
		}

		return values;
	},

	/**
	 * set values and update validity and details
	 * @param    {Object}    values
	 */
	setValues: function (values) {
		const __self = this;
		var relation = this.selectedOrgValues || {};
		this.ownBtn ? this.ownBtn.setDisabled(false) : null;
		this.partnerBtn ? this.partnerBtn.setDisabled(false) : null;
		var allFieldsAreEmpty = true;
		for (var i in values) {
			if (values.hasOwnProperty(i)) {
				if (values[i]) {
					allFieldsAreEmpty = false;
				} else if (null === values[i]) {
					values[i] = undefined;
					//Needed to correctly work with ext applyIf method
				}
			}
		}
		if (this.allowBlank && allFieldsAreEmpty) {
			this.is_valid = true;
		} else {
			this.is_valid = !allFieldsAreEmpty;
		}

		this.fieldValues = values;
		Ext.applyIf(this.fieldValues, relation);
		Ext.applyIf(this.fieldValues, this.defaultValues);
		this.fieldValues = this.preConvertFormValues(this.fieldValues);
		this.clearNotInMapValues();
		this.presetCompanyAndAddressTypes(this.fieldValues);
		this.hasData = !this.isEmptyValues();
		this.renderInfoPanelContents(true);

		var field = __self._hiddenFields[`_self_${__self.name}_`];
		if (field) {
			field.setValue(__self.fieldValues);
		}

		var objValid = {};
		if (!this.disableAutoValidation) {
			var allMandatoryFieldsAreOk = true;
			for (i in this.fieldValues) {
				if (this.fieldValues.hasOwnProperty(i)) {
					objValid[i] = !(this.mandatoryFields[i] && !this.fieldValues[i]);
					if (allMandatoryFieldsAreOk && !objValid[i]) {
						allMandatoryFieldsAreOk = false;
					}
				}
			}
			if (!allMandatoryFieldsAreOk) {
				this.is_valid = false;
			}
			this.markInvalid();
		}

		this.callback(this.fieldValues, this.selectedOrg, objValid);
		this.updateLayout();
	},

	/**
	 * Renders control data summary
	 * @param    {Boolean}    updateButton    true to update change button label text
	 */
	renderInfoPanelContents: function (updateButton) {
		var __self = this,
			isEmpty = this.isEmptyValues(),
			originalValues = this.fieldValues,
			fieldValues;
		__self.suspendLayouts();
		__self.infoPanel.removeAll();
		__self.presetCompanyAndAddressTypes(originalValues);
		fieldValues = edi.utils.stringifyObjectFields(originalValues);

		var emptyTemplate = `<span class="${__self.emptyLabelCls}">${edi.i18n.getMessage(
			'value.not.specified'
		)}</span>`;

		var i, hidden;
		if (Ext.isArray(__self.hideInTemplate) && __self.hideInTemplate.length) {
			for (i = 0; i < __self.hideInTemplate.length; i++) {
				hidden = __self.hideInTemplate[i];
				if (fieldValues.hasOwnProperty(hidden)) {
					fieldValues[hidden] = '';
				}
			}
		}

		let tmpFieldValues = Ext.clone(fieldValues);

		Object.entries(tmpFieldValues).forEach(([key, value]) => {
			tmpFieldValues[key] = edi.utils.safeString(value);
		});

		__self.infoPanel.update(
			createLabel({
				html: isEmpty && __self.readOnly ? emptyTemplate : __self.infoPanelComponentTpl.apply(tmpFieldValues)
			})
		);

		if (__self.useHiddenFields && __self.fieldsMap) {
			for (i in __self.fieldsMap) {
				if (__self.fieldsMap.hasOwnProperty(i)) {
					var name =
						(__self.fieldsMap[i].name ? __self.fieldsMap[i].name : __self.fieldsMap[i]) ||
						'__UnknownFieldName__';
					var field = __self._hiddenFields[name];
					if (field) {
						field.setValue(__self.fieldValues[i]);
					}
				}
			}
		}

		if (updateButton && this.editBtn) {
			let isEdit = !isEmpty || this.relationsOnly;
			let editBtnText = edi.i18n.getMessage(isEdit ? 'form.btn.edit' : 'form.btn.add');

			this.editBtn.setText(editBtnText);

			this.editBtn.removeCls('test-companySelector-btn-' + (isEdit ? 'add' : 'edit'));
			this.editBtn.addCls('test-companySelector-btn-' + (isEdit ? 'edit' : 'add'));

			__self.resetBtn ? __self.resetBtn.setDisabled(isEmpty) : null;
			if (__self.relationsOnly) {
				__self.editBtn.setDisabled(isEmpty);
			}
		}

		__self.markEmpty();
		__self.markReadOnly();
		__self.resumeLayouts();
	},
	/**
	 * Returns control values collected from modal form
	 * @param    {Boolean}    useMap    true to returnd values mapped to passed fields schema
	 */
	getValues: function (useMap) {
		if (this.fieldsMap && useMap) {
			return this.getValuesByMap();
		} else {
			return Ext.clone(this.fieldValues);
		}
	},
	/**
	 * Returns values according to components fields map
	 */
	getValuesByMap: function () {
		var values = null;
		if (this.fieldsMap) {
			values = {};
			for (var i in this.fieldsMap) {
				if (this.fieldsMap.hasOwnProperty(i) && this.fieldValues[i] !== '') {
					if ('object' == typeof this.fieldsMap[i]) {
						values[this.fieldsMap[i].name] = this.fieldValues[i];
					} else {
						values[this.fieldsMap[i]] = this.fieldValues[i];
					}
				}
			}
		}
		return values;
	},
	/**
	 * Returns is component not filled
	 * @returns    {Boolean}
	 */
	isEmptyValues: function () {
		var res = true;
		const values = this.fieldValues;
		for (let i in values) {
			//игнорируем поле Тип, т.к. это чисто ui для определения типа адреса (юридический\почтовый\фактический)
			if (
				values.hasOwnProperty(i) &&
				!(i === 'type' || i === 'address_type') &&
				(typeof values[i] === 'object' ? !Ext.isEmpty(values[i]) : !!values[i])
			) {
				res = false;
				break;
			}
		}
		return res;
	},

	isModalReadOnly() {
		let __self = this;
		return !!__self.modalConf?.readOnly;
	},

	isOrgFromRelation() {
		const __self = this;
		return !!__self.orgFromRelation;
	},

	/**
	 * Проверяет, является ли поле из карточки организации, есть ли у него значение и находится ли оно в списке на блокировку.
	 * @param {Object} options - Параметры метода.
	 * @param {string} options.fieldName - Имя поля для проверки.
	 * @returns {boolean}
	 */
	isDisabledFieldFromOrgCard({ fieldName }) {
		const __self = this;
		/**
		 * Оригинальное значение поля организации, не измененное ручками.
		 */
		const originalFieldValue = __self.originalValues[fieldName];
		/**
		 * Отображаемое значение поля
		 */
		const fieldValue = __self.fieldValues[fieldName];
		/**
		 * Имеет ли поле значение и находится в списке на блокировку.
		 */
		const isDisabledInOrgCard =
			!!originalFieldValue && !!fieldValue && !!__self.disabledFieldsFromOrgCard[fieldName];
		return __self.isOrgFromRelation() && isDisabledInOrgCard;
	},

	/**
	 * Поле задизейблено если:
	 * - орг из реляции (orgFromRelation)
	 * - у поля есть значение (originalValues[fieldName])
	 * - явно не указано, что поле разрешено изменять forceChangeFields[fieldName]/forceChangeAnyField
	 * @param {Object} options - Параметры метода.
	 * @param {string} options.fieldName - Имя проверяемого поля.
	 * @returns   {Boolean}
	 */
	isFieldDisabled({ fieldName }) {
		const __self = this;
		const allowForceChange = __self.forceChangeAnyField || __self.forceChangeFields?.hasOwnProperty(fieldName);
		return __self.isDisabledFieldFromOrgCard({ fieldName }) && !allowForceChange;
	},

	/**
	 * Собирает значения с полей, которые были задизейблены (disabledFieldsFromOrgCard), так как они из карточки организации.
	 * Функция костыль, т.к. проще для этих полей указывать readOnly в конфиге, но это рискованно
	 * т.к. могут быть слушатели на событие disable и другие неочевидные эффекты.
	 * @returns {Object} Объект, содержащий значения disabled полей.
	 */
	getDisabledValuesFromOrgCard() {
		const __self = this;
		const form = __self.formPanel.getForm();
		const formFields = form.getFields().items;
		const disabledFormFields = formFields.filter((field) => __self.isFieldDisabled({ fieldName: field.name }));
		const values = disabledFormFields.reduce((values, field) => {
			const fieldName = field.getName();
			if (fieldName) {
				values[fieldName] = field.getValue();
			}
			return values;
		}, {});
		return values;
	},

	createTextFieldCmp: function (config) {
		let __self = this;
		var fieldName = edi.utils.getObjectProperty(config, 'name');

		if (!fieldName) {
			return null;
		}

		var title = config.title;

		delete config.type;
		delete config.title;

		if (config.hasOwnProperty('emptyText')) {
			config.emptyText = edi.i18n.getMessage(config.emptyText);
		}

		if (config.hasOwnProperty('regexText')) {
			config.regexText = edi.i18n.getMessage(config.regexText);
		}

		if (config.hasOwnProperty('invalidText')) {
			config.invalidText = edi.i18n.getMessage(config.invalidText);
		}

		delete config.grid;
		Ext.applyIf(config, {
			value: __self.fieldValues[fieldName] || config.defaultValue, //
			allowBlank: !!__self.mandatoryFields ? !__self.mandatoryFields[fieldName] : true,
			disabled: __self.isFieldDisabled({ fieldName }) || __self.isModalReadOnly(),
			modalInstance: __self,
			enforceMaxLength: config.hasOwnProperty('maxLength')
		});

		delete config.defaultValue;
		const field = createTextField(config);
		return config.withoutBlock === true
			? field
			: createFieldBlock(
					Ext.merge(
						{
							cls: FIELD_BLOCK_CLS.small,
							title: edi.i18n.getMessage(title),
							items: [field]
						},
						config?.panelConf || {}
					)
			  );
	},

	createComboboxCmp: function (config, comboType) {
		config = config || {};
		let __self = this;
		var comboConf = config.fieldConfig || {},
			fieldName = comboConf.name,
			storeConf = null,
			isAutocomplete = comboType === 'autocomplete',
			combo,
			storeMethod = comboConf.store,
			title = config.title;

		if ('string' == typeof storeMethod && 'function' === typeof edi.stores[storeMethod]) {
			comboConf.store = edi.stores[storeMethod]();
		}

		if (isAutocomplete) {
			storeConf = Ext.clone(comboConf.store);
			if ('string' == typeof storeConf.model) {
				storeConf.model = edi.models.getModel(storeConf.model);
			}

			delete comboConf.store;
		} else {
			Ext.applyIf(comboConf, {
				forceSelection: true,
				valueField: 'id',
				anyMatch: true,
				disabled: __self.isFieldDisabled({ fieldName }) || __self.isModalReadOnly(),
				value: __self.fieldValues[fieldName]
			});
		}

		combo = isAutocomplete ? createAutocomplete(comboConf, storeConf) : createCombo(comboConf);
		combo.modalInstance = __self;

		return config.withoutBlock === true
			? combo
			: createFieldBlock(
					Ext.merge(
						{
							cls: FIELD_BLOCK_CLS.small,
							title: edi.i18n.getMessage(title),
							items: [combo]
						},
						config?.panelConf || {}
					)
			  );
	},

	createRadioCmp: function (config) {
		const fieldConfig = Ext.clone(config) || {};
		const title = edi.i18n.getMessage(config.title);

		return createRadio({
			...fieldConfig,
			boxLabel: title
		});
	},

	createDateFieldCmp: function (config) {
		let __self = this;
		var fieldConfig = Ext.clone(config),
			fieldName = edi.utils.getObjectProperty(fieldConfig, 'name');
		var title = fieldConfig?.title ?? '';

		delete fieldConfig.title;

		if (!fieldName) {
			return null;
		}

		Ext.applyIf(fieldConfig, {
			value: __self.fieldValues[fieldName]
		});

		if (fieldConfig.hasOwnProperty('emptyText')) {
			fieldConfig.emptyText = edi.i18n.getMessage(fieldConfig.emptyText);
		}

		if (config.hasOwnProperty('invalidText')) {
			config.invalidText = edi.i18n.getMessage(config.invalidText);
		}

		var field = createDateField(fieldConfig);
		return config.withoutBlock === true
			? field
			: createFieldBlock(
					Ext.merge(
						{
							cls: FIELD_BLOCK_CLS.small,
							title: edi.i18n.getMessage(title),
							items: [field]
						},
						config?.panelConf || {}
					)
			  );
	},

	createFieldSetCmp: function (config) {
		let __self = this;
		var fieldsetConf = Ext.apply(
			{
				userCls: `${componentCls}-fieldset`
			},
			config
		);

		if (config.hasOwnProperty('title')) {
			fieldsetConf.title = edi.i18n.getMessage(config.title);
		}

		fieldsetConf = Ext.applyIf(fieldsetConf, config.panelConf); // To apply hidden property if needed
		fieldsetConf.items = __self.createFieldsArray(config.fields);

		return createFieldSet(fieldsetConf);
	},

	createCheckboxCmp: function (config) {
		let __self = this;
		var fieldName = edi.utils.getObjectProperty(config, 'name');

		if (!fieldName) {
			return null;
		}

		var checkboxConf = Ext.apply(
			{
				boxLabel: edi.i18n.getMessage(config.title),
				checked: __self.fieldValues[fieldName]
			},
			config.fieldConfig
		);

		return createCheckbox(checkboxConf);
	},

	createPanelCmp: function (config) {
		let __self = this;
		var conf = Ext.clone(config);
		var panelConf = Ext.apply(
			{
				layout: 'column',
				margin: '0 0 5 0',
				cls: __self.fieldBlockCls
			},
			conf.panelConf
		);

		panelConf.items = __self.createFieldsArray(conf.fields);
		return createPanel(panelConf);
	},

	createSelectCmp: function (config) {
		let __self = this;
		config = config || {};

		let selectConf = config.fieldConfig || {},
			fieldName = selectConf.name,
			select,
			genUrl,
			title = config?.title ?? '',
			relation = __self.selectedOrgValues || {};

		Ext.applyIf(selectConf, {
			forceSelection: true,
			valueField: 'id',
			anyMatch: true,
			isOnlyInput: true,
			allowBlank: config.allowBlank,
			disabled: __self.isFieldDisabled({ fieldName }) || __self.isModalReadOnly(),
			value: __self.fieldValues[fieldName],
			modalInstance: __self,
			createURL: function () {
				return genUrl || config.url;
			}
		});

		if (relation && relation.id && 'function' == typeof config.onCreate) {
			config.onCreate(function (url) {
				genUrl = url;
			}, relation);
		}

		select = createPanelWithModalSelect(selectConf);
		select.setUrl = function (url) {
			genUrl = url;
		};

		return config.withoutBlock === true
			? select
			: createFieldBlock(
					Ext.merge(
						{
							cls: FIELD_BLOCK_CLS.small,
							itemId: config?.itemId,
							blockId: config?.blockId,
							title: edi.i18n.getMessage(title),
							items: [select]
						},
						config?.panelConf || {}
					)
			  );
	},

	createEditableGrid: function (config) {
		let __self = this;
		var fieldConfig = Ext.clone(config),
			fieldName = edi.utils.getObjectProperty(fieldConfig, 'name');

		if (!fieldName) {
			return null;
		}
		__self.formGrids[fieldName] = createEditableGrid(fieldConfig.config, __self.fieldValues[fieldName]);
		return __self.formGrids[fieldName];
	},

	createFieldsBlockCmp: function (config) {
		let __self = this;
		return createFieldBlock(
			Ext.merge(
				{
					cls: FIELD_BLOCK_CLS.small,
					itemId: config?.itemId,
					blockId: config?.blockId,
					items: __self.createFieldsArray(config?.fields ?? {})
				},
				config?.panelConf || {},
				config?.containerConf || {}
			)
		);
	},

	createContainerCmp: function (config) {
		let __self = this;
		return createContainer(
			Ext.merge(
				{
					layout: config.layout || 'auto',
					itemId: config?.itemId,
					blockId: config?.blockId,
					items: __self.createFieldsArray(config?.fields ?? {})
				},
				config?.panelConf || {}
			)
		);
	},

	createRowsEditingBlockCmp: function (config) {
		let __self = this;
		let fieldConfig = Ext.clone(config),
			fieldName = edi.utils.getObjectProperty(fieldConfig, 'name');

		if (!fieldName) {
			return null;
		}

		__self.rowsBlock[fieldName] = createRowsBlock({
			contentCols: 6,
			buttonsCols: 6,
			createContentFieldsFn: fieldConfig.createRow,
			initialData: edi.utils.getObjectProperty(__self.fieldValues, fieldName, true),
			isReadOnly: __self.readOnly,
			onAddRow() {
				if (typeof fieldConfig.checkModuleValid === 'function') {
					fieldConfig.checkModuleValid();
				}
			},
			onRemoveRow() {
				if (typeof fieldConfig.checkModuleValid === 'function') {
					fieldConfig.checkModuleValid();
				}
			}
		});

		return __self.rowsBlock[fieldName];
	},

	createFieldCmp: function (config) {
		let __self = this;
		var fieldConstructors = {
			text: __self.createTextFieldCmp,
			combo: __self.createComboboxCmp,
			radio: __self.createRadioCmp,
			checkbox: __self.createCheckboxCmp,
			select: __self.createSelectCmp,
			number: __self.createTextFieldCmp,
			autocomplete: __self.createComboboxCmp,
			panel: __self.createPanelCmp,
			fieldset: __self.createFieldSetCmp,
			date: __self.createDateFieldCmp,
			editableGrid: __self.createEditableGrid,
			fieldsBlock: __self.createFieldsBlockCmp,
			container: __self.createContainerCmp,
			rows_editing_block: __self.createRowsEditingBlockCmp
		};
		var field = null,
			fieldType = Ext.clone(config.type) || 'text',
			constructor = fieldConstructors[fieldType];

		delete config.type;
		if (__self.showDetailsButton === true) {
			config.readOnly = __self.readOnly;
			if (config.fieldConfig) {
				config.fieldConfig.readOnly = __self.readOnly;
			}
		}

		if ('function' == typeof constructor) {
			field = constructor.call(__self, config, fieldType);
		} else {
			edi.core.showWarn('Modal field type [' + fieldType + '] not recognized!');
		}

		if (field) {
			field.modalInstance = __self;
		}
		return field;
	},

	createFieldsArray: function (fieldsConfig) {
		let __self = this;
		var fields = [],
			fieldsConf = [];
		var relation = __self.selectedOrgValues || {};
		for (var i in fieldsConfig) {
			if (!fieldsConfig.hasOwnProperty(i) || !fieldsConfig[i]) {
				continue;
			}

			var conf = Ext.clone(fieldsConfig[i]) || {},
				inputConfig = conf.fieldConfig ? conf.fieldConfig : conf,
				field = null;

			if ('function' == typeof inputConfig.value) {
				inputConfig.value = inputConfig.value(__self.fieldValues);
			}

			if ('function' == typeof inputConfig.disabled) {
				inputConfig.disabled = inputConfig.disabled(relation);
			}

			if ('function' == typeof conf.hidden) {
				conf.panelConf = conf.panelConf || {};
				conf.panelConf.hidden = conf.hidden(__self.fieldValues);
			}

			if (conf.panelConf?.title) {
				conf.panelConf.title = edi.i18n.getMessage(conf.panelConf.title);
			}

			fieldsConf.push(conf);
			fieldsConf.sort(function (a, b) {
				return (a.order || 0) - (b.order || 0);
			});
		}

		for (var j = 0; j < fieldsConf.length; j++) {
			field = __self.createFieldCmp.call(__self, fieldsConf[j]);

			if (field && 'function' == typeof fieldsConf[j].onCreate) {
				fieldsConf[j].onCreate(field, relation);
			}

			fields.push(field);
		}

		edi.utils.clearEmptyArrayValues(fields);
		return fields;
	},

	createTabCmp: function (config) {
		let __self = this;
		if (!config) {
			return null;
		}

		var tabConfig = config.tabConfig;
		delete config.tabConfig;

		if (edi.utils.isEmptyObject(config)) {
			return null;
		}

		Ext.applyIf(tabConfig, {
			panelType: 'tabpanel',
			closable: false,
			bodyPadding: '24 10 0 24',
			layout: 'grid',
			autoScroll: true
		});

		tabConfig.items = __self.createFieldsArray(config);
		return createTab(tabConfig);
	},

	createFormPanel: function () {
		let __self = this;
		__self.formGrids = {};
		__self.rowsBlock = {};
		let tabsConfig = __self.modalConf ? __self.modalConf.tabs : {};
		let tabs = [];
		for (var i in tabsConfig) {
			if (tabsConfig.hasOwnProperty(i) && tabsConfig[i]) {
				let conf = Ext.clone(tabsConfig[i]);
				conf.tabConfig = conf.tabConfig || {};
				let title = conf.title || 'company.tab.' + (i === 'main' ? 'registry' : i) + '.data';
				conf.tabConfig.title = edi.i18n.getMessage(title);
				tabs.push(__self.createTabCmp(conf));
			}
		}

		edi.utils.clearEmptyArrayValues(tabs);

		return createTabPanel({
			cls: TAB_PANEL_CLS.simpleWithoutPadding,
			items: tabs
		});
	},

	onFormCreate: function () {},

	createModalFormPanel: function () {
		var __self = this;
		__self.formPanel = createForm({
			layout: 'fit',
			submitEmptyText: false,
			items: [__self.createFormPanel()]
		});
		__self.onFormCreate(__self, __self.formPanel);
		return __self.formPanel;
	},

	/**
	 * Creates modal company data form
	 */
	showModalCompanyControl: function () {
		let __self = this;
		__self.callParent();
		__self.onFormCreate(__self, __self.formPanel);
	},

	getFormValuesFunc: function (formPanel) {
		var __self = this;
		const values = Object.assign(edi.utils.collectFormValues(formPanel), __self.getDisabledValuesFromOrgCard());
		for (var key in __self.formGrids) {
			if (__self.formGrids.hasOwnProperty(key)) {
				edi.utils.setObjectProperty(values, key, edi.utils.getDataFromGrid(__self.formGrids[key]));
			}
		}
		for (var key in __self.rowsBlock) {
			if (__self.rowsBlock.hasOwnProperty(key)) {
				let rowValues = edi.methods.convertValuesFromMultipleFields(edi.utils.getObjectProperty(values, key));
				edi.utils.setObjectProperty(values, key, rowValues);
			}
		}
		return values;
	},

	/**
	 * Checks orgData on modalForm validity
	 * @returns	{Boolean}	validity
	 */
	checkOrgDataValid: function () {
		let __self = this;
		__self.suspendLayouts();
		let formPanel = __self.createModalFormPanel();

		let fields = formPanel.getForm().getFields();
		fields?.items.forEach((field) => {
			field.fireEvent('change', field, field.getValue());
		});
		let values = edi.utils.collectFormValues(formPanel);

		let allFieldsEmpty = true;
		edi.utils.clearEmptyValues(values);
		for (let i in values) {
			//игнорируем поле Тип, т.к. это чисто ui для определения типа адреса (юридический\почтовый\фактический)
			if (
				values.hasOwnProperty(i) &&
				!(i === 'type' || i === 'address_type') &&
				(typeof values[i] === 'object' ? !Ext.isEmpty(values[i]) : !!values[i])
			) {
				allFieldsEmpty = false;
				break;
			}
		}

		let formValid = formPanel.isValid();
		var gridValid = true;

		//editableGrid
		for (var key in __self.formGrids) {
			if (__self.formGrids.hasOwnProperty(key)) {
				if (!__self.formGrids[key].isValid()) {
					gridValid = false;
					break;
				}
			}
		}
		let valid = (__self.allowBlank && allFieldsEmpty) || (formValid && gridValid);

		__self.is_valid = valid;
		__self.resumeLayouts();
		__self.isValid();
		return valid;
	},

	getInfoPanelComponentTpl: function () {
		let __self = this;
		if (!__self.infoPanelComponentTpl) {
			__self.infoPanelComponentTpl = getOrgSelectorTpl(__self);
		}
	}
});

/**
 * Creates instance of UI.components.OrgSelector
 * @param	{Object}	cfg
 * @return	{Object}	UI.components.OrgSelector instance
 */
const createOrgSelector = function (cfg) {
	const isEmpty = cfg.valuesByMap
		? Object.entries(cfg.fieldsMap || {}).every(([key, value]) => {
				return !edi.utils.getObjectProperty(cfg.fieldValues || {}, value);
		  })
		: Ext.isEmpty(
				Object.entries(cfg.fieldValues || {}).filter(([fieldKey, fieldValue]) => {
					if (fieldKey === 'type' || fieldKey === 'address_type') return false;
					return typeof fieldValue === 'object' ? !Ext.isEmpty(fieldValue) : !!fieldValue;
				})
		  );
	if (!cfg.notReturnedVoid && isEmpty && cfg.readOnly) {
		return null;
	}

	return Ext.create('UI.components.OrgSelector', cfg);
};

export { createOrgSelector, ORG_SELECTOR_CLS };
