import '@UIkit/components/modal/Modal.js';
import { createCombo, createLabelBlock } from '@UIkit/components/fields';
import { createModalForm } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { MODAL_SIZE } from '@UIkit/components/modal';

Ext.define('UI.components.LoginSwitchOrgModal', {
	extend: 'UI.components.Modal',

	title: 'switchOrgModal.title',
	warningText: 'switchOrgModal.warning',
	suggestionText: 'switchOrgModal.suggestion',
	supportLinkText: 'switchOrgModal.support',
	productName: 'Coreplat',
	supportLinkUrl: 'http://helpdesk.ediweb.eu/',
	suggestionLogoutText: 'switchOrgModal.suggestion.logout',
	logoutBtnText: 'switchOrgModal.logout',
	modal: false,
	closable: true,
	draggable: false,
	width: MODAL_SIZE.widthSmall,

	modifyConfig: function (externalCfg) {
		const me = this;
		const conf = Ext.merge({}, externalCfg);
		conf.items = conf.items || me.createItems();
		conf.buttons = conf.buttons || me.createButtons();
		conf.listeners = conf.listeners || me.createListeners();
		return conf;
	},

	getAvailableOrgsList: function () {
		const orgs = edi.core.getUserData()?.orgs || [];
		const currentOrgId = edi.core.getUserData()?.org?.id;
		return orgs.length > 1 ? orgs.filter((org) => org.id !== currentOrgId) : orgs;
	},

	createOrgCombo: function () {
		const me = this;
		me.orgCombo = createCombo({
			fieldLabel: edi.i18n.getMessage('organization'),
			store: edi.stores.createInlineStore(me.getAvailableOrgsList(), 'SIMPLE'),
			listeners: {
				change: function (_cmp, newValue) {
					me.loginBtn?.setDisabled(!newValue);
				}
			}
		});
		return me.orgCombo;
	},

	logoutHandler: function () {
		edi.login.logout();
	},

	createTextBlock: function () {
		const me = this;
		const supportLinkText = edi.i18n.getMessage(me.supportLinkText);
		const supportLink = `<a href="${me.supportLinkUrl}" class="link-label" target="_blank">${supportLinkText}</a>`;
		const suggestionTextHasTranslation = edi.i18n.getMessage(me.suggestionText) !== me.suggestionText;
		const supportHtml = suggestionTextHasTranslation
			? edi.i18n.getMessage(me.suggestionText, [supportLink])
			: supportLink;

		const logoutLinkText = edi.i18n.getMessage(me.logoutBtnText);
		const logoutLink = `<a id="logout-link" href="" class="link-label" target="_self">${logoutLinkText}</a>`;
		const logoutTextHasTranslation = edi.i18n.getMessage(me.suggestionLogoutText) !== me.suggestionLogoutText;
		const logoutHtml = logoutTextHasTranslation
			? edi.i18n.getMessage(me.suggestionLogoutText, [logoutLink])
			: logoutLink;

		me.textBlock = createLabelBlock({
			gap: [24, 16],
			contents: [
				{
					html: edi.i18n.getMessage(me.warningText, [me.productName, edi.core.getUserData()?.org?.name || ''])
				},
				{
					isNewLine: true,
					html: supportHtml
				},
				{
					isNewLine: true,
					html: logoutHtml
				}
			],
			listeners: {
				afterrender: function () {
					me.el.dom.querySelector('#logout-link')?.addEventListener('click', function (e) {
						e.preventDefault();
						me.logoutHandler();
					});
				}
			}
		});
		return me.textBlock;
	},

	createItems: function () {
		const me = this;
		me.modalForm = createModalForm({
			items: [me.createTextBlock(), me.createOrgCombo()]
		});
		return [me.modalForm];
	},

	createLoginButton: function () {
		const me = this;
		me.loginBtn = createButton({
			cls: BUTTON_CLS.primary,
			text: edi.i18n.getMessage('form.btn.login'),
			disabled: true,
			handler: function () {
				me.changeOrgAndReload(me.orgCombo.getValue());
			}
		});
		return me.loginBtn;
	},

	createButtons: function () {
		const me = this;
		return [me.createLoginButton()];
	},

	createListeners: function () {
		const me = this;
		return {
			close: () => me.changeOrgAndReload(null)
		};
	},

	changeOrgAndReload: function (newOrgId) {
		if (newOrgId) {
			edi.login.setCurrentOrganization(
				newOrgId,
				function () {
					document.location.reload();
				},
				undefined,
				true
			);
		} else {
			window.location.reload();
		}
	}
});

const createLoginSwitchOrgModal = (cfg) => Ext.create('UI.components.LoginSwitchOrgModal', cfg);

export { createLoginSwitchOrgModal };
