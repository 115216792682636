export interface CertificateHandler {
	_selectedCertificate: AnyObject | null | undefined;
	_selectedCertObj: AnyObject | null | undefined;
	_isLocalCert: boolean | undefined;
	get(): CertificateHandler['_selectedCertificate'];
	set(
		cert?: CertificateHandler['_selectedCertificate'],
		certObj?: CertificateHandler['_selectedCertObj'],
		isLocalCert?: CertificateHandler['_isLocalCert']
	): void;
}

export interface PoaHandler {
	_selectedPoa: Number | null | undefined;
	get(): PoaHandler['_selectedPoa'];
	set(poa: PoaHandler['_selectedPoa']): void;
	_poaConfirmChecked: Boolean;
	setPoaConfirmCheck(poa: PoaHandler['_poaConfirmChecked']): void;
	getPoaConfirmCheck(): PoaHandler['_poaConfirmChecked'];
}

export interface PreselectedCertObject {
	cert: CertificateHandler['_selectedCertificate'];
	certObject: CertificateHandler['_selectedCertObj'];
}

export interface SignProcessConfig {
	document?: AnyObject | number;
	maskElement?: AnyObject;
	callback?: (
		failed: boolean,
		data?: AnyObject,
		cert?: CertificateHandler['_selectedCertificate'],
		poa?: PoaHandler['_selectedPoa'],
		silent?: boolean,
		poaConfirmChecked?: PoaHandler['_poaConfirmChecked']
	) => void;
	contentId?: string;
	beforeSign?: (success: SignProcess['beforeSendSignature'], fail: SignProcess['failure']) => void;
	skipMaskHandling?: boolean;
	certificateHandler?: CertificateHandler;
	poaHandler?: PoaHandler;
	properties?: Partial<{
		[key: string]: any;
		signRefuse: () => void;
		signLoadingText: string;
		signObjectProcessor: (signedContent: SignProcess['_signedContent']) => AnyObject;
		signUrl: string;
		signUrlMethod: string;
		signContentUrl: string;
		signContentUrlMethod: string;
		getSignContentPayload: (signProcess: SignProcess) => AnyObject | string | null | undefined;
		signContent: string;
		beforeSetSignature: (success: SignProcess['prepareContentToSign'], fail: SignProcess['failure']) => void;
		disabledPoa: boolean;
		loadFromUserProfile: any;
		isShowDuplicateModal: boolean;
		showActiveCertConfirm: boolean;
		doNotUseDefaultCert: boolean;
		force: true;
		usePoaConfirmationKey: string;
		preselectedCert: PreselectedCertObject;
	}>;
}

/**
 * Class to encapsulate temp variables and control steps of signing process
 * all params passed to constructor are saved in this.args
 */
export class SignProcess {
	args: SignProcessConfig;
	certificateHandler: CertificateHandler;
	poaHandler: PoaHandler;
	_signedContent: String;
	_usePoaConfirmationKey = 'sign.confirm.use_poa';

	constructor(config: SignProcessConfig) {
		this.args = this.modifyConfig(config);
	}

	/**
	 * Transforms config passed to constructor
	 * and provide changes in "this"
	 **/
	modifyConfig(config: SignProcessConfig): SignProcessConfig {
		const me = this;

		me.certificateHandler = config.certificateHandler || {
			_selectedCertificate: null,
			_selectedCertObj: null,
			_isLocalCert: undefined,
			get: function () {
				const handler = this;
				return handler._selectedCertificate;
			},
			set: function (cert, certObj, isLocalCert) {
				const handler = this;
				//сохранять будем только если аргумент передавался, иначе мы потеряем данные, которые уже были сохранены,
				//например при вызове set(cert)
				if (arguments.length >= 1) {
					handler._selectedCertificate = cert;
				}
				if (arguments.length >= 2) {
					handler._selectedCertObj = certObj;
				}
				if (arguments.length >= 3) {
					handler._isLocalCert = !!isLocalCert;
				}
			}
		};
		me.certificateHandler.set(me.certificateHandler.get());

		me.poaHandler = config.poaHandler || {
			_selectedPoa: null,
			get: function () {
				const handler = this;
				return handler._selectedPoa;
			},
			set: function (poa) {
				const handler = this;
				handler._selectedPoa = poa;
			},
			_poaConfirmChecked: false,
			setPoaConfirmCheck: function (poaConfirmChecked) {
				const handler = this;
				handler._poaConfirmChecked = poaConfirmChecked;
			},
			getPoaConfirmCheck: function () {
				const handler = this;
				return handler._poaConfirmChecked;
			}
		};
		me.poaHandler.set(me.poaHandler.get());

		return config;
	}

	/**
	 * Fires after successfully signing
	 */
	finishCallback(
		failed: boolean,
		data?: AnyObject,
		cert?: CertificateHandler['_selectedCertificate'],
		poa?: PoaHandler['_selectedPoa'],
		silent?: boolean
	) {
		const me = this;
		if (typeof me.args.callback === 'function') {
			me.args.callback(failed, data, cert, poa, silent, me.poaHandler.getPoaConfirmCheck?.());
		}
	}

	/**
	 * Switches on/off loading mask on the maskElement if it is not prevented by skipMaskHandling
	 */
	maskElSetLoading(value?: String | boolean) {
		const me = this;
		if (!me.args.skipMaskHandling && typeof me.args.maskElement?.setLoading === 'function') {
			me.args.maskElement.setLoading(value);
		}
	}

	/**
	 * Fires after failure on any step of signing process
	 */
	failure(data?: AnyObject, silent?: boolean) {
		const me = this;
		silent = typeof silent === 'boolean' ? silent : false;
		me.finishCallback(true, data, me.certificateHandler.get(), me.poaHandler.get(), silent);
		me.maskElSetLoading(false);
	}

	/**
	 * Fires when user cancel certificate or PoA selection
	 * @param	{boolean}	correctClose	false=user cancel selection, true=normal close
	 */
	refuseCallback(correctClose?: boolean) {
		const me = this;
		me.maskElSetLoading(false);
		if (!correctClose) {
			me.certificateHandler.set(null, null, false);
			me.poaHandler.set(null);
			me.poaHandler.setPoaConfirmCheck?.(false);
			if ('function' == typeof me.args.properties?.signRefuse) {
				me.args.properties.signRefuse();
			}
		}
	}

	/**
	 * Sends this._signedContent to backend
	 */
	sendSignedContent() {
		const me = this;
		const properties = me.args.properties || {};

		me.maskElSetLoading(properties.signLoadingText || undefined);

		let signature =
			'function' == typeof properties.signObjectProcessor
				? properties.signObjectProcessor(me._signedContent)
				: {
						[edi.constants.BUSINESS_PROCESS_PROPERTIES.SIGNATURE]: me._signedContent
				  };

		if (me.poaHandler.get()) {
			signature['POA_ID'] = me.poaHandler.get();
		}
		signature = Ext.encode(signature);

		const signSuccess = function (data: AnyObject) {
			me.finishCallback(false, data, me.certificateHandler.get(), me.poaHandler.get());
		};

		const signUrl = properties.signUrl
			? properties.signUrl
			: edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, {
					documentId: typeof me.args.document === 'object' ? me.args.document?.id : me.args.document
			  });
		const signMethod = properties.signUrlMethod || 'PUT';

		edi.rest.sendRequest(signUrl, signMethod, signature, signSuccess, me.failure.bind(me));
	}

	/**
	 * Step before sending signature to backend
	 * can be used as side effect or to modify process variables or even stop the process
	 */
	beforeSendSignature() {
		const me = this;
		const nextStep = me.sendSignedContent.bind(me);

		if ('function' === typeof me.args.beforeSign) {
			//действие после генерации контента на подпись, но перед отправкой на бэк
			//так что тут его можно подменить или использовать этот колбэк просто как сайд эффект
			me.args.beforeSign(nextStep, me.failure.bind(me));
		} else {
			nextStep();
		}
	}

	/**
	 * Generates signature from content and selected certificate, and put it in this._signedContent
	 */
	generateSign(contentToSign: string) {
		const me = this;
		const nextStep = me.beforeSendSignature.bind(me);

		edi.sign.setSignature({
			content: contentToSign,
			certificate: me.certificateHandler.get(),
			callback: function (signObj: AnyObject) {
				if (!signObj.data) {
					me.failure(signObj.error);
					return;
				}

				me._signedContent = signObj.data;
				nextStep();
			}
		});
	}

	/**
	 * Gets content for signing from backend
	 */
	fetchContentToSign() {
		const me = this;
		const nextStep = me.generateSign.bind(me);
		const properties = me.args.properties || {};
		const documentId = typeof me.args.document === 'object' ? me.args.document?.id : me.args.document;

		const signContentUrl = properties.signContentUrl
			? properties.signContentUrl
			: edi.utils.formatString(edi.rest.services.DOCUMENTS.SIGN.GET, {
					documentId: me.args.contentId || documentId
			  });

		let signContentUrlMethod;
		if (properties.signContentUrlMethod) {
			signContentUrlMethod = properties.signContentUrlMethod;
		} else {
			signContentUrlMethod = properties.signContentUrl ? 'GET' : 'PUT';
		}

		const payload =
			'function' === typeof properties.getSignContentPayload
				? properties.getSignContentPayload(me)
				: Ext.encode(edi.utils.parseCertificateData(me.certificateHandler.get()).subject);
		edi.rest.sendRequest(
			signContentUrl,
			signContentUrlMethod,
			signContentUrlMethod !== 'GET' ? payload : undefined,
			function (data: AnyObject) {
				if (data.data) {
					nextStep(data.data);
				} else {
					me.failure(data);
				}
			},
			me.failure.bind(me)
		);
	}

	/**
	 * Checks if we need to fetch content, or we already have string to sign
	 * can be used as contentToSign generator function
	 */
	prepareContentToSign() {
		const me = this;

		//isLocalCert использовался в УА (там не CryptoPro)
		if (!(me.certificateHandler._isLocalCert || me.certificateHandler.get()?.Thumbprint)) {
			edi.core.showError('error.certificate.not.correct');
			me.failure();
			return;
		}

		const contentToSign = me.args.properties?.signContent;
		if (contentToSign) {
			me.generateSign(contentToSign);
		} else {
			me.fetchContentToSign();
		}
	}

	/**
	 * Fires after all checks are finished
	 * can be used for additional checking
	 */
	beforePreparingContent() {
		const me = this;
		const properties = me.args.properties || {};
		const nextStep = me.prepareContentToSign.bind(me);

		if (typeof properties.beforeSetSignature === 'function') {
			properties.beforeSetSignature(nextStep, me.failure.bind(me));
		} else {
			nextStep();
		}
	}

	/**
	 * Provide PoA selection if needed and allowed
	 */
	checkPoa() {
		const me = this;
		const properties = me.args.properties || {};
		const nextStep = me.beforePreparingContent.bind(me);

		let certificateObj = edi.utils.fullStringCertParse(me.certificateHandler.get()?.SubjectName);
		//добавлен thumbprint в объект сертификата для того чтобы бэк мог по thumbprint определять,
		// к какому конкретно сертификату прилинкована данная МЧД
		certificateObj['thumbprint'] = me.certificateHandler.get()?.Thumbprint;

		const selectPoaAndContinue = function () {
			if (me.poaHandler.get()) {
				nextStep();
			} else {
				edi.methods.poa.selectPoA(
					certificateObj,
					true,
					function (poa: AnyObject) {
						if (poa?.docId) {
							me.poaHandler.set(poa.docId);
						}
						nextStep();
					},
					me.refuseCallback.bind(me)
				);
			}
		};

		const innUlFromCert = certificateObj.innle;
		const isUl = !!innUlFromCert;
		let isFL = !innUlFromCert && !certificateObj.ogrn && !certificateObj.ogrnip && certificateObj.snils;
		const innUlMatches = innUlFromCert && innUlFromCert === edi.core.getUserData().org.inn;

		//запустим выбор МЧД (если надо)
		if (!properties.disabledPoa) {
			//при подписании сертом ЮЛ проверяем совпадает ли ИНН в серте с ИНН текущей организации
			//если совпал, то подписываем без МЧД
			//если отличается то показываем окно "Использовать МЧД при подписании?" (ДА - окно выбора МЧД | НЕТ - подписываем без МЧД)
			if (edi.constants.ENABLE_POA_FOR_ALL_CERT || isFL || (isUl && !innUlMatches)) {
				if (isUl && !innUlMatches) {
					if (me.poaHandler.getPoaConfirmCheck?.()) {
						nextStep();
					} else {
						edi.core.confirm(
							null,
							me.args.properties?.usePoaConfirmationKey ?? me._usePoaConfirmationKey,
							selectPoaAndContinue,
							nextStep,
							() => me.poaHandler.setPoaConfirmCheck?.(true),
							me.refuseCallback.bind(me)
						);
					}
				} else {
					selectPoaAndContinue();
				}
			} else {
				nextStep();
			}
		} else {
			nextStep();
		}
	}

	/**
	 * Verifies certificate
	 */
	verifyCertificate() {
		const me = this;
		const nextStep = me.checkPoa.bind(me);
		//проверяет версию алгоритма генерации сертификата EDICORE-3803
		edi.sign.checkCertificate(nextStep, me.certificateHandler._selectedCertObj);
	}

	/**
	 * Checks if certificate has application to FNS if it is needed and allowed
	 */
	checkApplication(
		certificate?: CertificateHandler['_selectedCertificate'],
		certObj?: CertificateHandler['_selectedCertObj'],
		isLocalCert?: boolean,
		activePoa?: PoaHandler['_selectedPoa']
	) {
		const me = this;
		const properties = me.args.properties || {};
		const nextStep = me.verifyCertificate.bind(me);

		//если сертификат не пришел к нам снаружи, а был выбран в selectCert и мы можем запустить его проверку,
		//то сохраним серт в хэндлере и запустим проверку
		const needToCheckApplication =
			edi.constants.ENABLE_CERTIFICATE_APPLICATION_CHECKING &&
			typeof edi.methods.application_to_fns?.signApplicationAndCert === 'function' &&
			!me.certificateHandler.get();

		if (certificate || isLocalCert) {
			me.certificateHandler.set(certificate, certObj, isLocalCert);
		}
		if (activePoa) {
			me.poaHandler.set(activePoa);
		}

		if (needToCheckApplication) {
			let options = {} as AnyObject;
			if (properties.signUrl) {
				options.signUrl = properties.signUrl;
			}
			if (properties.loadFromUserProfile) {
				options.loadFromUserProfile = properties.loadFromUserProfile;
			}
			options.maskEl = me.args.maskElement;
			options.skipMaskHandling = me.args.skipMaskHandling;
			options.signRefuse = me.args.properties?.signRefuse;
			options.disabledPoa = me.args.properties?.disabledPoa;
			options.poaHandler = me.poaHandler;

			edi.methods.application_to_fns.signApplicationAndCert(
				me.certificateHandler,
				properties.isShowDuplicateModal,
				function (
					fail: boolean,
					data: AnyObject,
					selectedCert: CertificateHandler['_selectedCertificate'],
					selectedPoa: PoaHandler['_selectedPoa'],
					silent = false
				) {
					//перезапишем серт, т.к. проверка выставляет флаг готовности к работе readyToUse
					me.certificateHandler.set(selectedCert);
					// а перезапись мчд - это рудимент, возможно и стоит удалить, но я не уверен
					me.poaHandler.set(selectedPoa);

					if (!fail) {
						nextStep();
					} else {
						const isSilent = data?.typeError
							? edi.constants.SILENT_TYPE_ERRORS.includes(data.typeError)
							: silent;
						me.failure(data, isSilent);
					}
				},
				options
			);
		} else {
			nextStep();
		}
	}

	/**
	 * Shows modal to select certificate or use active cert for current docType
	 */
	selectCert(doctype?: string) {
		const me = this;
		const properties = me.args.properties || {};
		const nextStep = me.checkApplication.bind(me);

		if (properties.preselectedCert) {
			nextStep(properties.preselectedCert.cert, properties.preselectedCert.certObject);
			properties.preselectedCert = undefined;
		} else {
			const showActiveCertConfirm =
				properties.hasOwnProperty('showActiveCertConfirm') && properties.showActiveCertConfirm !== undefined
					? properties.showActiveCertConfirm
					: !edi.constants.CERTIFICATE.ONLY_ACTIVE;
			edi.sign.selectCertificate(
				nextStep,
				me.refuseCallback.bind(me),
				properties.doNotUseDefaultCert || false,
				doctype,
				showActiveCertConfirm
			);
		}
	}

	/**
	 * Checks if we pass all necessary params and has permissions to execute sign process
	 * @return	{boolean}	true=allow sign
	 */
	isSignAvailable() {
		const me = this;
		//readyToUse проставляется на сертификаты после проверки заявления в ФНС (если оно вообще вызывалось)
		if (me.certificateHandler.get()?.readyToUse === false && me.args.properties?.force !== true) {
			const dataError = {
				success: false,
				errorMessage: "Can't modify document. ",
				silent: true,
				additionalData: [],
				typeError: 'certificate.data.not.matched.with.organization',
				status: '108'
			};
			me.failure(dataError, false);
			return false;
		}

		if (!(me.args.document || me.args.properties?.signContent)) {
			me.failure();
			return false;
		}

		if (!edi.sign.isAvailable()) {
			edi.sign.displayNotAvailableMessage();
			me.maskElSetLoading(false);
			me.refuseCallback();
			return false;
		}

		return true;
	}

	/**
	 * Initiates signing process
	 */
	start() {
		const me = this;

		if (!me.isSignAvailable()) {
			return;
		}

		if (me.certificateHandler.get()) {
			me.checkApplication(me.certificateHandler.get());
		} else {
			const doc = me.args.document;
			if (doc && typeof doc === 'object') {
				if (doc.parentType) {
					me.selectCert(doc.parentType);
				} else if (doc.type) {
					edi.utils.getDocumentsParentType(doc, function (parentType: string) {
						me.selectCert(parentType);
					});
				}
			} else {
				me.selectCert();
			}
		}
	}
}
