const i18n = (key) => edi.i18n.getMessage(key);

const builder = {
	createSingleValueRow: ({
		title,
		name,
		customCondition,
		codeCls,
		customCodeValue,
		customCodeTag,
		valueCls,
		customValue,
		customValueTag
	}) => {
		const condition = customCondition ?? `!Ext.isEmpty($v.UI Platform Maven Webapp)`;
		const codeTag =
			customCodeTag ?? `<span class="code ${codeCls || ''}">${customCodeValue ?? i18n(title)}:</span>`;
		const valueTag =
			customValueTag ?? `<span class="row-data ${valueCls}">${customValue ?? '{' + name + '}'}</span>`;
		return [`<tpl if="${condition}">`, '<div class="edi-company-row">', codeTag, valueTag, '</div>', '</tpl>'];
		//если передадим конфиг
		//{title: 'company.gln.short', name: 'company_iln', valueCls: 'edi-company-gln'}
		//то получим такой вот шаблон
		//<tpl if="!Ext.isEmpty($v.company_iln)">
		//	<div class="edi-company-row">
		//		<span class="code">${i18n('company.gln.short')}:</span>
		//		<span class="row-data edi-company-gln"> {company_iln} </span>
		//	</div>
		//</tpl>
	},
	createCodeValue: ({
		title,
		name,
		customCondition,
		codeCls,
		customCodeValue,
		customCodeTag,
		valueCls,
		customValue,
		customValueTag
	}) => {
		const condition = customCondition ?? `!Ext.isEmpty($v.UI Platform Maven Webapp)`;
		const codeTag =
			customCodeTag ?? `<span class="code ${codeCls || ''}">${customCodeValue ?? i18n(title)}:</span>`;
		const valueTag =
			customValueTag ?? `<span class="row-data ${valueCls}">${customValue ?? '{' + name + '}'}</span>`;
		return [`<tpl if="${condition}">`, codeTag, valueTag, '</tpl>'];
	},
	createCodeValuesRow: (configs) => {
		const condition = configs
			.map(({ name, customCondition }) => customCondition ?? `!Ext.isEmpty($v.UI Platform Maven Webapp)`)
			.join(' || ');
		const items = configs.map((cfg) => builder.createCodeValue(cfg)).flat();
		return [`<tpl if="${condition}">`, '<div class="edi-company-row">', ...items, '</div>', '</tpl>'];
		//если передадим массив из 2 конфигов
		//[
		// {title: 'company.gln.short', name: 'company_iln', valueCls: 'edi-company-gln'},
		// {title: 'company.util.reg.nr', name: 'company_util_reg_nr', valueCls: 'edi-company-util-reg-nr'}
		//]
		//то получим такой вот шаблон
		//<tpl if="!Ext.isEmpty($v.company_iln || $v.company_util_reg_nr)">
		//	<div class="edi-company-row">
		//		<tpl if="!Ext.isEmpty($v.company_iln)">
		//			<span class="code">${i18n('company.gln.short')}:</span>
		//			<span class="row-data edi-company-gln"> {company_iln} </span>
		//		</tpl>
		//		<tpl if="!Ext.isEmpty($v.company_util_reg_nr)">
		//			<span class="code">${i18n('company.util.reg.nr')}:</span>
		//			<span class="row-data edi-company-util-reg-nr"> {company_util_reg_nr} </span>
		//		</tpl>
		//	</div>
		//</tpl>
	},
	getMainBlockTpl: function (selectorInstance) {
		const sections = builder.mainBlockSections;
		return [
			`<tpl if="$v.type === 'company'">`,
			...sections.legalEntity.getTpl(selectorInstance),
			`<tpl elseif="$v.type === 'individual'">`,
			...sections.individual.getTpl(selectorInstance),
			`<tpl elseif="$v.type === 'individualParticipant'">`,
			...sections.physical.getTpl(selectorInstance),
			`<tpl elseif="$v.type === 'legalForeigner'">`,
			...sections.legalForeigner.getTpl(selectorInstance),
			`<tpl elseif="$v.type === 'companyNotRegistered'">`,
			...sections.notRegistered.getTpl(selectorInstance),
			'</tpl>'
		];
	},
	mainBlockSections: {
		legalEntity: {
			getTpl: () => builder.mainBlockSections.legalEntity.companyName(),
			companyName: () => [
				'<tpl if="!Ext.isEmpty($v.company_name)">',
				'	<div class="edi-company-name">{company_name}</div>',
				'</tpl>'
			]
		},
		individual: {
			getTpl: () => [
				'<tpl if="!Ext.isEmpty($v.individual_company_name)">',
				...builder.mainBlockSections.individual.companyName(),
				'<tpl elseif="!Ext.isEmpty($v.individual_lastname || $v.individual_firstname || $v.individual_patronymicname)">',
				...builder.mainBlockSections.individual.firstLastPatronymicName(),
				'</tpl>',
				...builder.mainBlockSections.individual.inn(),
				...builder.mainBlockSections.individual.registration()
			],
			companyName: () => [
				'<div class="edi-company-name">',
				'	<span class="row-data edi-company-individual-company-name">{individual_company_name}</span>&nbsp;',
				'</div>'
			],
			firstLastPatronymicName: () => [
				'<div class="edi-company-name">',
				'	<span class="row-data edi-company-individual-lastname">{individual_lastname}</span>',
				'	<span class="row-data edi-company-individual-firstname"> {individual_firstname}</span>',
				'	<span class="row-data edi-company-individual-patronymicname"> {individual_patronymicname}</span>&nbsp;',
				'</div>'
			],
			inn: () =>
				builder.createCodeValuesRow([
					{
						title: 'company.inn.short',
						name: 'individual_inn',
						valueCls: 'edi-company-individual-inn'
					}
				]),
			registration: () =>
				builder.createCodeValuesRow([
					{
						title: 'individual.certificateRegistration',
						name: 'individual_certificateRegistrationIP',
						valueCls: 'edi-company-individual-registration'
					}
				])
		},
		physical: {
			getTpl: () => builder.mainBlockSections.physical.firstLastPatronymicName(),
			firstLastPatronymicName: () => [
				'<tpl if="!Ext.isEmpty($v.physical_lastname || $v.physical_firstname || $v.physical_patronymicname)">',
				'<div class="edi-company-name">',
				'	<span class="row-data edi-company-physical-lastname">{physical_lastname}</span>',
				'	<span class="row-data edi-company-physical-firstname"> {physical_firstname}</span>',
				'	<span class="row-data edi-company-physical-patronymicname"> {physical_patronymicname}</span>',
				'</div>',
				'</tpl>'
			]
		},
		legalForeigner: {
			getTpl: () => [
				...builder.mainBlockSections.legalForeigner.companyName(),
				...builder.mainBlockSections.legalForeigner.info(),
				...builder.mainBlockSections.legalForeigner.identifier()
			],
			companyName: () => [
				'<tpl if="!Ext.isEmpty($v.foreign_name)">',
				'<div class="edi-company-name">{foreign_name}</div>',
				'</tpl>'
			],
			info: () =>
				builder.createCodeValuesRow([
					{
						title: 'company.type.legalForeigner.header.info',
						name: 'foreign_info',
						valueCls: 'edi-company-address'
					}
				]),
			identifier: () =>
				builder.createCodeValuesRow([
					{
						title: 'column.indentifier',
						name: 'foreign_identifier',
						valueCls: 'edi-foreign-identifier'
					}
				])
		},
		notRegistered: {
			getTpl: () => [
				...builder.mainBlockSections.notRegistered.name(),
				...builder.mainBlockSections.notRegistered.info()
			],
			name: () => [
				'<tpl if="!Ext.isEmpty($v.notregistered_name)">',
				'<div class="edi-company-name">{notregistered_name}</div>',
				'</tpl>'
			],
			info: () =>
				builder.createCodeValuesRow([
					{
						title: 'company.type.legalForeigner.header.info',
						name: 'notregistered_info',
						valueCls: 'edi-company-address'
					}
				])
		}
	},
	getInnKppSubdivisionTpl: () =>
		builder.createCodeValuesRow([
			{ title: 'company.inn.short', name: 'company_inn', valueCls: 'edi-company-inn' },
			{ title: 'company.kpp.short', name: 'company_kpp', valueCls: 'edi-company-kpp' },
			{
				title: 'company.structuralSubdivision',
				name: 'structural_subdivision',
				valueCls: 'edi-company-structural-subdivision'
			}
		]),
	getGlnRegNumberTpl: () =>
		builder.createCodeValuesRow([
			{ title: 'company.gln.short', name: 'company_iln', valueCls: 'edi-company-gln' },
			{ title: 'company.util.reg.nr', name: 'company_util_reg_nr', valueCls: 'edi-company-util-reg-nr' }
		]),
	getOkpoTpl: () =>
		builder.createSingleValueRow({
			title: 'company.okpo.short',
			name: 'company_okpo',
			valueCls: 'edi-company-okpo'
		}),
	getSnilsTpl: () =>
		builder.createSingleValueRow({
			title: 'snils.code',
			name: 'person_snils',
			valueCls: 'edi-company-slils',
			customValue: '{[this.convertSnils(values.person_snils)]}'
		}),
	getAddressTpl: () => {
		const sections = builder.addressSections;
		return [
			'<tpl if="!Ext.isEmpty($v.address) && this.isAddressNotGar($v)">',
			...sections.addressString.getTpl(),
			'<tpl elseif="this.isAddressNotGar($v)">',
			'	<tpl if="!Ext.isEmpty($v.addr_for_text)">',
			`		${sections.foreignAddress.getTpl().join('\n')}`,
			'	<tpl else>',
			'		<tpl if="this.allowCommonAddressRenderer($v)">',
			`			${sections.commonAddress.getTpl().join('\n')}`,
			'		<tpl else>',
			`			${sections.legacyAddress.getTpl().join('\n')}`,
			`			${sections.fnsAddress.getTpl().join('\n')}`,
			'		</tpl>',
			'	</tpl>',
			'<tpl elseif="!Ext.isEmpty($v.addr_code_gar) && !this.isAddressNotGar($v)">',
			...sections.garAddress.getTpl(),
			'</tpl>'
		];
	},
	addressSections: {
		addressString: {
			getTpl: () =>
				builder.createSingleValueRow({
					customCodeValue: '{[this.renderAddressCategory(values)]}',
					name: 'address',
					valueCls: 'edi-company-address'
				})
		},
		foreignAddress: {
			getTpl: () => [
				'<div class="edi-company-row">',
				'	<tpl if="!Ext.isEmpty($v.addr_for_country_name)">',
				'		<span class="code">' + i18n('company.address') + ':</span>',
				'		<tpl if="!Ext.isEmpty($v.addr_for_country)">',
				'			{[this.convertData(["addr_for_country", "addr_for_country_name"], values)]}',
				'		</tpl>',
				'	</tpl>',
				'	<span class="row-data edi-company-addr-text"> {addr_for_text}</span>',
				'</div>'
			]
		},
		commonAddress: {
			getTpl: () =>
				builder.createSingleValueRow({
					customCondition: 'this.allowCommonAddressRenderer($v)',
					codeCls: 'edi-company-addr-header',
					customCodeValue: '{[this.renderAddressCategory(values)]}',
					customValueTag: '{[this.convertCommonAddress(values)]}'
				})
		},
		legacyAddress: {
			getTpl: () =>
				builder.createSingleValueRow({
					customCondition:
						'!Ext.isEmpty($v.addr_country || $v.addr_zip || $v.addr_area || $v.addr_block || $v.addr_city || $v.addr_street || $v.addr_home || $v.addr_flat)',
					codeCls: 'edi-company-addr-header',
					customCodeValue: '{[this.renderAddressCategory(values)]}',
					customValueTag: '{[this.convertLegacyAddress(values)]}'
				})
		},
		fnsAddress: {
			getTpl: () =>
				builder.createSingleValueRow({
					customCondition:
						'!Ext.isEmpty($v.addr_rus_zip || $v.addr_rus_region || $v.addr_rus_county || $v.addr_rus_block || $v.addr_rus_city || $v.addr_rus_street || $v.addr_rus_home || $v.addr_rus_flat)',
					codeCls: 'edi-company-addr-header',
					customCodeValue: '{[this.renderAddressCategory(values)]}',
					customValueTag: '{[this.convertRusAddress(values)]}'
				})
		},
		garAddress: {
			getTpl: () =>
				builder.createSingleValueRow({
					title: 'company.addr.code.gar',
					name: 'addr_code_gar',
					valueCls: 'edi-company-gar'
				})
		}
	},
	getBankTpl: () =>
		builder.createCodeValuesRow([
			{ title: 'company.bank.id', name: 'bank_id', valueCls: 'edi-company-bank-id' },
			{ title: 'company.bank.name', name: 'bank_name', valueCls: 'edi-company-bank-name' },
			{ title: 'company.bank.account', name: 'bank_acc', valueCls: 'edi-company-bank-acc' },
			{ title: 'company.bank.account.corr', name: 'bank_corr_acc', valueCls: 'edi-company-bank-cor-acc' }
		]),
	getOperatorTpl: () =>
		builder.createSingleValueRow({
			title: 'operator.name',
			customCondition: '!Ext.isEmpty($v.operator_name || $v.operator_phone || $v.operator_email)',
			customValueTag: '{[this.convertData(["operator_name", "operator_phone", "operator_email"], values)]}'
		}),
	getRepresentativeTpl: () =>
		builder.createSingleValueRow({
			title: 'representative.name',
			customCondition:
				'!Ext.isEmpty($v.representative_name || $v.representative_phone || $v.representative_email)',
			customValueTag:
				'{[this.convertData(["representative_name", "representative_phone", "representative_email"], values)]}'
		}),
	getContactTpl: () =>
		builder.createSingleValueRow({
			title: 'contact.name',
			customCondition:
				'!Ext.isEmpty($v.contact_name || $v.contact_phone || $v.contact_email || $v.contact_web || $v.contact_fax || $v.contact_person)',
			customValueTag:
				'{[this.convertData(["contact_name", "contact_phone", "contact_fax", "contact_person", "contact_email", "contact_web"], values)]}'
		}),
	getLicenseTpl: () => {
		const sections = builder.licenseBlockSections;
		return [
			'<tpl if="!Ext.isEmpty($v.license_type || $v.license_name || $v.license_series || $v.license_number)">',
			...builder.licenseBlockSections.typeName.getTpl(),
			...builder.licenseBlockSections.seriesNumber.getTpl(),
			...builder.licenseBlockSections.issuingAuthority.getTpl(),
			...builder.licenseBlockSections.dates.getTpl(),
			'</tpl>'
		];
	},
	licenseBlockSections: {
		typeName: {
			getTpl: () =>
				builder.createSingleValueRow({
					title: 'company.license',
					customCondition: '!Ext.isEmpty($v.license_type || $v.license_name)',
					customValueTag:
						'{[this.convertData(["license_type", "license_name", "representative_email"], values)]}'
				})
		},
		seriesNumber: {
			getTpl: () =>
				builder.createCodeValuesRow([
					{ title: 'company.license.series', name: 'license_series', valueCls: 'edi-company-licence-series' },
					{ title: 'company.license.number', name: 'license_number', valueCls: 'edi-company-licence-number' }
				])
		},
		issuingAuthority: {
			getTpl: () =>
				builder.createSingleValueRow({
					title: 'company.license.issuing.authority',
					name: 'license_issuing_authority',
					valueCls: 'edi-company-licence-authority'
				})
		},
		dates: {
			getTpl: () =>
				builder.createCodeValuesRow([
					{
						title: 'company.license.date.of.issue',
						name: 'license_date_of_issue',
						valueCls: 'edi-company-licence-date-of-issue'
					},
					{
						title: 'company.license.expiration.date',
						name: 'license_expiration_date',
						valueCls: 'edi-company-expiration-date'
					}
				])
		}
	},
	getTplMethods: () => ({
		isAddressNotGar: builder.tplMethods.isAddressNotGar,
		allowCommonAddressRenderer: builder.tplMethods.allowCommonAddressRenderer,
		renderAddressCategory: builder.tplMethods.renderAddressCategory,
		convertCommonAddress: builder.tplMethods.convertCommonAddress,
		convertLegacyAddress: builder.tplMethods.convertLegacyAddress,
		convertRusAddress: builder.tplMethods.convertRusAddress,
		convertData: builder.tplMethods.convertData,
		convertSnils: builder.tplMethods.convertSnils
	}),
	tplMethods: {
		isAddressNotGar: function (values) {
			return values.address_type !== '2';
		},
		allowCommonAddressRenderer: function (values) {
			return this.selectorInstance.disableCommonAddressRenderer !== true && !!values?.addr_category;
		},
		renderAddressCategory: function (values) {
			return this.selectorInstance.disableCommonAddressTitleRenderer === true || !values?.addr_category
				? i18n('company.address.header')
				: i18n('address.type.' + values.addr_category);
		},
		convertCommonAddress: function (values) {
			var addrProperties = [
				'addr_country_name',
				'addr_zip',
				'addr_region',
				'addr_area',
				'addr_city',
				'addr_locality',
				'addr_street',
				'addr_home',
				'addr_block',
				'addr_flat'
			];
			values['addr_region'] = values['addr_region'] ? `(${values['addr_region']})` : '';
			return this.convertData(addrProperties, values);
		},
		convertLegacyAddress: function (values) {
			var addrProperties = [
				'addr_country_name',
				'addr_zip',
				'addr_region',
				'addr_area',
				'addr_city',
				'addr_locality',
				'addr_street',
				'addr_home',
				'addr_block',
				'addr_flat'
			];
			values['addr_region'] = values['addr_region'] ? `(${values['addr_region']})` : '';
			return this.convertData(addrProperties, values);
		},
		convertRusAddress: function (values) {
			var addrProperties = [
				'addr_rus_zip',
				'addr_rus_region',
				'addr_region_name',
				'addr_rus_county',
				'addr_rus_city',
				'addr_rus_community',
				'addr_rus_street',
				'addr_rus_home',
				'addr_rus_block',
				'addr_rus_flat'
			];
			values['addr_rus_region'] = values['addr_rus_region'] ? `(${values['addr_rus_region']})` : '';
			const valuesWithCustomSeparator = [
				{
					siblingValues: ['addr_rus_region', 'addr_region_name'],
					separator: '<span class="row-data row-data-separator">&nbsp;</span>'
				}
			];
			return this.convertData(addrProperties, values, valuesWithCustomSeparator);
		},
		convertData: function (addrProperties, values, valuesWithCustomSeparator) {
			return addrProperties
				.filter(function (prop) {
					return values.hasOwnProperty(prop) && values[prop].length;
				})
				.reduce(function (acc, prop, i, arr) {
					const getSeparator = () => {
						const isLastValue = i === arr.length - 1;
						if (isLastValue) {
							return '';
						}
						//Значения между которыми используется свой собственный разделитель
						if (Array.isArray(valuesWithCustomSeparator) && valuesWithCustomSeparator.length) {
							const valueWithCustomSeparator = valuesWithCustomSeparator.find(({ siblingValues }) => {
								const [firstValue, secondValue] = siblingValues || [];
								return prop === firstValue && arr[i + 1] === secondValue && values[secondValue];
							});

							if (valueWithCustomSeparator && valueWithCustomSeparator.separator) {
								return valueWithCustomSeparator.separator;
							}
						}

						return '<span class="row-data row-data-separator">,&nbsp;</span>';
					};
					acc +=
						`<span class='row-data inline-row-data edi-${prop.replace(`_', '-`)}'>` +
						values[prop] +
						'</span>' +
						getSeparator();
					return acc;
				}, '');
		},
		convertSnils: function (snilsData) {
			return edi.converters.SNILS.convertForShow(snilsData);
		}
	}
};

const getOrgSelectorTpl = (selectorInstance) => {
	const templateItems = [
		...builder.getMainBlockTpl(selectorInstance),
		...builder.getInnKppSubdivisionTpl(selectorInstance),
		...builder.getGlnRegNumberTpl(selectorInstance),
		...builder.getOkpoTpl(selectorInstance),
		...builder.getSnilsTpl(selectorInstance),
		...builder.getAddressTpl(),
		...builder.getBankTpl(selectorInstance),
		...builder.getOperatorTpl(selectorInstance),
		...builder.getRepresentativeTpl(selectorInstance),
		...builder.getContactTpl(selectorInstance),
		...builder.getLicenseTpl(selectorInstance)
	];
	const template = new Ext.XTemplate(...templateItems, builder.getTplMethods(selectorInstance));
	template.selectorInstance = selectorInstance;

	return template;
};

export { builder, getOrgSelectorTpl };
