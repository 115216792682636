import { createGrid } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';

Ext.ariaWarn = Ext.emptyFn;

Ext.override(Ext.data.field.Field, {
	allowNull: true
});

/**
 * Global override that allows to intercept all server calls, and prolong business session, if timeout between requests were too long. Also it intercept all requests that requires login, and forces login form popup
 */
Ext.override(Ext.Ajax, {
	interceptedChain: [],
	interceptionActive: false,
	interceptionFlushActive: false,
	loginInProcess: false,
	requestsCount: 0,
	pendingChain: [],
	pendingFlushActive: false,
	/**
	 * Returns current state of pending requests flush state
	 * @returns {boolean}
	 */
	getPendingFlushState: function () {
		return this.pendingFlushActive;
	},
	/**
	 * Clears pending chain collection
	 */
	clearPendingChain: function () {
		this.pendingChain = [];
	},
	/**
	 * Flushes pending chain collection by firing all requests
	 */
	flushPendingChain: function () {
		if (this.pendingChain && this.pendingChain.length) {
			this.pendingFlushActive = true;
			while (this.pendingChain.length) {
				this.request(this.pendingChain.shift());
			}
			edi.core.logMessage('Pending requests chain was flushed to server');
			this.pendingFlushActive = false;
		}
	},
	/**
	 * Checks if request is pending from user activity(any other independent requests)
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isRequestPending: function (options) {
		return !!options.isPending;
	},
	/**
	 * Checks if request is not interceptable
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isRequestNotInterceptable: function (options) {
		return !!options.notInterceptable;
	},
	/**
	 * Checks if request is from login form
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isLoginRequest: function (options) {
		return (
			options.url === edi.rest.services.USER.LOGIN.POST ||
			options.url === edi.rest.services.USER.CHECK_AUTH_TOKEN.POST
		);
	},
	/**
	 * Checks if request is get user data
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isGetUserRequest: function (options) {
		return options.url === edi.rest.services.USER.SELF.GET;
	},
	/**
	 * Checks if request is check_auth_token
	 * @param    {Object}    options        request parameters object
	 * @returns  {boolean}
	 */
	isCheckAuthTokenRequest: function (options) {
		return options.url === edi.rest.services.USER.CHECK_AUTH_TOKEN.POST;
	},
	/**
	 * Adds intercepted pending request parameters into chain
	 * @param    {Object}    options        request parameters object
	 */
	interceptPendingRequest: function (options) {
		this.pendingChain.push(options);
		edi.core.logMessage('Intercepted pending request - ' + options.url);
	},
	/**
	 * Returns current state of interceptor
	 * @returns {boolean}
	 */
	getInterceptionState: function () {
		return this.interceptionActive;
	},
	/**
	 * Sets state of interceptor and flushes chain if we set state to false
	 * @param    {Boolean}    state           true if active
	 * @param    {Boolean}    preventFlush    true to prevent flush
	 */
	setInterceptionState: function (state, preventFlush) {
		if (this.interceptionActive !== state) {
			this.interceptionActive = state;
			if (!this.interceptionActive) {
				if (!preventFlush) {
					this.flushInterceptedChain();
				} else {
					this.clearInterceptedChain();
					this.clearPendingChain();
				}
			}
		}
	},
	/**
	 * Adds intercepted request parameters into chain
	 * @param    {Object}    options        request parameters object
	 */
	interceptRequest: function (options) {
		this.interceptedChain.push(options);
		edi.core.logMessage('Intercepted request - ' + options.url);
	},
	/**
	 * Clears intercepted chain collection
	 */
	clearInterceptedChain: function () {
		this.interceptedChain = [];
	},
	/**
	 * Flushes intercepted chain collection by firing all requests
	 */
	flushInterceptedChain: function () {
		if (this.interceptedChain && this.interceptedChain.length) {
			this.interceptionFlushActive = true;
			while (this.interceptedChain.length) {
				this.request(this.interceptedChain.shift());
			}
			this.interceptionFlushActive = false;
			edi.core.logMessage('Intercepted chain was flushed to server');
		}
	},
	/**
	 * Aborts intercepted request by removing it from chain
	 * @param    {String}    url     request url
	 * @param    {Object}    args    request parameters to compare
	 * @returns  {boolean}
	 */
	abortInterceptedRequest: function (url, args) {
		var aborted = false,
			i,
			requestsEqual,
			requestOptions;
		if (this.interceptedChain && this.interceptedChain.length) {
			for (i = 0; i < this.interceptedChain.length; i++) {
				requestOptions = this.interceptedChain[i];
				if (url === requestOptions.url) {
					requestsEqual = true;
					if (!edi.utils.isEmptyObject(args)) {
						if (requestOptions.proxy && requestOptions.proxy.extraParams) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.proxy.extraParams);
						} else if (requestOptions.params) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.params);
						} else if (requestOptions.operation && requestOptions.operation.params) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.operation.params);
						}
					}
					if (requestsEqual) {
						this.interceptedChain.splice(i, 1);
						edi.core.logMessage('Aborted intercepted request - ' + url);
						aborted = true;
						break;
					}
				}
			}
		}
		if (!aborted && this.pendingChain && this.pendingChain.length) {
			for (i = 0; i < this.pendingChain.length; i++) {
				requestOptions = this.pendingChain[i];
				if (url === requestOptions.url) {
					requestsEqual = true;
					if (!edi.utils.isEmptyObject(args)) {
						if (requestOptions.proxy && requestOptions.proxy.extraParams) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.proxy.extraParams);
						} else if (requestOptions.params) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.params);
						} else if (requestOptions.operation && requestOptions.operation.params) {
							requestsEqual = edi.utils.compareObjects(args, requestOptions.operation.params);
						}
					}
					if (requestsEqual) {
						this.pendingChain.splice(i, 1);
						edi.core.logMessage('Aborted intercepted pending request - ' + url);
						aborted = true;
						break;
					}
				}
			}
		}
		return aborted;
	},
	/**
	 * Updates requests counter by adding delta. By default delta is 1
	 * @param delta
	 */
	updateRequestsCounter: function (delta) {
		delta = parseInt(delta, 10);
		delta = !delta ? 1 : delta;
		this.requestsCount += delta;
		var countData = {};
		countData[edi.constants.REQUESTS_COUNTER_ATTR] = this.requestsCount;
		Ext.getBody().set(countData);
	},
	/**
	 * Start Login
	 */
	startLogin: function () {
		var __self = this;

		this.abortAll();
		edi.core.startLogin(function () {
			__self.loginInProcess = false;
			edi.login.getCurrentOrganization(function (failed) {
				__self.setInterceptionState(false, failed);
			});
		});
	}
});

Ext.override(Ext.data.Connection, {
	config: {
		timeout: Ext.Ajax.timeout
	},
	/**
	 * @overridden
	 * Overrides default method to allow interception of requests
	 * @param    {Object}    options        request parameters object
	 */
	request: function (options) {
		if (
			Ext.Ajax.isRequestPending(options) &&
			!Ext.Ajax.getPendingFlushState() &&
			!Ext.Ajax.interceptionFlushActive
		) {
			Ext.Ajax.interceptPendingRequest(options);
		} else {
			var requestTime = new Date().getTime(),
				userData = edi.core.getUserData(),
				interceptable = !Ext.Ajax.isRequestNotInterceptable(options),
				isLogin = Ext.Ajax.isLoginRequest(options);
			var interseptingTimeLeft =
				this.getLastRequestTime() &&
				userData &&
				this.getLastRequestTime() + edi.constants.ORG_SELECTION_CHECK_TIMEOUT < requestTime;

			if (!isLogin && interseptingTimeLeft) {
				this.setLastRequestTime(requestTime);
				Ext.Ajax.setInterceptionState(true);
				Ext.Ajax.interceptRequest(options);
				edi.login.getCurrentOrganization(function () {
					Ext.Ajax.setInterceptionState(false);
				});
			} else if (interceptable && Ext.Ajax.getInterceptionState()) {
				Ext.Ajax.interceptRequest(options);
			} else {
				if (!Ext.Ajax.isRequestNotInterceptable(options) && Ext.Ajax.getInterceptionState()) {
					Ext.Ajax.interceptRequest(options);
				} else {
					this.setLastRequestTime(requestTime);
					Ext.Ajax.updateRequestsCounter();
					this.callParent([options]);
				}

				if (!Ext.Ajax.getPendingFlushState()) {
					//We flush pending requests chain on any independent request
					Ext.Ajax.flushPendingChain();
				}
			}
		}
	},
	/**
	 * Sets time for last request that was sent to backend
	 * @param    {Number}    time    time of request in ms
	 */
	setLastRequestTime: function (time) {
		this.lastRequestTime = time || 0;
	},
	/**
	 * Sets time for last request that was sent to backend
	 * @returns	{Number}	time of request in ms
	 */
	getLastRequestTime: function () {
		return this.lastRequestTime || 0;
	}
});

Ext.override(Ext.data.request.Ajax, {
	/**
	 * @overridden
	 * To be called when the request has come back from the server
	 * @private
	 * @param {Object} xdrResult
	 * @return {Object} The response
	 */
	onComplete: function (xdrResult) {
		let request = this;
		if (Ext.Ajax.loginInProcess && request.aborted) {
			Ext.Ajax.interceptRequest(request.options);
		} else {
			//We must skip login for ACTIVATION type of authorization
			let loginNeeded =
				edi.login.getAuthType() !== 'ACTIVATION' &&
				request.xhr &&
				edi.constants.STATUS.NOT_AUTHORISED === String(request.xhr.status);
			if (loginNeeded && !Ext.Ajax.isLoginRequest(request.options)) {
				Ext.Ajax.setInterceptionState(true);
				Ext.Ajax.interceptRequest(request.options);
				if (!Ext.Ajax.loginInProcess) {
					Ext.Ajax.loginInProcess = true;
					if (
						Ext.Ajax.isGetUserRequest(request.options) ||
						Ext.Ajax.isCheckAuthTokenRequest(request.options)
					) {
						return this.callParent(request, xdrResult);
					} else {
						Ext.Ajax.startLogin();
					}
				}
			} else {
				return this.callParent(request, xdrResult);
			}
		}
	},
	/**
	 * Updating requests counter in case of request finished or aborted
	 * @private
	 */
	cleanup: function () {
		this.callParent();
		Ext.Ajax.updateRequestsCounter(-1);
	}
});

Ext.override(Ext.data.request.Form, {
	onComplete: function () {
		this.callParent();
		Ext.Ajax.updateRequestsCounter(-1);
	}
});

/**
 * Improved reload store method that not loads data if there are no url in ajax proxy
 */
Ext.override(Ext.data.Store, {
	reload: function (options) {
		if (this.proxy && this.proxy.type === 'ajax' && !this.proxy.url) {
			return this;
		} else {
			return this.load(this.lastOptions ? Object.assign(this.lastOptions, options) : undefined);
		}
	}
});

Ext.override(Ext.data.proxy.Server, {
	config: {
		timeout: Ext.Ajax.timeout
	},
	buildRequest: function (operation) {
		const me = this;
		const request = me.callParent([operation]);
		//сетим дополнительные параметры в jsonData Ext.data.Request из proxy
		//нужно для дальнейшего мерджа данных jsonData с params в doRequest (Ext.data.proxy.Ajax)
		if (me.extraJsonData) {
			request.setJsonData(me.extraJsonData);
		}
		return request;
	}
});
/**
 * Paging Memory Proxy, allows to use paging grid with in memory dataset
 */
Ext.define('Ext.ux.data.PagingMemoryProxy', {
	extend: 'Ext.data.proxy.Memory',
	alias: 'proxy.pagingmemory',
	alternateClassName: 'Ext.data.PagingMemoryProxy',
	enablePaging: true
});

/**
 * Improvement for viewport
 */
Ext.override(Ext.container.Viewport, {
	setSize: function (width, height) {
		if (this.rendered) {
			var el = this.el,
				overflowStyleX = '',
				overflowStyleY = '';
			if (width < this.minWidth) {
				overflowStyleX = 'auto';
			}
			if (height < this.minHeight) {
				overflowStyleY = 'auto';
			}
			el.setStyle('overflow-x', overflowStyleX);
			el.setStyle('overflow-y', overflowStyleY);
		}
		this.callOverridden(arguments);
	}
});

Ext.override(Ext.window.Window, {
	closeToolText: '',
	onShow: function () {
		var me = this;
		//сбрасываем focus при открытие любого Window,
		//иначе, после закрытия Window, focus "прыгнет" на последний выбранный элемент
		Ext.get(Ext.Element.getActiveElement())?.blur(); //<-- ADDED BY OVERRIDE

		me.callParent(arguments);
		if (me.expandOnShow) {
			me.expand(false);
		}
		me.syncMonitorWindowResize();
		if (me.rendered && me.tabGuard) {
			me.initTabGuards();
		}

		if (edi.navigation) {
			edi.navigation.observer.fireEvent('hidefloatingmenu'); //<-- ADDED BY OVERRIDE
		}
	}
});

/**
 * Simple canvasJs wrapper for rendering charts, use elements chart property to get access for
 */
Ext.define('Ext.chart.CanvasJsChart', {
	extend: 'Ext.panel.Panel',
	alias: 'widget.CanvasJsChart',
	alternateClassName: ['Ext.chart.CnvCrt', 'Ext.CanvasJsChart', 'Ext.CnvCrt'],
	chartConfig: null,
	chart: null,
	chartCfg: null,
	seriesMap: null,
	defaultChartConfig: {
		culture: 'edi',
		legend: {
			cursor: 'pointer',
			itemclick: function (e) {
				e.dataSeries.visible = !(typeof e.dataSeries.visible === 'undefined' || e.dataSeries.visible);
				e.chart.render();
			},
			fontSize: 15
		}
	},
	initComponent: function () {
		var me = this;
		me.addEdiCulture();
		me.setDefaultChartConfig();
		me.setSeriesMap();
		me.callParent();
		me.on('afterrender', me.afterCompRender);
		me.on('afterlayout', me.afterCompLayout);
	},
	addEdiCulture: function () {
		if (!CanvasJS.ediCultureAdded) {
			CanvasJS.addCultureInfo('edi', {
				decimalSeparator: ',',
				digitGroupSeparator: ' ',
				days: [
					edi.i18n.getMessage('day.sunday'),
					edi.i18n.getMessage('day.monday'),
					edi.i18n.getMessage('day.tuesday'),
					edi.i18n.getMessage('day.wednesday'),
					edi.i18n.getMessage('day.thursday'),
					edi.i18n.getMessage('day.friday'),
					edi.i18n.getMessage('day.saturday')
				],
				shortDays: [
					edi.i18n.getMessage('day.sunday.short'),
					edi.i18n.getMessage('day.monday.short'),
					edi.i18n.getMessage('day.tuesday.short'),
					edi.i18n.getMessage('day.wednesday.short'),
					edi.i18n.getMessage('day.thursday.short'),
					edi.i18n.getMessage('day.friday.short'),
					edi.i18n.getMessage('day.saturday.short')
				],
				zoomText: edi.i18n.getMessage('canvasjs.zoom.text'),
				panText: edi.i18n.getMessage('canvasjs.pan.text'),
				resetText: edi.i18n.getMessage('canvasjs.reset.text'),
				printText: edi.i18n.getMessage('canvasjs.print.text'),
				savePNGText: edi.i18n.getMessage('canvasjs.save.png.text'),
				saveJPGText: edi.i18n.getMessage('canvasjs.save.jpg.text'),
				menuText: edi.i18n.getMessage('canvasjs.menu.text'),
				months: [
					edi.i18n.getMessage('month.january'),
					edi.i18n.getMessage('month.february'),
					edi.i18n.getMessage('month.march'),
					edi.i18n.getMessage('month.april'),
					edi.i18n.getMessage('month.may'),
					edi.i18n.getMessage('month.june'),
					edi.i18n.getMessage('month.july'),
					edi.i18n.getMessage('month.august'),
					edi.i18n.getMessage('month.september'),
					edi.i18n.getMessage('month.october'),
					edi.i18n.getMessage('month.november'),
					edi.i18n.getMessage('month.december')
				],
				shortMonths: [
					edi.i18n.getMessage('month.january.short'),
					edi.i18n.getMessage('month.february.short'),
					edi.i18n.getMessage('month.march.short'),
					edi.i18n.getMessage('month.april.short'),
					edi.i18n.getMessage('month.may.short'),
					edi.i18n.getMessage('month.june.short'),
					edi.i18n.getMessage('month.july.short'),
					edi.i18n.getMessage('month.august.short'),
					edi.i18n.getMessage('month.september.short'),
					edi.i18n.getMessage('month.october.short'),
					edi.i18n.getMessage('month.november.short'),
					edi.i18n.getMessage('month.december.short')
				]
			});
			CanvasJS.ediCultureAdded = true;
		}
	},
	/**
	 * Apply default config properties
	 */
	setDefaultChartConfig: function () {
		var me = this;
		me.chartCfg = Object.assign({}, me.defaultChartConfig, me.chartConfig);
	},
	/**
	 * Defines series map based on seriesId defined in data objects
	 */
	setSeriesMap: function () {
		var me = this,
			chartData = me.chartCfg.data,
			i;
		if (me.chart) {
			chartData = me.chart.options.data;
		}
		me.seriesMap = {};
		if (chartData.length) {
			for (i = 0; i < chartData.length; i++) {
				if (chartData[i].seriesId) {
					me.seriesMap[chartData[i].seriesId] = chartData[i];
				}
			}
		}
	},
	/**
	 * Event listener that called after panel is rendered to render chart
	 */
	afterCompRender: function () {
		var me = this;
		me.chart = new CanvasJS.Chart(me.body.dom, me.chartCfg);
		me.setSeriesMap();
		if (me.chartCfg.disableZoomResetBtns) {
			me.on(
				'afterlayout',
				function () {
					var me = this;
					var buttonsContainer = Ext.query('div.canvasjs-chart-toolbar', me.body.dom);
					if (buttonsContainer) {
						buttonsContainer[0].children[1].className = 'buttonHidden';
						buttonsContainer[0].children[0].className = 'buttonHidden';
					}
				},
				undefined,
				{
					single: true
				}
			);
		}
	},
	/**
	 * Rerender chart after layout is finished by container
	 */
	afterCompLayout: function () {
		var me = this;
		me.chart.render();
	},
	/**
	 * Routine that should be done before component destroy
	 */
	beforeDestroy: function () {
		var me = this;
		if (me.chart) {
			me.chart.destroy();
			me.chart = null;
		}
		me.callParent();
	},
	/**
	 * returns chart object
	 */
	getChart: function () {
		return this.chart;
	},
	/**
	 * Resets chart to initial state from zooming/panning and rerender it
	 * @param    {Boolean}      skipRendering    true skip rendering of chart
	 */
	resetChart: function (skipRendering) {
		var me = this,
			chart = me.getChart();
		if (chart) {
			chart.options.axisX.viewportMinimum = chart.options.axisX.viewportMaximum = null;
			chart.options.axisY.viewportMinimum = chart.options.axisY.viewportMaximum = null;
		}
		if (chart && !skipRendering && me.rendered && !me.destroying && !me.isDestroyed) {
			chart.render();
		}
	},
	/**
	 * Sets datapoints collections passed from data in the order of they were declared in configuration.
	 * @param    {Array}      data      collection of dataPoints collections
	 * @param    {Boolean}    append    true to append values to the end of series set
	 */
	setRawDataPoints: function (data, append) {
		var me = this,
			chart = me.getChart(),
			chartData = me.chartCfg.data,
			i;
		if (chart) {
			chartData = chart.options.data;
		}
		if (chartData && chartData.length) {
			for (i = 0; i < chartData.length; i++) {
				if (append) {
					if (Array.isArray(data[i])) {
						chartData[i].dataPoints = chartData[i].dataPoints.concat(data[i]);
					}
				} else {
					chartData[i].dataPoints = Array.isArray(data[i]) ? data[i] : [];
				}
			}
		}
		if (chart && me.rendered && !me.destroying && !me.isDestroyed) {
			chart.render();
		}
	},
	/**
	 * Object containing properties named according chart seriesIds containing collections of data points
	 * @param    {Object}     data      object with datapoints in properties named as series id
	 * @param    {Boolean}    append    true to append values to the end of series set
	 */
	setDataPoints: function (data, append) {
		var me = this,
			chart = me.getChart(),
			i;
		if (data) {
			for (i in data) {
				if (data.hasOwnProperty(i) && me.seriesMap[i]) {
					if (append) {
						if (Array.isArray(data[i])) {
							me.seriesMap[i].dataPoints = me.seriesMap[i].dataPoints.concat(data[i]);
						}
					} else {
						me.seriesMap[i].dataPoints = Array.isArray(data[i]) ? data[i] : [];
					}
				}
			}
		}
		if (chart && me.rendered && !me.destroying && !me.isDestroyed) {
			chart.render();
		}
	}
});

/**
 * Month field control
 */
Ext.define('Ext.form.field.Month', {
	extend: 'UI.components.DateField',
	alias: 'widget.monthfield',
	alternateClassName: ['Ext.form.MonthField', 'Ext.form.Month'],
	selectMonth: null,
	createPicker: function () {
		var me = this,
			format = Ext.String.format;
		return Ext.create('UI.picker.Month', {
			pickerField: me,
			ownerCt: me.ownerCt,
			renderTo: document.body,
			floating: true,
			hidden: true,
			focusOnShow: true,
			cls: 'edi-month-picker',
			minDate: me.minValue,
			maxDate: me.maxValue,
			disabledDatesRE: me.disabledDatesRE,
			disabledDatesText: me.disabledDatesText,
			disabledDays: me.disabledDays,
			disabledDaysText: me.disabledDaysText,
			format: me.format,
			showToday: me.showToday,
			startDay: me.startDay,
			minText: format(me.minText, me.formatDate(me.minValue)),
			maxText: format(me.maxText, me.formatDate(me.maxValue)),
			listeners: {
				select: {
					scope: me,
					fn: me.onSelect
				},
				monthdblclick: {
					scope: me,
					fn: me.onOKClick
				},
				yeardblclick: {
					scope: me,
					fn: me.onOKClick
				},
				OkClick: {
					scope: me,
					fn: me.onOKClick
				},
				CancelClick: {
					scope: me,
					fn: me.onCancelClick
				}
			},
			keyNavConfig: {
				esc: function () {
					me.collapse();
				}
			}
		});
	},
	onCancelClick: function () {
		var me = this;
		me.selectMonth = null;
		me.collapse();
	},
	onOKClick: function () {
		var me = this;
		if (me.selectMonth) {
			me.setValue(me.selectMonth);
			me.fireEvent('select', me, me.selectMonth);
		} else {
			me.setValue(new Date());
		}
		me.collapse();
	},
	onSelect: function (m, d) {
		var me = this;
		me.selectMonth = new Date(d[0] + 1 + '/1/' + d[1]);
	}
});

Ext.override(Ext.Component, {
	constructor: function () {
		const me = this;
		let cfg = null;
		me.callParent(arguments);

		const fieldsRenderMethods = function (cmpConfig) {
			if (cmpConfig.name) {
				return cmpConfig.name;
			}
			return null;
		};
		switch (Ext.getClassName(me)) {
			case 'Ext.form.field.Text':
			case 'UI.components.TextField':
				cfg = {
					name: 'textField',
					renderMethod: fieldsRenderMethods
				};
				break;
			case 'Ext.form.field.ComboBox':
			case 'UI.components.ComboboxField':
				cfg = {
					name: 'comboBox',
					renderMethod: fieldsRenderMethods
				};
				break;
			case 'Ext.form.field.Checkbox':
			case 'UI.components.Checkbox':
				cfg = {
					name: 'checkbox',
					renderMethod: fieldsRenderMethods
				};
				break;
			case 'Ext.form.field.Date':
			case 'UI.components.DateField':
				cfg = {
					name: 'dateField',
					renderMethod: fieldsRenderMethods
				};
				break;
			case 'Ext.form.field.TextArea':
			case 'UI.components.TextAreaField':
				cfg = {
					name: 'areaField',
					renderMethod: fieldsRenderMethods
				};
				break;
			case 'Ext.tab.Tab':
			case 'UI.components.Tab':
				cfg = {
					name: 'tab',
					renderMethod: function (cmpConfig) {
						if (
							cmpConfig.hasOwnProperty('card') &&
							cmpConfig.card &&
							cmpConfig.card.hasOwnProperty('tabName')
						) {
							return cmpConfig.card.tabName;
						}
						return null;
					}
				};
				break;
			case 'Ext.button.Button':
			case 'UI.button.Button':
				cfg = {
					name: 'button',
					renderMethod: function (cmpConfig, cmp) {
						var iconKey;
						if (cmpConfig.glyph) {
							iconKey = Ext.Object.getKey(edi.constants.ICONS, cmpConfig.glyph);
						}
						if (!iconKey && cmp.cssTestSuffix) {
							iconKey = cmp.cssTestSuffix;
						}
						return iconKey ? iconKey.toLowerCase() : null;
					}
				};
				break;
			default:
				break;
		}

		let cls;
		if (cfg) {
			cls = 'test-' + cfg.name;
			if ('function' === typeof cfg.renderMethod) {
				let classSuffix = cfg.renderMethod(arguments[0], me);
				cls += '-' + (classSuffix ? classSuffix : me.id);
			}
		}
		if (cls) {
			me.addCls(cls);
		}
	},
	setLoading: function (load, targetEl) {
		//т.к. в uikit 2.0.15 изменился апи маски (теперь текст передается в title,а не msg),
		//то сделаем небольшой хак, что бы не переписывать все вызовы setLoading('text')
		const me = this;
		if (Ext.isString(load)) {
			return me.callParent([{ title: load }, targetEl]);
		}
		return me.callParent([load, targetEl]);
	},
	showModalNotice: function (msg, delay) {
		//TODO это очень сомнительный функционал, используется буквально пару раз в проекте
		// желательно пристрелить это
		// ради упрощения заменено создание окна на обычную маску
		const me = this;
		if (me.rendered && msg && 'string' == typeof msg && typeof me.setLoading === 'function') {
			me.setLoading({
				title: msg
			});
			setTimeout(function () {
				me.setLoading(false);
			}, delay || 1000);
		}
	}
});

/******* fix tooltip width issue with safari 7 Mac for extjs4.2.2 *******/
if (Ext.isSafari && Ext.safariVersion >= 7) {
	// Override button to fix tooltip issue on Safari
	delete Ext.tip.Tip.prototype.minWidth;
}

Ext.override(Ext.form.Basic, {
	timeout: Ext.Ajax.timeout / 1000
});

Ext.define('Ext.ux.grid.RowSubGrid', {
	extend: 'Ext.grid.plugin.RowExpander',
	alias: 'plugin.rowsubgrid',

	rowBodyTpl: [
		'{%this.renderComponent(values);%}',
		'<div id="{[this.subGrid.grid.id]}-component-subgrid-{[values[this.subGrid.rowId]]}"></div>',
		{
			renderComponent: function (rowValues) {
				var me = this.subGrid,
					grid = me.grid,
					store = grid.getStore(),
					recordId = me.rowId ? rowValues[me.rowId] : rowValues.id,
					record = store.findRecord(me.rowId ? me.rowId : 'id', recordId, 0, false, true, true),
					componentWrapId = grid.id + '-component-subgrid-' + recordId;
				var recordData = record.getData();
				var subRecordData = edi.utils.getObjectProperty(recordData, me.parentPropName);

				if (me.components[recordId]) {
					me.components[recordId].destroy();
					delete me.components[recordId];
				}
				if (
					!me.components[recordId] &&
					Array.isArray(subRecordData) &&
					subRecordData.length &&
					me.columns &&
					me.columns.length &&
					me.model
				) {
					me.components[recordId] = createGrid({
						enableTextSelection: !!me.enableTextSelection,
						gridConfig: {
							columns: me.columns,
							disableSelection: true,
							disablePaging: true,
							width: me.width ? me.width : undefined
						},
						storeConfig: {
							proxy: createProxyConfig({
								type: 'memory',
								data: subRecordData
							}),
							model: me.model
						}
					});
					me.components[recordId].componentId = componentWrapId;
				}
			}
		}
	],
	init: function (grid) {
		var me = this,
			store = grid.getStore(),
			view = grid.getView(),
			recordIdName = me.rowId ? me.rowId : 'id';

		me.components = {};
		me.callParent(arguments);

		this.grid = grid;
		this.rowBodyTpl.subGrid = this;

		grid.on('beforedestroy', me.destroyComponents, me);
		store.on('beforeload', me.destroyComponents, me);
		store.on('filterchange', me.onParentFilterChange, me);

		view.on('expandbody', me.onExpandBody, me);

		var rerenderSubGrid = function (componentId, internalId) {
			if (me.recordsExpanded[internalId] && me.components[componentId] && !me.components[componentId].rendered) {
				me.components[componentId].on(
					'afterrender',
					function (component) {
						this.grid.layout.redoLayout();
						component.layout.redoLayout();
					},
					me,
					{
						delay: 10,
						single: true
					}
				);
				me.components[componentId].render(me.components[componentId].componentId);
			}
		};
		view.on('itemupdate', function (record) {
			rerenderSubGrid(record.get(recordIdName), record.internalId);
		});
		view.on('refresh', function () {
			var internalId, rec;
			for (internalId in me.recordsExpanded) {
				if (me.recordsExpanded.hasOwnProperty(internalId)) {
					rec = store.data.get(internalId);
					if (rec) {
						rerenderSubGrid(rec.get(recordIdName), internalId);
					}
				}
			}
		});
		// modify getRefItems method of grid to allow querying components from rowbody
		grid.getRefItems = (function () {
			var originalFn = grid.getRefItems;
			return function (deep) {
				var result = originalFn.call(grid, deep);

				if (deep) {
					for (var i in me.components) {
						if (me.components.hasOwnProperty(i)) {
							result.push(me.components[i]);
							result.push.apply(result, me.components[i].getRefItems(true));
						}
					}
				}
				return result;
			};
		})();
	},

	destroyComponents: function () {
		var me = this,
			components = me.components;

		for (var i in components) {
			if (components.hasOwnProperty(i)) {
				components[i].destroy();
			}
		}
		me.components = {};
	},

	onExpandBody: function (rowNode, record) {
		var me = this,
			grid = me.grid,
			recordId = me.rowId ? record.get(me.rowId) : record.getId(),
			componentWrapId = grid.id + '-component-subgrid-' + recordId,
			component = me.components[recordId];

		if (component && !component.rendered) {
			component.on(
				'afterrender',
				function () {
					grid.layout.redoLayout();
					component.layout.redoLayout();
				},
				me,
				{
					delay: 10,
					single: true
				}
			);
			component.render(componentWrapId);
		}
	},

	onParentFilterChange: function (store, filters) {
		var me = this,
			i,
			applicableFilters = [],
			compStore;
		for (i = 0; i < filters.length; i++) {
			if (filters[i].subGrid) {
				applicableFilters.push(filters[i].subGrid);
			}
		}
		for (i in me.components) {
			if (me.components.hasOwnProperty(i)) {
				compStore = me.components[i].getStore();
				if (applicableFilters.length) {
					compStore.clearFilter(true);
					compStore.filter(applicableFilters);
				} else {
					compStore.clearFilter();
				}
			}
		}
	}
});

Ext.override(Ext.picker.Month, {
	initComponent: function () {
		var me = this;
		me.okText = Ext.htmlDecode(me.okText);

		me.callParent(arguments);
	}
});
