import {
	createActionsColumnConfig,
	createCheckboxSelectionModel,
	createGrid,
	ROW_COLOR_CLS
} from '@UIkit/components/grid';
import { createContainer, createModalForm } from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createButton, BUTTON_CLS, createTool, TOOL_CLS } from '@UIkit/components/buttons';
import { createFieldsForProductGrid } from '@Components/fields';

import './EditableGrid.scss';

const createEditableGrid = function (config, data) {
	config = 'object' == typeof config ? config : {};
	var fields = config.fields || [];
	var model = config.model || 'SIMPLE';
	var limit = config.limit;
	var readOnly = config.readOnly || false;
	var deleteManyBtn = config.deleteManyBtn || { text: '' };
	var deleteRowBtn = config.deleteRowBtn || {};
	var editRowBtn = config.editRowBtn || {};
	var actionColumns = config.actionColumns || {};
	var detailsRowBtn = config.detailsRowBtn || {};
	var canAddRow = config.canAddRow || false;
	var isHideAddBtn = config.isHideAddBtn || readOnly;
	var isDisableAddBtn = config.isDisableAddBtn || false;
	var isHideDeleteManyBtn = config.isHideDeleteManyBtn !== false || readOnly;
	var showDetailRowButton = config.showDetailRowButton || false;
	var columns, grid, modalForm;
	var storeConfig = config.storeConfig || {};
	var viewConfig = config.viewConfig || {};
	if (config.columnsConfig) {
		columns = edi.columns.get(config.columnsConfig);
	} else {
		var columnItems = [];
		for (var i = 0; i < fields.length; i++) {
			var field = fields[i];
			columnItems.push({
				text: edi.i18n.getMessage(field.title || 'column.' + edi.utils.formatName(field.name)),
				dataIndex: field.name,
				renderer: field.renderer || edi.renderers.encodedString,
				flex: 1
			});
		}
		columns = columnItems;
	}
	var addButton, updateAddButton, deleteManyButton, attachGridActionColumns;

	if (!readOnly) {
		attachGridActionColumns = Ext.merge(
			createActionsColumnConfig({
				items: [
					Ext.merge(
						{
							glyph: edi.constants.ICONS.EDIT,
							testCls: 'test-action-column-edit',
							isActionDisabled: function (view, rowIndex, colIndex, clickItem, record) {
								return 'function' == typeof config.canEditRow
									? !config.canEditRow(record, view.getStore(), view)
									: false;
							},
							handler: function (grid, rowIndex) {
								var record = grid.getStore().getAt(rowIndex);
								if (record) {
									createModal(record);
								}
							}
						},
						editRowBtn
					),
					Ext.merge(
						{
							glyph: edi.constants.ICONS.DELETE,
							tooltip: edi.i18n.getMessage('form.btn.delete'),
							testCls: 'test-action-column-remove',
							isActionDisabled: function (view, rowIndex, colIndex, clickItem, record) {
								return 'function' == typeof config.canDeleteRow
									? !config.canDeleteRow(record, view.getStore(), view)
									: false;
							},
							handler: function (grid, rowIndex) {
								edi.core.confirm(null, 'general.line.remove', function () {
									grid.getStore().removeAt(rowIndex);
									updateAddButton();
									if (typeof config.afterEditFunction === 'function') {
										config.afterEditFunction(grid);
									}
								});
							}
						},
						deleteRowBtn
					)
				]
			}),
			actionColumns
		);
		columns.push(attachGridActionColumns);

		addButton = createButton({
			cls: [BUTTON_CLS.secondary, BUTTON_CLS.small, 'edi-row-buttons__add-button'],
			glyph: edi.constants.ICONS.PLUS,
			text: edi.i18n.getMessage('form.btn.add'),
			disabled: isDisableAddBtn,
			handler: function () {
				if (config.beforeAdd && 'function' == typeof config.beforeAdd) {
					config.beforeAdd(createModal);
				} else {
					createModal();
				}
			}
		});
		updateAddButton = function () {
			var count = grid.getStore().count();
			isDisableAddBtn = grid.hasOwnProperty('isDisableAddBtn') ? grid.isDisableAddBtn : isDisableAddBtn;
			addButton.setDisabled(isDisableAddBtn || (limit != undefined && count >= limit));
		};

		deleteManyButton = createTool(
			{
				cls: TOOL_CLS.light,
				tooltip: edi.i18n.getMessage('form.btn.delete'),
				glyph: edi.constants.ICONS.DELETE,
				disabled: true,
				handler: function () {
					edi.core.confirm(null, 'general.manylines.remove', function () {
						var store = grid.getStore();
						var selected = grid.getSelectionModel().getSelection();

						if (selected && selected.length) {
							store.remove(selected);
							updateAddButton();
						}
					});
				}
			},
			deleteManyBtn
		);
	} else if (showDetailRowButton) {
		attachGridActionColumns = createActionsColumnConfig({
			items: [
				Ext.merge(
					{
						glyph: edi.constants.ICONS.DETAILS,
						cls: 'edi-action-column-details',
						testCls: 'test-action-column-details',
						handler: function (grid, rowIndex) {
							var record = grid.getStore().getAt(rowIndex);
							if (record) {
								createModal(record, true);
							}
						}
					},
					detailsRowBtn
				)
			]
		});
		columns.push(attachGridActionColumns);
	}

	var createModal = function (record, readOnly, options) {
		var fieldConfigs = Ext.clone(fields),
			convertedFields = [];
		if (fieldConfigs) {
			let modalFieldsData = !!record ? record.getData() : options?.initialFieldsData || null;
			convertedFields = createFieldsForProductGrid(fieldConfigs, modalFieldsData);
		}
		fieldConfigs.forEach((fieldConfig, i) => {
			let inputField;
			const convertedField = convertedFields[i];
			if (typeof convertedField.getFieldByName === 'function') {
				inputField = convertedField.getFieldByName(fieldConfig.name);
			}
			if (inputField) {
				if (readOnly) {
					inputField.setReadOnly(readOnly);
				} else if ('function' == typeof fieldConfig.readOnly) {
					inputField.setReadOnly(fieldConfig.readOnly(record, grid.getStore()));
				}

				if ('function' == typeof fieldConfig.value) {
					inputField.setValue(fieldConfig.value(record, grid.getStore()));
				}
			}
		});

		modalForm = createModalForm({
			layout: config.layout ?? 'auto',
			autoScroll: true,
			items: convertedFields
		});
		var modal = createModalPanel({
			width: config.modalWidth || MODAL_SIZE.widthSmall,
			title: config.modalTitle ?? config.gridConfig?.title ?? '',
			autoScroll: true,
			items: [modalForm],
			buttonsBefore: !readOnly
				? [
						createContainer({
							items: [
								createButton({
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage(!!record ? 'form.btn.save' : 'form.btn.add'),
									bindToForm: modalForm,
									formBind: true,
									disabled: true,
									handler: function () {
										var store = grid.getStore();
										var values = edi.utils.collectFormValues(modalForm);
										var gridElements = [];
										var findGrid = function (fields) {
											fields.forEach((element) => {
												if (element.type === 'fieldset') {
													findGrid(element.items);
												}
												if (element.subType === 'editableGrid') {
													gridElements.push(element);
												}
											});
										};
										findGrid(config.fields);
										if (gridElements) {
											var gridList = modalForm.query('grid');
											gridList.forEach((grid, index) => {
												var element = gridElements[index];
												var name = element?.name ?? null;
												if (!name) return;
												var gridValues = edi.utils.getDataFromGrid(grid);
												edi.utils.setObjectProperty(values, name, gridValues);
											});
										}
										var isEdit = !!record;

										if (config.validFormFunc && 'function' == typeof config.validFormFunc) {
											if (!config.validFormFunc(values, store)) {
												return;
											}
										}

										var saveRecord = function () {
											var recordToEdit = record || edi.models.createInstance(config.model);
											for (var i in values) {
												if (values.hasOwnProperty(i)) {
													recordToEdit.set(i, values[i]);
												}
											}

											if (!isEdit) {
												store.add(recordToEdit);
												updateAddButton();
											}

											store.commitChanges();
											modal.close();
											if (typeof config.afterEditFunction === 'function') {
												config.afterEditFunction(grid);
											}
										};

										if (!isEdit && config.recordsUniqueByField) {
											var fieldName = config.recordsUniqueByField;
											var existingRec = store.findRecord(
												fieldName,
												values[fieldName],
												0,
												false,
												true,
												true
											);
											if (existingRec) {
												edi.core.showError(edi.i18n.getMessage(config.duplicateRecordErrorMsg));
											} else {
												saveRecord();
											}
										} else {
											saveRecord();
										}
									}
								})
							]
						})
				  ]
				: null
		});
		modal.show();
		modalForm.isValid();
	};

	var defaultGridConfig = {
		columns: columns,
		disablePaging: true,
		dockedItems: [],
		tools: [],
		bbar: {
			items: []
		},
		isGridValid: 'function' == typeof config.isGridValid ? config.isGridValid : null
	};
	if (!isHideAddBtn) {
		defaultGridConfig.bbar.items.push(addButton);
	}
	if (!isHideDeleteManyBtn) {
		defaultGridConfig.tools.push(deleteManyButton);
		defaultGridConfig.disableSelection = false;
		defaultGridConfig.selModel = createCheckboxSelectionModel({
			listeners: {
				selectionchange: function (model, selected) {
					deleteManyButton.setDisabled(!(selected && selected.length));
				}
			}
		});
	}

	var isGridValid = [];

	if ('function' == typeof config.isRowValid) {
		defaultGridConfig.viewConfig = {
			getRowClass: function (record, rowIndex) {
				isGridValid[rowIndex] = config.isRowValid(record);

				return !isGridValid[rowIndex] ? ROW_COLOR_CLS.error : '';
			}
		};
	}

	if (config.gridConfig && config.gridConfig.tools && Ext.isArray(config.gridConfig.tools)) {
		config.gridConfig.tools = config.gridConfig.tools.concat(defaultGridConfig.tools);
	}
	var conf = Ext.applyIf(config.gridConfig || {}, defaultGridConfig);

	grid = createGrid({
		cls: 'ui-editable-grid',
		proxyConfig: config.proxyConfig || {
			type: 'memory',
			reader: {
				type: 'json',
				rootProperty: edi.constants.LIST_ROOT_PROPERTY
			},
			model: edi.models.getModel(model),
			data: {
				items: data
			}
		},
		gridConfig: conf,
		storeConfig: storeConfig,
		viewConfig: viewConfig
	});

	var gridStore = grid.getStore();

	if (config.useCanDeleteHandlerForAllRows && 'function' == typeof config.canDeleteRow) {
		var toggleDeleteButton = function (store, record) {
			if (config.canDeleteRow(record, store)) {
				if (attachGridActionColumns.enableAction) {
					attachGridActionColumns.enableAction(1);
				}
			} else {
				if (attachGridActionColumns.disableAction) {
					attachGridActionColumns.disableAction(1);
				}
			}
		};
		gridStore.on('update', toggleDeleteButton);
		gridStore.on('remove', toggleDeleteButton);
	}

	var rowsValid = function () {
		if ('function' == typeof config.isRowValid) {
			var storeRange = gridStore.getRange(),
				isValid = true;
			Ext.Array.every(storeRange, function (record) {
				isValid = config.isRowValid(record);
				return isValid;
			});
			return isValid;
		} else {
			return true;
		}
	};
	grid.isValid = function () {
		return rowsValid() && ('function' == typeof grid.isGridValid ? grid.isGridValid(gridStore) : true);
	};

	if (!readOnly) {
		updateAddButton();
	}

	grid.getModalForm = function () {
		return modalForm;
	};

	grid.setGridAddButtonDisabled = function (isDisabled) {
		grid.isDisableAddBtn = isDisabled;
		addButton?.setDisabled(isDisabled);
		grid.getView().refresh();
	};

	if (canAddRow && 'function' == typeof canAddRow) {
		var canRowFunc = function (store) {
			isDisableAddBtn = canAddRow(store);
		};
		gridStore.on('load', canRowFunc);
		gridStore.on('add', canRowFunc);
		gridStore.on('remove', canRowFunc);
	}

	if (!readOnly) {
		grid.getView().on('refresh', updateAddButton);
	}

	return grid;
};

export { createEditableGrid };
