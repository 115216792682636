export const orgSelectorMethods = {
	/**
	 * @returns {Function} - Функция-конвертер по умолчанию для преобразования данных организации.
	 */
	getDefaultOrgConverter: () => edi.converters.convertOrgToPartie,
	/**
	 * Получает значения организации с указанным id и преобразует их с помощью заданного конвертера.
	 * @param {Object} options - Параметры метода.
	 * @param {string} options.orgId - Идентификатор организации.
	 * @param {Function} [options.converter] - Конвертер для преобразования данных орг. Должен быть равен processValues который передается в orgSelector
	 * @returns {Object} - Объект с данными организации, преобразованными указанным конвертером.
	 */
	getOrgCardValues: function ({ orgId, converter }) {
		const org = edi.utils.getOrg({ orgId });
		if (org) {
			const converterFn = typeof converter === 'function' ? converter : this.getDefaultOrgConverter();
			return converterFn(org);
		}
		return {};
	}
};
