import { createLink } from '@Components/buttons';
import { createCombo, createLabel } from '@Components/fields';
import { createPanel } from '@Components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

/**
 * Creates user organization combo
 * @returns	{Object}	Ext.form.ComboBox instance
 */
const createUserOrganizationsSwitch = function () {
	var sortedOrgs = edi.methods.filiations.sortOrgsByFiliations(edi.core.getUserData().orgs);
	var isHideCombo = false;
	if (sortedOrgs.length === 1) {
		isHideCombo = sortedOrgs[0].orgType === edi.constants.ORGANIZATION_TYPES.PERSON;
	}
	return createCombo(
		Object.assign(
			{
				store: edi.stores.createInlineStore(sortedOrgs, 'ORGANIZATION'),
				columnWidth: null,
				hidden: isHideCombo,
				cls: 'edi-user-org-selector',
				width: 250,
				displayField: 'visualName',
				valueField: 'id',
				allowBlank: false,
				anyMatch: true,
				forceSelection: true,
				defaultListConfig: {
					cls: 'edi-user-org-selector-list'
				},
				getQtipText: function (selectedValueObj) {
					var headOrg = selectedValueObj.headOrg,
						tooltip = selectedValueObj.visualName || '';
					if (selectedValueObj.hasFilials) {
						tooltip += ' (' + edi.i18n.getMessage('org.is.head.org') + ')';
					} else if (headOrg && headOrg.id) {
						tooltip +=
							' (' +
							edi.i18n.getMessage('org.is.filiation.of.another.org', {
								headOrg: headOrg.name
							}) +
							')';
					}

					return tooltip;
				},
				showQtips: true,
				emptyText: edi.i18n.getMessage('form.combo.not.selected'),
				value: edi.core.getUserOrgID(),
				listeners: {
					select: function (comp) {
						var val = comp.getValue();
						if (val && val != edi.core.getUserOrgID()) {
							edi.login.setCurrentOrganization(
								val,
								function (failed) {
									if (!failed) {
										document.location.reload();
									} else {
										comp.setValue(edi.core.getUserOrgID());
									}
								},
								undefined,
								true
							);
						}
					},
					afterrender: function () {
						var __combo = this;
						var store = __combo.getStore();
						if (store.getCount()) {
							__combo.setValue(edi.core.getUserOrgID());
						} else {
							store.on('load', function () {
								__combo.setValue(edi.core.getUserOrgID());
							});
						}
					}
				}
			},
			edi.constants.DEFAULT.COMPONENTS_SETTINGS.USER_ORG_COMBO
		)
	);
};

/**
 * Creates panel of breadcrumb
 * @param	{Object}	tabPanel	config options in form of Ext.tab.Panel instance
 * @returns	{Object}	Ext.Panel instance
 */
const createBreadCrumb = function (tabPanel) {
	var panel = createPanel({
		cls: 'edi-bread-crumb',
		region: 'north',
		layout: 'column',
		height: 24
	});
	var closeModulesAfter = function (moduleData) {
		var i = 0;
		var closing = false;
		var changed = false;
		while (i < tabPanel.items.items.length) {
			var tabItem = tabPanel.items.items[i];
			var module = tabItem.module;
			if (closing) {
				if (module.isChanged) {
					changed = true;
				}
			}
			i++;
			if ((!module.id && module.objectId == moduleData.objectId) || (module.id && module.id == moduleData.id)) {
				closing = true;
			}
		}
		var startClosingModules = function () {
			var i = 0;
			var closing = false;
			while (i < tabPanel.items.items.length) {
				var tabItem = tabPanel.items.items[i];
				var module = tabItem.module;
				module.isChanged = false;
				if (closing) {
					edi.modulesHandler.removeModule(module);
				} else {
					i++;
				}
				if (
					(!module.id && module.objectId == moduleData.objectId) ||
					(module.id && module.id == moduleData.id)
				) {
					closing = true;
				}
			}
		};
		if (changed) {
			edi.core.confirm('confirm.changed.close.title', 'confirm.changed.close.text', startClosingModules);
		} else {
			startClosingModules();
		}
	};

	var addItem = function (module, isLast) {
		var item = createLink({
			cls:
				'edi-label-button edi-bread-crumb-item' +
				(panel.items.length == 0 ? ' first-item' : '') +
				(isLast ? ' last-item' : ''),
			text: edi.i18n.getMessage(module.title),
			handler: function () {
				closeModulesAfter(module);
			}
		});
		panel.add(item);
		return item;
	};

	var update = function () {
		panel.removeAll();
		var countItems = tabPanel.items.items.length;
		for (var i = 0; i < countItems; i++) {
			var tabItem = tabPanel.items.items[i];
			var module = tabItem.module;
			tabItem.breadCrumbItem = addItem(module, i == countItems - 1);
		}
		var rightContainer = createPanel({
			cls: 'edi-breadcrump-right' + (!countItems ? '-alone' : ''),
			layout: 'column'
		});
		rightContainer.add(createUserOrganizationsSwitch());
		if (edi.login.getAuthType() != 'AB' && !edi.core.getHeader()) {
			rightContainer.add(
				createButton({
					cls: [BUTTON_CLS.light, 'edi-logout-button'],
					text: edi.i18n.getMessage('user.logout'),
					handler: edi.login.logout
				})
			);
		}
		if (edi.debug && edi.core.getDebugConfig().enabled) {
			var btn = createLink({
				cls: 'edi-label-button edi-bread-crumb-item edi-breadcrump-button-right',
				text: edi.i18n.getMessage('debug.tools'),
				handler: edi.debug.showDebugWindow
			});
			rightContainer.add(btn);
		}
		rightContainer.add(
			createLabel({
				cls: 'edi-bread-crumb-item edi-breadcrump-button-right',
				//html: "<span class='edi-version-info' data-qtip='" + createVersionTooltipContent() + ' data-qtipFormatted="true">' + edi.core.getBuildVersion().version + "</span>"
				html: `<span class='edi-version-info' data-qtip="${createVersionTooltipContent()}" data-qtipFormatted="true">${
					edi.core.getBuildVersion().version
				}</span>`
			})
		);
		panel.add(rightContainer);
	};

	edi.events.module.on('moduleInit', update);
	edi.events.module.on('moduleRemove', update);
	edi.events.login.on('userOrganizationLoad', update);
	return panel;
};

/**
 * Generates html content for version tooltip
 * @returns	{String}	version tooltip html
 */
const createVersionTooltipContent = function () {
	var html = '';
	var version = edi.core.getBuildVersion();
	html += edi.i18n.getMessage('version.version') + ': ' + version.version + '<br/>';
	if (edi.debug && edi.core.getDebugConfig().enabled) {
		html += edi.i18n.getMessage('version.branch') + ': ' + version.branch + '<br/>';
	}
	html +=
		edi.i18n.getMessage('version.timestamp') +
		': ' +
		edi.utils.formatDate(
			version.timestamp,
			edi.constants.DATE_FORMAT.DATE_TIME_FNS,
			edi.constants.DATE_FORMAT.SERVER
		) +
		'<br/>';
	html += edi.i18n.getMessage('version.profile') + ': ' + version.profile + ' ' + version.buildConfig + '<br/>';
	html += edi.i18n.getMessage('version.changeset') + ': ' + version.changeSet;
	return html;
};

export { createUserOrganizationsSwitch, createBreadCrumb, createVersionTooltipContent };
