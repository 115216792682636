import { createTextField } from '@Components/fields';
import { createForm } from '@Components/panels';
import { createContainer } from '@Components/miscComponents';
import { extraDataBroadcastChannel } from '@App/js/core';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { FIELD_CLS } from '@UIkit/components/fields/Base/Base';
import { PTYPE_FIELD_ERROR } from '@UIkit/plugins/fieldError/fieldError';
import { createLabel, createPasswordField } from '@UIkit/components/fields';

/**
 * User login functionality
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.login');
let __self = edi.login,
	localStorage = window.localStorage;
Ext.merge(edi.login, {
	isDevEnv: false,
	passwordSaveCallback: null,
	webkitPasswordFrameReloaded: false,
	loginFrameLocation: '',
	SSO_URL: localStorage.getItem('sso_url') || '',
	SSO_BACK_PARAM: localStorage.getItem('sso_back_param') || '',
	/**
	 * Init handler
	 */
	init: function () {
		__self.isDevEnv = edi.core.getDebugConfig().isDevEnv;
		__self.loginFrameLocation = edi.utils.compileURL(String(document.location.href), {
			loginFrame: true
		});
		edi.core.logMessage('Login class initialized', 'info');
		__self.init = function () {
			edi.core.handleException('Login class could not be reinitialized');
		};
	},
	/**
	 * Send email confirmation link
	 * @param callback
	 */
	emailConfirmation: function (callback) {
		var failure = function (data) {
			edi.core.handleException('Failure - create email confirmation');
			edi.core.showError(edi.utils.formatComplexServerError(data, 'email.confirm.error'), function () {
				'function' == typeof callback ? callback(false) : null;
			});
		};

		edi.rest.sendRequest(
			edi.rest.services.USER.SELF.RESEND_CONFIRMATION_EMAIL.PUT,
			'PUT',
			undefined,
			function () {
				'function' == typeof callback ? callback(true) : null;
			},
			failure
		);
	},
	/**
	 * Displays restore password form
	 */
	passwordRestore: function (loginModal) {
		loginModal.hide();
		const restorePassword = function () {
			modal.setLoading(true);
			setError('');
			var values = edi.utils.collectFormValues(formPanel);
			var failure = function (data) {
				edi.core.logMessage('Error sending password recovery request', 'warn');
				setError(edi.utils.formatComplexServerError(data, 'password.recovery.request.failed'));
				modal.setLoading(false);
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.USER.RECOVERY.POST, values),
				'POST',
				Ext.encode({}),
				function () {
					modal.close();
					edi.core.showInfo('password.recovery.request.sent', function () {
						loginModal.show();
					});
				},
				failure,
				null,
				{
					notInterceptable: true,
					suppressDefaultError: true,
					headers: {
						Authorization: edi.constants.AUTH_TYPE_BASIC
					}
				}
			);
		};
		let errorField;
		const centerWin = function () {
			modal.center();
		};
		/**
		 * Fills error container with error msg and force layout of form
		 * @param msg
		 */
		const setError = function (msg) {
			errorField.setVisible(!!msg);
			errorField.setText(msg, false);
			centerWin();
		};

		const logo = createContainer({
			cls: 'edi-login-logo'
		});

		var loginField = createTextField({
			cls: [FIELD_CLS.big],
			fieldLabel: edi.i18n.getMessage('login.form.login'),
			name: 'login',
			listeners: {
				specialkey: function (field, e) {
					if (e.getKey() == e.ENTER) {
						restorePassword();
					}
				},
				afterrender: function (field) {
					setTimeout(function () {
						field.focus();
					}, 2000);
				}
			}
		});
		var formPanel = createForm({
			cls: 'edi-form edi-login-form',
			bodyPadding: 24,
			layout: {
				type: 'grid',
				gap: 24
			},
			submitEmptyText: false,
			items: [
				logo,
				createContainer({
					layout: {
						type: 'grid',
						gap: 16
					},
					items: [
						loginField,
						createContainer({
							cls: 'edi-password-recovery-link-container',
							layout: {
								type: 'hbox',
								align: 'middle',
								pack: 'end'
							},
							items: [
								createLabel({
									text: edi.i18n.getMessage('user.login.link'),
									cls: 'link-label edi-password-recovery-link',
									maskOnDisable: false,
									typography: 'caption_01',
									color: '--color-blue',
									listeners: {
										render: function (comp) {
											comp.el.on('click', function () {
												modal.close();
												loginModal.show();
											});
										}
									}
								})
							]
						}),
						(errorField = createLabel({
							text: '',
							hidden: true,
							color: '--color-error',
							typography: 'body-short_01',
							cls: 'edi-login-error-label'
						}))
					]
				}),
				createButton({
					text: edi.i18n.getMessage('password.recovery.send'),
					handler: restorePassword,
					cls: [BUTTON_CLS.primary, 'edi-login-submit-button']
				})
			]
		});

		var modalConf = {
			cls: 'edi-login-form-window',
			title: ' ',
			width: edi.constants.DEFAULT.LOGIN_FORM_WIDTH || MODAL_SIZE.widthSmall,
			minWidth: edi.constants.DEFAULT.LOGIN_FORM_WIDTH || MODAL_SIZE.widthSmall,
			layout: 'fit',
			items: [formPanel],
			ghost: false,
			closable: false,
			draggable: false,
			resizable: false,
			listeners: {
				close: function () {
					edi.core.getViewPort().un('resize', centerWin);
				}
			}
		};
		var modal = createModalPanel(modalConf, true);
		modal.on('show', function () {
			formPanel.isValid();
			let input = document.querySelector(`#${loginField.getInputId()}`);
			input && input.focus();
		});
		edi.core.getViewPort().on('resize', centerWin);
		modal.show();
	},
	/**
	 * Checks password expiration date and displays warning in case of close expiration date
	 */
	checkPasswordExpired: function () {
		var user = edi.core.getUserData(),
			lifeTime = user.currentPassLifetime;
		if (lifeTime !== edi.constants.PASSWORD.LIFE_TIME.UNLIMITED) {
			var lifetimeDays = edi.constants.PASSWORD.LIFE_TIME.DAYS_COUNT[lifeTime],
				passwordDateEnd = user.passChangeDate + lifetimeDays * edi.constants.DAY_IN_MS,
				remainingTimeMillisec = passwordDateEnd - new Date().getTime();

			// Show password expiration warning if less than 3 days remained
			if (remainingTimeMillisec <= edi.constants.DAY_IN_MS * 3) {
				var timeLeft = Math.floor(remainingTimeMillisec / edi.constants.DAY_IN_MS),
					timePostfix = 'password.time.days',
					timeStr;

				//if less than 1 day remained, show hours
				if (remainingTimeMillisec < edi.constants.DAY_IN_MS) {
					timeLeft = Math.floor(remainingTimeMillisec / edi.constants.HOUR_IN_MS);
					timePostfix = 'password.time.hours';

					//if less than 1 hour remained, show minutes
					if (remainingTimeMillisec < edi.constants.HOUR_IN_MS) {
						timeLeft = Math.floor(remainingTimeMillisec / edi.constants.MINUTE_IN_MS);
						timePostfix = 'password.time.minutes';
					}
				}

				timeStr = edi.utils.formatString(edi.i18n.getMessage(timePostfix), {
					time: timeLeft
				});

				edi.core.showWarn(
					edi.utils.formatString(edi.i18n.getMessage('password.time.remained'), {
						time: timeStr
					})
				);
			}
		}
	},

	/**
	 * Show login modal for local (same server) auth
	 * @param	{Object}	user
	 * @param	{Function}	success
	 * @param	{Function}	userDataFn
	 */
	showLocalLoginModal: function (user, success, userDataFn) {
		let attemptQnt = 0,
			loginTimeout = __self.getLoginTimeoutCookie(),
			currTime = new Date().getTime(),
			loginBtn,
			needBlockLogin = loginTimeout && currTime - loginTimeout < edi.constants.LOGIN_TIMEOUT,
			errorField,
			recoveryLink;
		edi.core.getViewPort().addCls('edi-login-form-displayed');

		const unDisableError = function () {
			setError('');
			loginBtn.setDisabled(false);
		};

		const logo = createContainer({
			cls: 'edi-login-logo'
		});

		const loginField = createTextField({
			cls: [FIELD_CLS.big],
			fieldLabel: edi.i18n.getMessage('login.form.login'),
			name: 'login',
			msgTarget: 'none',
			blankTextKey: '',
			validator: function () {
				if (!errorField.isVisible()) {
					return true;
				}
			},
			listeners: {
				specialkey: function (field, e) {
					if (e.getKey() == e.ENTER) {
						submitLogin();
					}
				},
				change: unDisableError
			}
		});
		const passwordField = createPasswordField({
			cls: [FIELD_CLS.big],
			fieldLabel: edi.i18n.getMessage('login.form.password'),
			name: 'password',
			msgTarget: 'none',
			emptyText: null,
			validator: function () {
				if (!errorField.isVisible()) {
					return true;
				}
			},
			listeners: {
				specialkey: function (field, e) {
					if (e.getKey() == e.ENTER) {
						submitLogin();
					}
				},
				change: unDisableError
			}
		});

		var forcePasswordSave = function (callback) {
			__self.passwordSaveCallback = callback;
			document.getElementById('edi_loginForm').submit();
		};

		var successFn = function (data) {
			edi.core.getFooter()?.setVisible(false);
			modal.modal = true;
			Ext.WindowManager.bringToFront(modal);
			__self.getOrganizations(function (orgs) {
				data.data.orgs = orgs;
				userDataFn(data.data);
				var userLogged = edi.core.getUserData();
				if (!user || userLogged.id == user.id) {
					if (!user) {
						if (__self.getAuthType() === 'EDI') {
							__self.checkPasswordExpired();
						}

						forcePasswordSave(function () {
							modal.close();
							__self.setLoginState((userLogged && !userLogged.orgs.length) || edi.core.getUiRendered());
						});

						edi.events.modules.on('rendered', function () {
							edi.core.getViewPort().setLoading(false);
						});
					} else {
						modal.close();
						__self.setLoginState(edi.core.getUiRendered());
						edi.core.getViewPort().setLoading(false);
					}
					success();
				} else {
					if (__self.getAuthType() === 'EDI') {
						__self.checkPasswordExpired();
					}
					forcePasswordSave(function () {
						modal.close();
						document.location.reload();
					});
				}
			});
		};

		const blockLoginItems = function (isBlock) {
			[loginField, passwordField].forEach((f) => f.setEditable(!isBlock));
			[recoveryLink, loginBtn].forEach((b) => b.setDisabled(isBlock));
		};

		const blockLogin = function (skipCookie) {
			skipCookie = 'boolean' == typeof skipCookie ? skipCookie : false;
			!skipCookie ? __self.setLoginTimeoutCookie() : null;
			var leftTime = parseInt(
				(skipCookie ? edi.constants.LOGIN_TIMEOUT - (currTime - loginTimeout) : edi.constants.LOGIN_TIMEOUT) /
					1000,
				10
			);
			var interval = window.setInterval(function () {
				leftTime -= 1;
				const isLeftTimeExpired = leftTime <= 0;
				blockLoginItems(!isLeftTimeExpired);
				if (isLeftTimeExpired) {
					attemptQnt = 0;
					window.clearInterval(interval);
					let input = document.querySelector(`#${loginField.getInputId()}`);
					input && input.focus();
					unDisableError();
				}
			}, 1000);
		};
		/**
		 * Fills error container with error msg and force layout of form
		 * @param msg
		 */
		const setError = function (msg) {
			errorField.setVisible(!!msg);
			errorField.setText(msg, false);
			loginField.isValid();
			passwordField.isValid();
			modal.updateLayout();
			centerWin();
		};
		/**
		 * Submits login form
		 */
		var submitLogin = function () {
			var form = formPanel.getForm();
			var values = edi.utils.collectFormValues(form);
			if (values?.login != '' && values?.password != '' && form.isValid()) {
				setError('');
				blockLoginItems(true);
				edi.core.getViewPort().setLoading();
				edi.rest.sendRequest(
					edi.rest.services.USER.LOGIN.POST,
					'POST',
					Ext.encode({
						login: values.login,
						password: values.password
					}),
					successFn,
					function (responseData) {
						attemptQnt++;
						if (attemptQnt >= 3) {
							setError(
								edi.utils.formatString(edi.i18n.getMessage('login.failed.qnt.times'), {
									qnt: edi.constants.LOGIN_ATTEMPTS,
									block: parseInt(edi.constants.LOGIN_TIMEOUT / 1000, 10)
								})
							);
							blockLoginItems(true);
							blockLogin();
						} else {
							const msg = edi.utils.formatComplexServerError(responseData, 'login.failed');
							blockLoginItems(false);
							setError(msg);
							let input = document.querySelector(`#${loginField.getInputId()}`);
							input && input.focus();
						}
						loginBtn.setDisabled(true);
						edi.core.getViewPort().setLoading(false);
					},
					undefined,
					{
						notInterceptable: true,
						suppressDefaultError: true
					}
				);
			} else {
				loginBtn.setDisabled(true);
				setError(edi.i18n.getMessage('login.form.not.valid'));
				const errorCls = loginField.findPlugin(PTYPE_FIELD_ERROR)?._invalidFieldCls;
				loginField.toggleCls(errorCls, !loginField.getValue());
				passwordField.toggleCls(errorCls, !passwordField.getValue());
			}
		};
		const userLogged = edi.core.getUserData();
		const hideMask = !(userLogged && userLogged.id) && edi.constants.DEFAULT.LOGIN_FORM_HIDE_MASK;
		edi.core.getFooter()?.setVisible(hideMask);
		let form;
		const modalConf = {
				cls: 'edi-login-form-window',
				title: ' ',
				width: edi.constants.DEFAULT.LOGIN_FORM_WIDTH || MODAL_SIZE.widthSmall,
				minWidth: edi.constants.DEFAULT.LOGIN_FORM_WIDTH || MODAL_SIZE.widthSmall,
				layout: 'fit',
				items: [
					(form = Ext.create('Ext.Panel', {
						html: [
							"<iframe id='edi_loginFrame' name='edi_loginFrame' src='./login/login.html' class='edi-login-frame'></iframe>",
							"<form id='edi_loginForm' name='edi_loginForm' method='",
							edi.constants.LOGIN_FORM_CRD_SAVE_METHOD,
							"' action='" + __self.loginFrameLocation + "' target='edi_loginFrame'>",
							'GET' === edi.constants.LOGIN_FORM_CRD_SAVE_METHOD
								? "<input type='hidden' name='loginFrame' value='true'/>"
								: '',
							'</form>'
						].join('')
					}))
				],
				ghost: false,
				closable: false,
				draggable: false,
				resizable: false,
				modal: !hideMask,
				listeners: {
					close: function () {
						edi.core.getViewPort().un('resize', centerWin);
					}
				}
			},
			centerWin = function () {
				modal.center();
			};
		var modal = createModalPanel(modalConf, true);
		modal.show();

		modal.add(form);
		var formPanel = createForm({
			cls: 'edi-form edi-login-form',
			bodyPadding: 24,
			layout: {
				type: 'grid',
				gap: 24
			},
			submitEmptyText: false,
			hideMode: 'visibility',
			hidden: true,
			renderTo: 'edi_loginForm',
			items: [
				logo,
				createContainer({
					layout: {
						type: 'grid',
						gap: 16
					},
					items: [
						loginField,
						passwordField,
						createContainer({
							cls: 'edi-password-recovery-link-container',
							layout: {
								type: 'hbox',
								align: 'middle',
								pack: 'end'
							},
							items: [
								(recoveryLink = createLabel({
									text: edi.i18n.getMessage('password.recovery.link'),
									cls: 'link-label edi-password-recovery-link',
									maskOnDisable: false,
									typography: 'caption_01',
									color: '--color-blue',
									listeners: {
										render: function (comp) {
											comp.el.on('click', function () {
												setError('');
												edi.login.passwordRestore(modal);
											});
										}
									}
								}))
							]
						}),
						(errorField = createLabel({
							cls: 'edi-login-error-label',
							typography: 'body-short_01',
							color: '--color-error',
							text: '',
							hidden: true
						}))
					]
				}),
				(loginBtn = createButton({
					text: edi.i18n.getMessage('form.btn.login'),
					handler: submitLogin,
					cls: [BUTTON_CLS.primary, 'edi-login-submit-button']
				}))
			]
		});
		if (needBlockLogin) {
			blockLogin(true);
		} else {
			edi.utils.async(function () {
				var fields = formPanel.getForm().getFields();
				fields.each(function (fld) {
					fld.blur(); //hook to force browser fill component with change fire
				});
				loginBtn.focus();
			}, 400);
		}
		formPanel.updateLayout();
		formPanel.setVisible(true);
		modal.updateLayout();
		centerWin();
		edi.core.getViewPort().on('resize', centerWin);
		formPanel.on('validitychange', function (form, valid) {
			if (valid) {
				setError('');
			}
		});
		__self.setLoginState('login');
	},

	/**
	 * Show login modal for SSO auth with iframe
	 * @param	{Object}	user
	 * @param	{Function}	success
	 * @param	{Function}	userDataFn
	 */
	showSsoLoginModal: function (user, success, userDataFn) {
		let userLogged = edi.core.getUserData();
		let hideMask = !(userLogged && userLogged.id) && edi.constants.DEFAULT.LOGIN_FORM_HIDE_MASK;

		let centerWin = function () {
			modal.center();
		};
		let modal = createModalPanel(
			{
				cls: 'edi-login-form-window edi-login-sso-window',
				width: 500,
				height: 700,
				layout: 'fit',
				items: [],
				ghost: false,
				closable: false,
				draggable: false,
				resizable: false,
				modal: !hideMask,
				listeners: {
					close() {
						edi.core.getViewPort().un('resize', centerWin);
						window.removeEventListener('message', messageHandler);
					}
				}
			},
			true
		);
		modal.show();

		//url нужен что б фрейм знал куда слать сообщение, а не всем подряд
		modal.add(
			Ext.create('Ext.Panel', {
				html: `<iframe id="edi_loginFrame_sso"
					class="edi-login-frame-sso"
					src="${__self.compileSsoUrl({
						runinframe: 'true',
						url: document.location.href
					})}"
					>
			</iframe>`
			})
		);

		let messageHandler = function (msg) {
			if (!msg?.data?.newAuthToken) {
				return;
			}
			__self
				.checkAuthToken(msg.data.newAuthToken)
				.then((resp) => {
					__self.getOrganizations(function (orgs) {
						resp.data.orgs = orgs;
						userDataFn(resp.data);
						var userLogged = edi.core.getUserData();
						if (!user || userLogged.id == user.id) {
							if (!user) {
								if (__self.getAuthType() === 'EDI') {
									__self.checkPasswordExpired();
								}
								modal.close();
								__self.setLoginState(
									(userLogged && !userLogged.orgs.length) || edi.core.getUiRendered()
								);
							} else {
								modal.close();
								__self.setLoginState(edi.core.getUiRendered());
							}
							success();
						} else {
							if (__self.getAuthType() === 'EDI') {
								__self.checkPasswordExpired();
							}
							modal.close();
							document.location.reload();
						}
					});
				})
				.catch((resp) => edi.core.showError(edi.utils.formatComplexServerError(resp, 'error.getting.data')));
		};

		window.addEventListener('message', messageHandler);

		modal.updateLayout();
		centerWin();
		edi.core.getViewPort().on('resize', centerWin);
		__self.setLoginState('login');
	},

	/**
	 * Display login form
	 * @param success
	 * @param userDataFn
	 */
	showlogin: function (success, userDataFn) {
		var user = edi.core.getUserData();
		if (__self.getAuthType() === 'AB') {
			if (user && user.id) {
				edi.core.showWarn('login.form.ab.session.ended', function () {
					document.location.reload();
				});
			} else {
				var showError = function () {
					edi.core.showWarn('login.form.ab.session.ended', showError);
				};
				showError();
			}
		} else {
			edi.core.getViewPort().setLoading(false);
			if (__self.SSO_URL) {
				__self.showSsoLoginModal(user, success, userDataFn);
			} else {
				__self.showLocalLoginModal(user, success, userDataFn);
			}
		}
	},
	/**
	 * Fires password save callback on frame load
	 */
	firePasswordSaveCallback: function () {
		if ('function' == typeof __self.passwordSaveCallback) {
			var frame = document.getElementById('edi_loginFrame');
			if (
				!__self.webkitPasswordFrameReloaded &&
				navigator.userAgent.toLowerCase().indexOf('webkit') !== -1 &&
				navigator.userAgent.toLowerCase().indexOf('edge') === -1
			) {
				__self.webkitPasswordFrameReloaded = true;
				frame.contentWindow.location.reload();
			} else {
				__self.passwordSaveCallback();
				__self.passwordSaveCallback = null;
				__self.webkitPasswordFrameReloaded = false;
				edi.core.getViewPort().removeCls('edi-login-form-displayed');
				if (
					edi.constants.DEFAULT.BACKGROUND.ON_START &&
					edi.constants.DEFAULT.BACKGROUND.IMG_LIST &&
					edi.constants.DEFAULT.BACKGROUND.IMG_LIST.length &&
					'MONTH' !== edi.constants.DEFAULT.BACKGROUND.ROTATION
				) {
					var bgNr = parseInt(edi.utils.getCookie(edi.constants.DEFAULT.BACKGROUND.COOKIE_NAME) || 0, 10) + 1;
					if (bgNr >= edi.constants.DEFAULT.BACKGROUND.IMG_LIST.length) {
						bgNr = 0;
					}
					edi.utils.setCookie(edi.constants.DEFAULT.BACKGROUND.COOKIE_NAME, bgNr);
				}
			}
		}
	},
	/**
	 * Get user extra data
	 * @param {Function}    callback
	 */
	getExtraData: function (callback) {
		var success = function (data) {
			edi.core.logMessage('User extra data loaded', 'info');
			'function' == typeof callback ? callback(data.data) : null;
		};
		var failure = function () {
			edi.core.handleException('User extra data did not loaded properly');
			'function' == typeof callback ? callback(null) : null;
		};
		edi.rest.sendRequest(edi.rest.services.USER.SELF.EXTRA_INFO.GET, 'GET', null, success, failure);
	},

	/**
	 * Sends new auth token to update session cookie via backend
	 * @param	{string}	token
	 * @returns	{Promise<Object>}	response from backend
	 */
	checkAuthToken: (token) =>
		new Promise((resolve, reject) => {
			let success = function (response) {
				if (response?.success === true) {
					resolve(response);
				} else {
					reject(response);
				}
			};
			let payload = Ext.encode({ authToken: token });
			edi.rest.sendRequest(
				edi.rest.services.USER.CHECK_AUTH_TOKEN.POST,
				'POST',
				payload,
				success,
				reject,
				undefined,
				{ notInterceptable: true }
			);
		}),

	/**
	 * Compiles sso url with query params
	 * @param	{Object}	[additionalParams]
	 * @returns {String}
	 */
	compileSsoUrl: function (additionalParams) {
		let dc = new Date().valueOf();
		let params = Ext.merge(
			{},
			{
				_dc: dc,
				url: document.location.href,
				backParam: __self.SSO_BACK_PARAM
			},
			additionalParams
		);
		edi.utils.clearEmptyValues(params);
		return edi.utils.compileURL(__self.SSO_URL, params);
	},

	/**
	 * get user data and set it to userData
	 * @param    {Function}   callback            callback that will be called after response processing finish
	 * @param    {Boolean}    skipEvent           true to skip event fire
	 * @param    {Function}   userDataCallback    callback that will be called on success and accept user data as parameter
	 */
	getUser: function (callback, skipEvent, userDataCallback) {
		Ext.Ajax.defaultHeaders.Authorization = edi.login.getAuthType();
		if (edi.login.getAuthType() === 'ACTIVATION') {
			edi.rest.setGlobalRequestParams({
				login: edi.utils.getURLParams().login
			});
		}

		let getParams = edi.utils.getURLParams();
		let authTokenFromParams = getParams && getParams['token'];
		if (authTokenFromParams) {
			Ext.util.Cookies.set('edi_auth_token', authTokenFromParams);
			delete getParams.token;

			window.history.replaceState(
				null,
				null,
				window.location.pathname + (Ext.urlEncode(getParams) ? '?' + Ext.urlEncode(getParams) : '')
			);
		}

		var success = function (data, opts, conn) {
			__self.getOrganizations(function (orgs) {
				edi.core.logMessage('User data loaded', 'info');
				if (!skipEvent) {
					edi.events.login.fireEvent('userLoad');
				}
				if (Array.isArray(orgs) && orgs.length) {
					orgs = orgs.map(function (orgItem) {
						if (orgItem.orgData) {
							var orgData = orgItem.orgData;
							delete orgItem.orgData;

							delete orgData.creationDate;
							delete orgData.modifyDate;
							delete orgData.header;
							delete orgData.id;

							Object.assign(orgItem, orgData);
						}
						return orgItem;
					});
				}
				__self.getExtraData(function (extra) {
					var result = data.data;
					result.orgs = orgs;
					result.userData = extra;
					'function' == typeof userDataCallback ? userDataCallback(result) : null;
					'function' == typeof callback ? callback() : null;
				});
			});
		};
		var failure = function (response, options, conn) {
			if (!__self.SSO_URL && !!conn.getAllResponseHeaders().sso) {
				__self.SSO_URL = conn.getAllResponseHeaders().sso || '';
				localStorage.setItem('sso_url', __self.SSO_URL);
				__self.SSO_BACK_PARAM = conn.getAllResponseHeaders().back_param || '';
				localStorage.setItem('sso_back_param', __self.SSO_BACK_PARAM);
			}
			if (!conn.getAllResponseHeaders().sso) {
				localStorage.removeItem('sso_url');
				__self.SSO_URL = '';
				localStorage.removeItem('sso_back_param');
				__self.SSO_BACK_PARAM = '';
			}

			//нет авторизации при включенном SSO - отправляем авторизироваться в SSO урл для редиректа обратно
			if (__self.SSO_URL && !authTokenFromParams) {
				document.location.href = __self.compileSsoUrl();
			}
			//при включенном SSO авторизация на SSO прошла, но на продукте старая сессия в куках
			else if (
				__self.SSO_URL &&
				authTokenFromParams &&
				(String(response.status) === edi.constants.STATUS.NOT_AUTHORISED ||
					String(response.status) === edi.constants.STATUS.UNEXPECTED_ERROR)
			) {
				//отправляем бэку токен, что бы он обновил куки сессии (т.к. у них флаг httpOnly)
				edi.login
					.checkAuthToken(authTokenFromParams)
					.then(() => document.location.reload())
					.catch((resp) => {
						edi.core.logMessage(resp, 'error');
						//что-то пошло не так (токен протух, например)
						//отправляем на ссо без редиректа, что бы не зациклиться в бесконечных редиректах
						document.location.href = __self.compileSsoUrl({
							url: null,
							noredirect: 'true',
							infocode: response.status
						});
					});
			} else if (String(response.status) !== edi.constants.STATUS.MAINTENANCE_MODE) {
				Ext.Ajax.startLogin();
			}

			edi.core.handleException('User data did not loaded properly');
			if (String(response.status) === edi.constants.STATUS.MAINTENANCE_MODE) {
				edi.core.showWarnWithoutClose(edi.i18n.getMessage(response.typeError));
			} else if (edi.login.getAuthType() === 'ACTIVATION') {
				edi.core.showInfo('registration.push.not.allowed', function () {
					'function' == typeof callback ? callback(true) : null;
				});
			} else {
				'function' == typeof callback ? callback() : null;
			}
		};

		edi.rest.sendRequest(edi.rest.services.USER.SELF.GET, 'GET', null, success, failure, null, {
			suppressDefaultError: true
		});
	},
	/**
	 * Reads all current user organizations
	 * @param    {Function}    callback
	 */
	getOrganizations: function (callback) {
		var success = function (responseData) {
			if (responseData && responseData.items) {
				callback(responseData.items);
			} else {
				callback();
			}
		};
		var failure = function () {
			edi.core.logMessage('Organizations are not loaded', 'warn');
			callback();
		};
		edi.rest.sendRequest(edi.rest.services.USER.ORGANIZATION.LIST.GET, 'GET', null, success, failure, undefined, {
			notInterceptable: true
		});
	},
	/**
	 * Get user positions
	 * @param    {Function}   callback            callback that will be called after response processing finish
	 */
	getPositions: function (callback) {
		edi.core.logMessage('Getting positions', 'info');
		edi.rest.sendRequest(
			edi.rest.services.USER.POSITIONS.GET,
			'GET',
			null,
			function (responseData) {
				edi.core.logMessage('Positions loaded', 'info');
				'function' == typeof callback ? callback(responseData.items) : null;
			},
			function (responseData) {
				edi.core.logMessage('Positions are not loaded', 'warn');
				edi.core.showError(edi.utils.formatComplexServerError(responseData, 'error.server'), callback);
			},
			undefined,
			{
				notInterceptable: true
			}
		);
	},
	/**
	 * Get current user organization and set it, if none is set
	 * @param    {Function}   callback            callback that will be called after response processing finish
	 * @param    {Boolean}    skipSet             true to skip setting organization if none is set
	 * @param    {Function}   orgSetCallback      callback that will be called on organization set success and accept user organization data as parameter
	 */
	getCurrentOrganization: function (callback, skipSet, orgSetCallback) {
		var userData = edi.core.getUserData();
		var setOrganization = function () {
			edi.core.logMessage('Not found selected user organization on backend, forcing selection', 'info');
			var orgId =
				__self.checkSelectedOrganization() || (userData.org && userData.org.id ? userData.org.id : null);
			if (orgId) {
				__self.setCurrentOrganization(orgId, callback, orgSetCallback);
			} else if (!userData.orgs.length) {
				edi.core.showError('error.no.user.organizations', function () {
					if (edi.login.getAuthType() !== 'AB') {
						edi.login.logout();
					} else {
						edi.core.getViewPort().destroy();
					}
				});
				edi.core.logMessage('User has no organizations', 'info');
				'function' == typeof callback ? callback(true) : null;
			}
		};
		var success = function (data) {
			if (!skipSet && (!data || !data.data)) {
				setOrganization();
			} else {
				edi.core.logMessage('Found selected user organization - ' + data.data.id, 'info');
				'function' == typeof orgSetCallback ? orgSetCallback(data.data) : null;
				var userData = edi.core.getUserData();
				__self.setCurrentOrganizationCookie(data.data.id, userData.id);
				'function' == typeof callback ? callback() : null;
				edi.events.login.fireEvent('userOrganizationLoad');
			}
		};
		var failure = function (data) {
			if (data && data.status === edi.constants.STATUS.CURRENT_ORGANIZATION_NOT_FOUND) {
				if (!skipSet) {
					setOrganization();
				} else {
					'function' == typeof callback ? callback(true) : null;
				}
			} else {
				edi.core.handleException(
					'Failure - ' +
						edi.rest.services.USER.ORGANIZATION.GET +
						' returned error ' +
						data.error +
						', status ' +
						data.status
				);
				'function' == typeof callback ? callback(true) : null;
			}
		};
		edi.rest.sendRequest(edi.rest.services.USER.ORGANIZATION.GET, 'GET', null, success, failure, undefined, {
			notInterceptable: true
		});
	},
	/**
	 * Sets current user organization
	 * @param    {String}    orgId           id of the organization
	 * @param    {Function}  callback        callback that will be called after response processing finish
	 * @param    {Function}  orgSetCallback  callback that will be called on success and accept user data as parameter
	 * @param    {Boolean}   skipEvent       true to skip event firing
	 */
	setCurrentOrganization: function (orgId, callback, orgSetCallback, skipEvent) {
		var userData = edi.core.getUserData();
		if (orgId !== userData?.org?.id && extraDataBroadcastChannel) {
			extraDataBroadcastChannel.postMessage('organization changed');
		}
		var success = function (data) {
			if (data && data.data) {
				'function' == typeof orgSetCallback
					? orgSetCallback({
							id: orgId
					  })
					: null;
				__self.setCurrentOrganizationCookie(orgId, userData.id);
				!skipEvent ? edi.events.login.fireEvent('userOrganizationLoad') : null;
				'function' == typeof callback ? callback() : null;
			} else {
				failure();
			}
		};
		var failure = function (errorData) {
			if (
				errorData &&
				edi.utils.getObjectProperty(errorData, 'errorMessage.type') === 'error.organization.has.payment.debt'
			) {
				edi.core.showWarn('error.organization.has.payment.debt.warning.text', function () {
					var changeOrgId;
					for (var i = 0; i < userData.orgs.length; i++) {
						if (userData.orgs[i].id != orgId && !userData.orgs[i].paymentDebt) {
							changeOrgId = userData.orgs[i].id;
							break;
						}
					}
					if (changeOrgId) {
						edi.login.setCurrentOrganization(changeOrgId, callback, orgSetCallback, skipEvent);
					} else {
						'function' == typeof callback ? callback(true) : null;
					}
				});
			} else {
				edi.core.handleException('Failure - checkUserOganization');
				'function' == typeof callback ? callback(true) : null;
			}
		};
		if (orgId) {
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.USER.ORGANIZATION.PUT, {
					orgId: orgId
				}),
				'PUT',
				null,
				success,
				failure,
				undefined,
				{
					notInterceptable: true
				}
			);
		} else {
			failure();
		}
	},
	/**
	 * Sets selected organization for user if he has valid cookie
	 * @param    {Function}    orgSetCallback    callback that will be called to set org id if cookie present and mach current user
	 */
	checkSelectedOrganization: function (orgSetCallback) {
		var selectedOrg = edi.constants.USE_USER_ORG_COOKIE ? edi.utils.getCookie(edi.constants.ORG_COOKIE_NAME) : null,
			data,
			userData = edi.core.getUserData(),
			remove = true,
			orgId = null;
		if (selectedOrg) {
			selectedOrg = edi.utils.base64.decode(selectedOrg);
			try {
				data = Ext.decode(selectedOrg);
			} catch (e) {
				__self.handleException('Selected organization did not parsed properly');
			}
			if (data) {
				if (data.orgId) {
					if (data.userId == userData.id) {
						for (var i = 0; i < userData.orgs.length; i++) {
							if (data.orgId == userData.orgs[i].id) {
								remove = false;
								orgId = data.orgId;
							}
						}
					}
				}
			}
			if (remove) {
				edi.utils.setCookie(edi.constants.ORG_COOKIE_NAME, '', undefined, 1);
			} else {
				'function' == typeof orgSetCallback ? orgSetCallback(data.orgId) : null;
			}
		}
		return orgId;
	},
	/**
	 * Logouts current user from system
	 */
	logout: function () {
		edi.core.getViewPort().setLoading();
		edi.rest.sendRequest(edi.rest.services.USER.LOGOUT.POST, 'POST', undefined, undefined, undefined, function () {
			document.location.reload();
		});
	},
	/**
	 * set auth data to url
	 * @param    {String}    url
	 * @returns  {String}
	 */
	setUrlAuth: function (url) {
		return edi.utils.compileURL(url, {
			authType: __self.getAuthType()
		});
	},
	/**
	 * Returns currently used auth type
	 * @returns {AUTH_TYPE_DEV|*}
	 */
	getAuthType: function () {
		return __self.isDevEnv ? edi.constants.AUTH_TYPE_DEV : edi.constants.AUTH_TYPE;
	},
	/**
	 * Sets organization cookie
	 * @param    {String}    orgId        id of the organization
	 * @param    {String}    userId       id of the user
	 */
	setCurrentOrganizationCookie: function (orgId, userId) {
		if (orgId && userId && edi.constants.USE_USER_ORG_COOKIE) {
			var data = {
				orgId: orgId,
				userId: userId
			};
			edi.utils.setCookie(edi.constants.ORG_COOKIE_NAME, edi.utils.base64.encode(Ext.encode(data)));
		}
	},
	/**
	 * Sets login timeout cookie
	 * @param    {Number}    time    timestamp of last login form lock
	 */
	setLoginTimeoutCookie: function (time) {
		time = time || new Date().getTime();
		var date = new Date(time + edi.constants.LOGIN_TIMEOUT);
		edi.utils.setCookie(
			edi.constants.LOGIN_TIMEOUT_COOKIE_NAME,
			edi.utils.base64.encode(Ext.encode(time)),
			undefined,
			date.getTime()
		);
	},
	/**
	 * Gets login timeout cookie
	 * @returns {*}
	 */
	getLoginTimeoutCookie: function () {
		var cookie = edi.utils.getCookie(edi.constants.LOGIN_TIMEOUT_COOKIE_NAME);
		if (cookie) {
			cookie = edi.utils.base64.decode(cookie);
		}
		return cookie;
	},

	/**
	 * Sets UI login state
	 * @param state
	 */
	setLoginState: function (state) {
		var loginState = {};
		loginState[edi.constants.RENDERED_UI_ATTR] = state;
		Ext.getBody().set(loginState);
	}
});
