import { createContainer } from '@Components/miscComponents';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import './rows-block.scss';

Ext.define('edi.components.RowsBlock', {
	extend: 'Ext.panel.Panel',
	alias: 'widget.rowsblock',

	cls: 'edi-rows-block',
	title: '',
	rowGap: 16,
	items: [],
	readOnly: false,

	createContentFieldsFn() {},
	initialData: [],
	border: 0,
	contentCols: 8,
	buttonsCols: 4,
	onCreate() {},
	onAddRow() {},
	onRemoveRow() {},

	initComponent: function () {
		this.setOwnConfig();
		this.callParent();
		this.afterInit();
	},

	setOwnConfig() {
		let me = this;
		if (!me.initialData) {
			me.initialData = [];
		}
		me.items = me.createInitialRows();
		me.mainRow = me.items[0];
	},
	afterInit() {
		let me = this;
		me.updateRowsBlockButtons();
		if (typeof me.onCreate === 'function') {
			me.onCreate();
		}

		me.on('afterrender', function () {
			me.updateRowsBlock();
			if (typeof me.rowChange === 'function') {
				me.rowChange(me);
			}
		});
	},
	/**
	 * Creates array of rows from initialData
	 * @returns	{Object[]}	Array of rows (containers ot panels)
	 */
	createInitialRows() {
		let me = this;

		let initialRows = me.initialData.map((values, i) => {
			return me.createRow(values, i === 0);
		});
		//если начальных данных нет, то создадим пустую строку
		return initialRows.length > 0 ? initialRows : [me.createRow(null, true)];
	},
	/**
	 * Initial row create method
	 * @param	{any}		rowData
	 * @param	{Boolean}	isFirstRow
	 * @returns	{Object}	row (containers ot panels)
	 */
	createRow(rowData, isFirstRow) {
		let me = this;

		if (me.isReadOnly) {
			var contentContainer = me.createContentFieldsFn(isFirstRow, rowData, {});
			return me.createRowPanel(rowData ? contentContainer : [], []);
		} else {
			return me.createRowWithAddRemoveButtons(isFirstRow, rowData);
		}
	},
	/**
	 * Creates row panel with fields and buttons
	 * @param	{Object}	contentFieldsPanel
	 * @param	{Object}	buttonsContainer
	 * @returns	{Object}	row (Ext.container.Container instance)
	 */
	createRowPanel(contentFieldsPanel, buttonsContainer) {
		let me = this;
		return createContainer({
			layout: {
				type: 'grid',
				area: [[me.contentCols, me.buttonsCols]]
			},
			contentFieldsPanel: contentFieldsPanel,
			buttonsContainer: buttonsContainer,
			items: [contentFieldsPanel, buttonsContainer]
		});
	},
	/**
	 * Add button click handler
	 * @param	{Object}	clickedButton
	 * @param	{Object}	rowWhereClickWas
	 * @param	{any}	[props]	(костыль для упд)
	 */
	addRowClickHandler(clickedButton, rowWhereClickWas, props) {
		let me = this;
		let newRow = me.createRow(null);
		me.add(newRow);
		if (typeof me.rowChange === 'function') {
			me.rowChange(me);
		}
		me.updateRowsBlockButtons();

		if ('function' == typeof me.onAddRow) {
			me.onAddRow(me, newRow);
		}

		if ('function' == typeof rowWhereClickWas?.contentContainer?.onAddRow) {
			rowWhereClickWas.contentContainer.onAddRow(me, newRow);
		}

		me.updateLayout();
	},
	/**
	 * Remove button click handler
	 * @param	{Object}	btn
	 * @param	{Object}	rowWhereClickWas
	 */
	removeRowClickHandler(btn, rowWhereClickWas) {
		let me = this;
		let allRows = me.getRows() || [];

		if (allRows.length <= 1) {
			//если строка всего 1 и кнопка "удалить" не заблокирована,
			//то очистим поля в строке вместо её удаления
			if ('function' == typeof me.onClearRow) {
				me.onClearRow(rowWhereClickWas.contentFieldsPanel);
			} else {
				let inputs = rowWhereClickWas.contentFieldsPanel.query('field') || [];
				inputs.forEach((input) => {
					if (!input.readOnly) {
						input.setValue(null);
						input.isValid();
					}
				});
			}
		} else {
			//если строк больше чем одна, то удалим строку и покажем кнопку "добавить" на последней строке
			me.remove(rowWhereClickWas);
			let lastRow = allRows[allRows.length - 1];
			if (lastRow && lastRow.addBtn && !lastRow.addBtn.isDestroyed) {
				lastRow.addBtn.show();
			}
		}

		if (typeof me.rowChange === 'function') {
			me.rowChange(me);
		}

		me.updateRowsBlockButtons();

		if ('function' == typeof me.onRemoveRow) {
			me.onRemoveRow();
		}
		if ('function' == typeof rowWhereClickWas?.contentFieldsPanel?.onRemoveRow) {
			rowWhereClickWas.contentFieldsPanel.onRemoveRow();
		}
	},
	/**
	 * Creates row with add/remove buttons
	 * @param   {Boolean}    isFirstRow
	 * @param	{any}	rowData
	 * @param	{any}	[props]	(костыль для упд)
	 * @returns	{Object}	row (container ot panel)
	 */
	createRowWithAddRemoveButtons(isFirstRow, rowData, props) {
		let me = this,
			contentContainer = me.createContentFieldsFn(isFirstRow, rowData || {}, props);

		let removeRowBtn = createButton({
			cls: [BUTTON_CLS.secondary, BUTTON_CLS.small, 'edi-row-block-remove_button'],
			itemId: 'remove_button',
			hidden: false,
			permanentlyDisabled: me.disableRemoveButton,
			glyph: edi.constants.ICONS.CLOSE,
			tooltip: edi.i18n.getMessage('form.btn.delete'),
			handler(btn) {
				me.removeRowClickHandler(btn, rowPanel);
			}
		});

		let addRowBtn = createButton({
			cls: [BUTTON_CLS.secondary, BUTTON_CLS.small, 'edi-row-block-add_button'],
			itemId: 'add_button',
			hidden: true,
			permanentlyHidden: me.hideAddButton,
			glyph: edi.constants.ICONS.PLUS,
			tooltip: edi.i18n.getMessage('form.btn.add'),
			text: me?.textAddButton ?? edi.i18n.getMessage('form.btn.add'),
			handler(btn) {
				me.addRowClickHandler(btn, rowPanel, props);
			}
		});

		let rowPanel = me.createRowPanel(
			contentContainer,
			createContainer(
				Object.assign(
					{
						layout: 'column',
						cls: 'row-block-buttons-container',
						items: [removeRowBtn, addRowBtn]
					},
					me.buttonsContainerProps
				)
			)
		);

		rowPanel.addBtn = addRowBtn;
		rowPanel.removeBtn = removeRowBtn;
		me.rowPanel = rowPanel;
		me.setExtraDataToRow(rowPanel);

		return rowPanel;
	},
	removeAllRows() {
		const me = this;
		const rows = [...me.getRows()];
		rows.forEach((row, rowIndex) => {
			const isLastRow = rowIndex === rows.length - 1;
			// Вызов removeRowClickHandler на последней строке нужен для вызова эвентов и апдейта панели
			isLastRow ? me.removeRowClickHandler(undefined, row) : me.remove(row);
		});
	},
	/**
	 * Modifies row after creation
	 * @param	{Object}	row
	 */
	setExtraDataToRow(row) {},
	/**
	 * Enables/disables buttons in rows due to block state
	 */
	updateRowsBlockButtons() {
		let me = this;

		me.suspendLayouts();

		let rows = me.getRows() || [];
		//кнопки "добавить" скрыты у всех строк кроме последней (она же первая если строка всего одна)
		rows.forEach((row) => {
			if (typeof row?.addBtn?.setVisible === 'function') {
				row.addBtn.setVisible(false);
			}
		});
		let lastRow = rows[rows.length - 1];
		if (typeof lastRow?.addBtn?.setVisible === 'function') {
			lastRow.addBtn.setVisible(lastRow.addBtn.permanentlyHidden !== true);
		}

		//обновим доступность кнопок "удалить"
		rows.forEach((row) => {
			if (typeof row?.removeBtn?.setDisabled === 'function') {
				row.removeBtn.setDisabled(row.removeBtn.permanentlyDisabled === true);
			}
		});
		let firstRemoveBtn = rows[0]?.removeBtn;
		//блокируем кнопку "удалить", если строка всего одна
		if (typeof rows[0]?.removeBtn?.setDisabled === 'function') {
			firstRemoveBtn.setDisabled(rows.length <= 1);
		}

		me.resumeLayouts();
		me.layout.redoLayout();

		me.updateRowsBlock();
	},
	updateRowsBlock() {
		let me = this;
		let rows = me.getRows() || [];

		rows.forEach((row, i) => {
			let el = row.el;
			if (el) {
				el.setStyle({
					marginBottom: i == rows.length - 1 ? 0 : `${me.rowGap}px`
				});
			}
		});
	},
	/**
	 * Returns array of rows
	 * @returns	{Object[]}	Array of rows (containers or panels)
	 */
	getRows() {
		let me = this;
		return Ext.isArray(me.items?.items) ? me.items.items : [];
	},
	/**
	 * Returns index of row
	 * @param	{Object}	row		row instance (container or panel)
	 * @returns	{number}	index of row
	 */
	getRowIndex(row) {
		let me = this;
		return Ext.Array.indexOf(me.getRows(), row);
	}
});

const createRowsBlock = function (cfg) {
	return Ext.create('edi.components.RowsBlock', cfg);
};

export { createRowsBlock };
