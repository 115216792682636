import { createHiddenField, createLabel } from '@Components/fields';
import { createPanel } from '@Components/panels';
import { createContainer } from '@Components/miscComponents';
import { createLink } from '@Components/buttons';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import './FileDropper.scss';

const selfCls = 'drag-file';

Ext.define('UI.components.FileDropper', {
	extend: 'Ext.panel.Panel',
	cls: selfCls,
	layout: 'column',
	_fileField: null,
	_fileDropPanel: null,
	_uploadedFilePanel: null,
	_fileName: null,
	isFileLoaded: false,
	focusable: true,
	readOnly: false,
	invalidClass: `${selfCls}-container-with-errors`,
	readOnlyClass: `${selfCls}-read-only`,
	columnWidth: 0.85,
	float: 'right',
	allowBlank: true,
	//максимальный размер файла в МБ
	maxFileSize: 10,
	maxFileSizeErrorKey: 'fileDropper.error.max.size',
	checkMaxFileSize: false,
	fileData: {},
	accept: '',
	multiple: false,

	initComponent: function () {
		this.renderFields();
		this.callParent();
		this.addListeners();
	},

	addListeners: function () {
		const me = this;
		me.on('afterrender', function () {
			me._fileField.fileInputEl?.set({
				accept: me.accept ?? undefined,
				multiple: me.multiple ? true : undefined
			});
		});
		me.on('reset', function () {
			me._fileField.fileInputEl?.set({
				accept: me.accept ?? undefined,
				multiple: me.multiple ? true : undefined
			});
		});
	},

	renderFields: function () {
		const __self = this;

		__self.createFileField();
		__self.createFileDropPanel();
		__self.createUploadedFilePanel();

		__self.valueChangedHandler();
		__self.markReadOnly();

		__self.items = [__self._fileDropPanel, __self._fileField, __self._fileName, __self._uploadedFilePanel];
	},

	/**
	 * Переключение видимости филдов в зависимости от данных файла
	 */
	valueChangedHandler: function () {
		const __self = this;
		const hasData = !(edi.utils.isEmptyObject(__self.fileData) || Ext.isEmpty(__self.fileData));

		__self._fileDropPanel.setVisible(!hasData);
		__self._uploadedFilePanel.setVisible(hasData);

		if (!!hasData) {
			const validFileError = __self.fileValidator(__self.fileData);
			const maxSizeError = __self.fileSizeValidator(__self.fileData);
			const error = validFileError || maxSizeError;
			__self.fileData.validationError = error;
			__self.setUploadedFileData();
			__self._fileField.setValidation(error);
			__self.markUploadedPanelInvalid(!!error);
		}
		__self.setAllowBlank();
		typeof __self.onFileChange === 'function' &&
			__self.onFileChange({
				cmp: __self,
				hasData,
				isValid: __self._fileField?.isValid()
			});
	},

	/**
	 * Создание fileField
	 */
	createFileField: function () {
		const __self = this;
		__self._fileField = new Ext.form.field.File({
			name: __self.name,
			columnWidth: 1,
			allowBlank: __self.allowBlank,
			hidden: true,
			listeners: {
				change: function (field, value) {
					const fileName = edi.utils.getFileNameFromPath(value);
					field.selectedFileName = fileName;
					__self._fileName.setValue(edi.utils.base64.encode(fileName));
					if (value) {
						delete field.duringFileSelect;
						__self._fileField.superclass.setValue.call(field, value.replace(/C:\\fakepath\\/g, ''));
					}
					__self.fileData = __self.convertFileInfo(__self._fileField.fileInputEl.el.dom.files[0]) || null;
					__self.valueChangedHandler();
				}
			}
		});

		__self._fileName = createHiddenField({
			name: 'filename'
		});
	},

	/**
	 * Конвертация данных файла из input в вид для отображения в UploadedFilePanel
	 * @param    {objects}     value	объект данных загруженного файла
	 */
	convertFileInfo: function (value) {
		const fileName = value?.name || '';
		const fileDate = edi.utils.formatDate(value?.lastModified, edi.constants.DATE_FORMAT.FNS) || '';
		const fileTime = edi.utils.formatDate(value?.lastModified, edi.constants.DATE_FORMAT.TIME) || '';
		const fileSize = edi.utils.formatFileSize(value?.size);

		return {
			fileName,
			fileDate,
			fileTime,
			fileSize
		};
	},

	getDropHereText: () => edi.i18n.getMessage('fileDropper.drop.file.here'),
	getDownloadBtnText: () => edi.i18n.getMessage('fileDropper.download.files'),
	getAllowedFileTypesText: () => edi.i18n.getMessage('fileDropper.allow.file.types.dsf'),
	getNeedToUploadText: () => edi.i18n.getMessage('fileDropper.need.download.file.error'),

	/**
	 * Создание drag and drop панели
	 */
	createFileDropPanel: function () {
		const __self = this;
		const loadButtonWithlabel = createContainer({
			columnWidth: 1,
			items: [
				createLabel({
					text: __self.getDropHereText(),
					cls: `${selfCls}-load-label`
				}),
				createLink({
					text: __self.getDownloadBtnText(),
					cls: `${selfCls}-load-button`,
					itemId: 'dragFileLoadButton',
					handler: () => {
						__self._fileField.fileInputEl.el.dom.click();
					}
				})
			]
		});

		const allowFileTypeLabel = createLabel({
			text: __self.getAllowedFileTypesText(),
			cls: `${selfCls}-load-allowFileType`,
			columnWidth: 1
		});

		const needUploadFile = !__self.allowBlank
			? createLabel({
					text: __self.getNeedToUploadText(),
					cls: `${selfCls}-load-needUploadFile`,
					columnWidth: 1
			  })
			: null;

		const fileDropPanel = createPanel({
			cls: `${selfCls}-container`,
			layout: {
				type: 'vbox',
				align: 'middle',
				pack: 'center'
			},
			columnWidth: 1,
			isDroppable: true,

			items: [loadButtonWithlabel, allowFileTypeLabel],
			listeners: {
				afterRender: function (view) {
					if (window.File && window.FileList && window.FileReader) {
						new Ext.drag.Target({
							element: view.el.dom,
							listeners: {
								scope: this,
								dragenter: () => {
									view.addCls('active');
								},
								dragleave: () => {
									view.removeCls('active');
								},
								drop: (target, info) => {
									view.removeCls('active');
									__self._fileField.fileInputEl.el.dom.files = info?.files;
									__self._fileField.onFileChange(null, null, info?.files[0].name);
								}
							}
						});
					}
				}
			}
		});

		__self._fileDropPanel = createContainer({
			layout: {
				type: 'vbox',
				pack: 'start',
				align: 'stretch'
			},
			style: {
				float: __self.float
			},
			columnWidth: 1,

			items: [fileDropPanel, needUploadFile]
		});
	},

	/**
	 * Template просмотра данных файла (UploadedFilePanel)
	 */
	fileViewTemplate: `
		<div class="file-info">
			<tpl if="!!values.fileName">
				<span class="file-info-name">{fileName}</span>
			</tpl>
			<div class="file-info-wrapper">
				<tpl if="!!values.validationError">	
					<span class="file-info-error">{validationError}</span>
				<tpl else>
					<tpl if="!!values.fileDate">
						<span class="file-info-fileDate">{fileDate}</span>
					</tpl>
					<tpl if="!!values.fileTime">
						<span class="file-info-fileTime">{fileTime}</span>
					</tpl>
					<tpl if="!!values.fileSize">
						<span class="file-info-fileSize">{fileSize}</span>
					</tpl>
				</tpl>
			</div>
		</div>
	`,

	/**
	 * удаление данных fileData и отчистка input
	 * @param    {boolean}     fireChange	вызов события смены значения
	 */
	clearFileData: function (fireChange) {
		const __self = this;
		__self?._fileField?.reset();
		__self.fileData = {};
		__self._fileName?.setValue(null);
		fireChange ? __self?.valueChangedHandler() : null;
	},

	/**
	 * установка значения в панель просмотра данных файла (uploadedFilePanel)
	 */
	setUploadedFileData: function () {
		const __self = this;
		const uploadedFileLabel = __self._uploadedFilePanel?.down("[itemId ='uploadedFileLabel']");
		uploadedFileLabel?.setHtml(new Ext.XTemplate(__self.fileViewTemplate).apply(__self.fileData));
	},

	/**
	 * Создание панели просмотра данных файла
	 */
	createUploadedFilePanel: function () {
		const __self = this;

		const fileInfo = createLabel({
			itemId: 'uploadedFileLabel',
			html: new Ext.XTemplate(__self.fileViewTemplate).apply(__self.fileData)
		});

		const fileClearBtn = !__self.readOnly
			? createButton({
					cls: [BUTTON_CLS.light, BUTTON_CLS.small, 'clear-btn'],
					glyph: edi.constants.ICONS.CLOSE,
					handler: () => {
						__self.clearFileData(true);
					}
			  })
			: null;

		const uploadedFilePanel = createPanel({
			columnWidth: 1,
			style: {
				float: __self.float
			},
			cls: `${selfCls}-uploaded-file`,
			items: [fileInfo, fileClearBtn]
		});

		__self._uploadedFilePanel = createContainer({
			layout: {
				type: 'vbox',
				pack: 'start',
				align: 'stretch'
			},
			style: {
				float: __self.float
			},
			columnWidth: 1,

			items: [uploadedFilePanel]
		});
	},

	getMaxSizeError: function (currentSize, maxSize) {
		const __self = this;
		const currentValue = edi.utils.formatFileSize(currentSize);
		const maxValue = edi.utils.formatFileSize(maxSize);
		return edi.i18n.getMessage(__self.maxFileSizeErrorKey, [currentValue, maxValue]);
	},

	fileSizeValidator: function () {
		const __self = this;
		let errorText = '';
		const file = __self._fileField.fileInputEl?.el?.dom?.files?.[0];
		const maxSize = __self.maxFileSize * 1024 * 1024; //размер в МБ
		if (file?.size && maxSize && __self.checkMaxFileSize && file.size > maxSize) {
			errorText = __self.getMaxSizeError(file.size, maxSize);
		}
		return errorText;
	},

	fileValidator: function () {
		return '';
	},

	/**
	 * установка allowBlank для fileField
	 */
	setAllowBlank: function () {
		const __self = this;
		const hasData = !(edi.utils.isEmptyObject(__self.fileData) || Ext.isEmpty(__self.fileData));

		if (!__self.allowBlank) {
			__self._fileField.allowBlank = !!hasData;
			__self._fileField?.isValid();
			__self.markInvalid(!hasData);
		}
	},

	/**
	 * переключение invalidClass у drag & drop панели
	 * @param    {boolean}     invalid
	 */
	markInvalid: function (invalid) {
		const __self = this;

		if (invalid) {
			__self._fileDropPanel.addCls(__self.invalidClass);
		} else {
			__self._fileDropPanel.removeCls(__self.invalidClass);
		}
	},

	/**
	 * переключение invalidClass у панели с загруженным файлом
	 * @param    {boolean}     invalid
	 */
	markUploadedPanelInvalid: function (invalid) {
		const __self = this;

		if (invalid) {
			__self._uploadedFilePanel.addCls(__self.invalidClass);
		} else {
			__self._uploadedFilePanel.removeCls(__self.invalidClass);
		}
	},

	/**
	 * переключение readOnlyClass у всей панели
	 */
	markReadOnly: function () {
		if (this.readOnly && !this.hasCls(this.readOnlyClass)) {
			this.addCls(this.readOnlyClass);
		} else if (!this.readOnly && this.hasCls(this.readOnlyClass)) {
			this.removeCls(this.readOnlyClass);
		}
	}
});

/**
 * создание экземляра file.dropper
 * @param	{Object}	cfg
 * @return	{Object}	экземляр file.dropper
 */
const createFileDropper = function (cfg) {
	return Ext.create('UI.components.FileDropper', cfg);
};

export { createFileDropper };
