import { createTabPanel as createTabPnl, createTab, TAB_PANEL_CLS } from '@UIkit/components/tab';

/**
 * Creates new panel object based on passed params
 * @param	{Object}	[properties]	ExtJs panel options
 * @returns	{Object}	Ext.Panel instance
 */
const createPanel = function (properties) {
	properties = 'object' == typeof properties ? properties : {};
	Ext.applyIf(properties, edi.constants.DEFAULT.PANEL);
	return new Ext.Panel(properties);
};

/**
 * Creates a form
 * @param	{Object}	[config]	config options to pass to Ext.form.Panel
 * @returns	{Object}	Ext.form.Panel instance
 */
const createForm = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		defaultType: 'textfield'
	};
	Ext.applyIf(config, defaults);
	return new Ext.form.Panel(config);
};

/**
 * Creates new tab panel instance
 * @param	{Object}	[properties]	tab panel config options
 * @param	{Boolean}	[isModulePanel]	true to use module panel defaults
 * @returns	{Object}	Ext.tab.Panel instance
 */
const createTabPanel = function (properties, isModulePanel) {
	properties = 'object' == typeof properties ? properties : {};
	let defaults = isModulePanel
		? {
				autoDestroy: true,
				region: 'center',
				layout: 'fit',
				flex: 1
		  }
		: {
				deferredRender: false,
				autoDestroy: true,
				flex: 1
		  };
	Ext.applyIf(properties, defaults);
	return createTabPnl(properties);
};

/**
 * Creates module panel
 * @param	{Object}	[config]	options
 * @returns	{Object}	Ext.Panel instance
 */
const createModulePanel = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		cls: 'edi-module-panel',
		bodyCls: 'edi-module-panel-body'
	};
	Ext.applyIf(config, defaults);
	return createPanel(config);
};

Ext.define('UI.components.ModuleForm', {
	extend: 'Ext.form.Panel',
	alias: 'widget.module-form',
	cls: '',
	selfCls: 'ui-module-form',

	submitEmptyText: false,
	bodyPadding: 24,
	autoScroll: true,
	layout: {
		type: 'grid',
		gap: [24, 16]
	},
	buttonAlign: 'left',

	constructor(externalCfg) {
		const modifiedConfig = this.modifyConfig(externalCfg);
		this.callParent([modifiedConfig]);
	},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	modifyConfig: function (cfg) {
		return Ext.merge({}, cfg);
	},

	beforeInit: function () {},

	afterInit: function () {
		this.addCls(this.selfCls);
	}
});

const createFormForModule = function (cfg) {
	return Ext.create('UI.components.ModuleForm', cfg);
};

/**
 * Creates module panel with layout of new documents
 * @param	{Object}	[config]	options
 * @returns	{Object}	Ext.Panel instance
 */
const createAddModulePanel = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		region: 'center',
		layout: 'fit',
		border: 0,
		cls: 'edi-module-add'
	};
	Ext.applyIf(config, defaults);
	return createPanel(config);
};

/**
 * Creates module panel with layout of document details
 * @param	{Object}	[config]	options
 * @returns	{Object}	Ext.Panel instance
 */
const createDetailsModulePanel = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		autoScroll: true,
		region: 'center',
		layout: 'fit',
		cls: 'edi-module-details'
	};
	Ext.applyIf(config, defaults);
	return createPanel(config);
};

/**
 * Creates panel for user actions like filtering data, print, close etc.
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.Panel instance
 */
const createActionsPanel = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		region: 'north',
		cls: 'edi-module-actions'
	};
	Ext.applyIf(config, defaults);
	return createPanel(config);
};

export {
	createPanel,
	createForm,
	createTabPanel,
	createTab,
	TAB_PANEL_CLS,
	createModulePanel,
	createAddModulePanel,
	createDetailsModulePanel,
	createActionsPanel,
	createFormForModule
};
