import { createNumberField, createTextField } from '@Components/fields';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { createPanel } from '@Components/panels';

/**
 * Creates modal window for catalog selection
 * @param	{Number}	orgId
 * @param	{Function}	[callback]
 * @param	{String}	[type]
 * @param	{Boolean}	[isOwnCatalog]
 * @returns	{Object}	Ext.window.Window instance
 */
const createModalCatalogSelect = function (orgId, callback, type, isOwnCatalog) {
	type = type ? type : 'delcat';
	var toOrgId = isOwnCatalog ? orgId : edi.core.getUserOrgID(),
		fromOrgId = isOwnCatalog ? edi.core.getUserOrgID() : orgId,
		url = edi.utils.formatString(
			edi.rest.services.CATALOGS.V2.RECORDS.GET,
			{
				type: type,
				fromOrgId: fromOrgId,
				toOrgId: type !== edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT ? fromOrgId : toOrgId
			},
			true
		),
		isFirstLoad = true;
	const formItemsMap = {
		iln: createNumberField({
			fieldLabel: edi.i18n.getMessage('company.gln'),
			allowDecimals: false,
			name: 'iln'
		}),
		name: createTextField({
			fieldLabel: edi.i18n.getMessage('company.name'),
			name: 'name'
		})
	};
	var modal = createModalRemoteSelect(url, undefined, {
		storeConfig: {
			model: edi.models.getModel('ORGANIZATION_CATALOG'),
			sortOnLoad: true,
			sorters: {
				property: 'name',
				direction: 'ASC'
			},
			listeners: {
				load: function (store) {
					if (isFirstLoad && !store.getCount()) {
						edi.core.showInfo(
							edi.i18n.getMessage(type === 'delcat' ? 'info.no.delcat' : 'info.no.loccat'),
							function () {
								modal.close();
							}
						);
					}
					isFirstLoad = false;
				}
			},
			autoLoad: false
		},
		gridConfig: {
			columns: edi.columns.get('organization_catalog'),
			listeners: {
				select: function (comp, record) {
					'function' == typeof callback ? callback(edi.converters.convertCatToOrg(record.getData())) : null;
					modal.close();
				}
			}
		},
		hideSaveButton: true,
		createFormItemsMap: formItemsMap,
		createFilterFormItems: [
			createPanel({
				layout: {
					type: 'grid',
					area: [[6, 6]]
				},
				items: [formItemsMap.iln, formItemsMap.name]
			})
		],
		createArgs: edi.filters.config.generic.createArgs,
		cls: 'edi-modal-catalog-selector',
		title: edi.i18n.getMessage(type === 'delcat' ? 'document.delcat.select.title' : 'document.loccat.select.title')
	});
	return modal;
};

export { createModalCatalogSelect };
