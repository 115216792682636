/**
 * Creates simple store
 * @param	{Object}	[properties]	store config options
 * @returns	{Object}	Ext.data.Store instance
 */
const createSimpleStore = function (properties) {
	properties = 'object' == typeof properties ? properties : {};
	var defaults = {
		model: edi.models.getModel('SIMPLE')
	};
	Ext.applyIf(properties, defaults);
	return new Ext.data.Store(properties);
};

/**
 * Creates new proxy based store object based on passed params
 * @param	{Object}	[properties]	store config options
 * @returns	{Object}	Ext.data.Store instance
 */
const createStore = function (properties) {
	properties = 'object' == typeof properties ? properties : {};
	var defaults = {
		pageSize: edi.constants.ITEMS_PER_PAGE,
		proxy: createProxyConfig(),
		autoLoad: true
	};
	Ext.applyIf(properties, defaults);
	return new Ext.data.Store(properties);
};

/**
 * Creates proxy config used by store
 * @param	{Object}	[properties]	proxy config object
 * @returns	{Object}	proxy config object
 */
const createProxyConfig = function (properties) {
	properties = 'object' == typeof properties ? properties : {};
	var defaults = {
		type: 'memory',
		reader: {
			type: 'json',
			rootProperty: edi.constants.LIST_ROOT_PROPERTY,
			totalProperty: edi.constants.LIST_TOTAL_PROPERTY
		},
		listeners: {
			exception: function (proxy, response) {
				var showError = function (data) {
					edi.core.logMessage(
						'Error getting data. status: ' + response.status + ' url: ' + proxy.url,
						'warn'
					);
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
				};
				if (!response || response.statusText != 'transaction aborted') {
					if (proxy && proxy.reader && proxy.reader.rawData && proxy.reader.rawData.status) {
						showError(proxy.reader.rawData);
					} else {
						edi.rest.processResponse(response, showError, showError, {
							suppressDefaultError: true
						});
					}
				}
			}
		}
	};
	Ext.applyIf(properties, defaults);
	return properties;
};

export { createSimpleStore, createStore, createProxyConfig };
