import {
	createContainer,
	createFieldSetFromConfig,
	createTwoColumnsLayout,
	createTwoColumnsLayoutFromConfig,
	createContainerFromConfig,
	createFieldBlockFromConfig
} from './miscComponents.js';
import { createLink } from './buttons';
import { createPanel } from './panels';
import {
	createTextField,
	createDateField,
	createNumberField,
	createTriggerField,
	createPasswordField,
	createHiddenField,
	createCombo,
	createCheckbox as createChckbx,
	createRadio,
	createTagTextField,
	createDetailLabel,
	createDetailDateLabel,
	createFileField,
	createTimeField,
	createCompositeInput
} from '@UIkit/components/fields';
import { createLabel } from '@UIkit/components/fields_old/Label';
import { createFieldBlock, createFieldSet, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';

/**
 * Creates a labeled group of fields having default width
 * @param	{Object}    [config]    config for new Ext.form.FieldContainer
 * @returns	{Object}	Ext.form.FieldContainer instance
 */
const createFieldContainer = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		labelWidth: 150
	};
	Ext.applyIf(config, defaults);
	return new Ext.form.FieldContainer(config);
};

/**
 * Creates a text field with email address validation
 * @param	{Object}	[config]	config for new createTextField()
 * @returns	{Object}	Ext.form.field.Text or Ext.form.field.TextArea instance
 */
const createEmailField = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		regex: edi.constants.VALIDATORS.EMAIL,
		maxLength: 175,
		invalidText: edi.i18n.getMessage('invalid.email.format')
	};
	Ext.applyIf(config, defaults);
	return createTextField(config);
};

/**
 * Creates combobox with OKEI store
 * @param	{Object}	[config]	config for new createCombo()
 * @returns	{Object}	Ext.form.field.Combo
 */
const createOkeiField = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		title: edi.i18n.getMessage('line.item.unit.of.measure'),
		name: 'UnitOfMeasure',
		store: edi.stores.initLegacyOkeiStore(),
		valueField: 'name_international',
		displayField: 'name_code',
		forceSelection: false,
		anyMatch: true,
		allowManualInput: true,
		valueInitialize: true,
		editable: false,
		triggers: {
			clear: {
				glyph: edi.constants.ICONS.CLOSE,
				tooltip: edi.i18n.getMessage('uikit.action.reset'),
				handler: function (cmp) {
					cmp.setValue(null);
				}
			}
		},
		//@Override
		//т.к. в комбик может приехать значение не из стора, то для нормального отображения
		//необходимо дозаполнить пустые поля в объекте единицы измерения
		getDisplayValue: function (tplData) {
			const cmp = this;
			tplData = tplData || cmp.displayTplData;
			if (Array.isArray(tplData)) {
				tplData.forEach((item) => {
					Object.entries(item).forEach(([key, value]) => {
						if (value === null || value === 'null') {
							item[key] = item[cmp.valueField];
						}
					});
				});
			}
			let s = cmp.getDisplayTpl().apply(tplData);
			s = s == null ? '' : String(s);
			// The display field may have newlines characters, but the raw value in
			// the field will not because they will be automatically stripped, so do
			// the same here for the sake of comparison.
			return s.replace(cmp.newlineRe, '');
		},
		findAndSetValue: function (val) {
			let num = this.getStore().findBy(function (record) {
				let row = record.getData();
				val = val?.toUpperCase();
				return row.name_international.toUpperCase() === val || row.name.toUpperCase() === val;
			});
			if (num < 0) {
				return false;
			} else {
				this.setValue(this.getStore().getAt(num).get('name_international'));
				return true;
			}
		}
	};
	Ext.applyIf(config, defaults);
	return createCombo(config);
};

/**
 * Creates a text field with phone number validation
 * @param	{Object}	[config]	config for new createTextField()
 * @returns	{Object}	Ext.form.field.Text or Ext.form.field.TextArea
 */
const createPhoneField = function (config) {
	config = 'object' == typeof config ? config : {};

	const userOrgCountry = config.orgCountry ?? edi.core.getUserData()?.org?.country;

	const getConfigByCountry = (country) => {
		switch (country) {
			case 'RUS':
				// EW start
				return edi.constants.IS_EDIWEB_CLIENT
					? {
							regex: edi.constants.VALIDATORS.PHONE,
							inputMask: edi.constants.FIELD_MASK.PHONE,
							emptyText: edi.i18n.getMessage('general.phone.format', ['+7 xxx xxx-xx-xx']),
							invalidText: edi.i18n.getMessage('general.phone.format', ['+7 xxx xxx-xx-xx'])
							// EW end
					  }
					: {
							regex: /^[+]7\d{10}$/,
							emptyText: edi.i18n.getMessage('general.phone.format', ['+7xxxxxxxxxx']),
							invalidText: edi.i18n.getMessage('general.phone.format', ['+7xxxxxxxxxx'])
					  };
			default:
				return {
					regex: /^[+]\d{1,15}$/,
					emptyText: edi.i18n.getMessage('general.phone.format', ['+XXXXXXXXXXXXXXX']),
					invalidText: edi.i18n.getMessage('general.phone.format', ['+XXXXXXXXXXXXXXX'])
				};
		}
	};

	let defaults = {
		...getConfigByCountry(userOrgCountry)
	};
	Ext.applyIf(config, defaults);
	return createTextField(config);
};

/**
 * Set input required fields (mutate conf)
 * @param	{Object}	conf	config with requiredFields and listeners objects inside
 */
const setRequiredFields = function (conf) {
	if (conf.requiredFields && conf.requiredFields.length) {
		let originalListener;
		if (conf.listeners && conf.listeners.change) {
			originalListener = conf.listeners.change;
		}
		let checkRequired = function (comp, form) {
			if (comp.requiredFields && comp.requiredFields.length) {
				let fieldValue = comp.getValue();
				let hasValue = Array.isArray(fieldValue) ? !!fieldValue.length : !!fieldValue;

				let fields = edi.utils.getFormFields(form);
				for (let i = 0; i < comp.requiredFields.length; i++) {
					let field = fields[comp.requiredFields[i]];
					if (field) {
						field.conditional = true;
						if (hasValue) {
							field.isRequired = true;
						}
					}
				}
			}
		};
		Ext.merge(conf, {
			listeners: {
				change: function (comp, newValue, oldValue, eOpts) {
					originalListener ? originalListener(comp, newValue, oldValue, eOpts) : null;
					let form = comp.up('form');
					if (form) {
						let fields = edi.utils.getFormFields(form);
						for (let i in fields) {
							if (fields.hasOwnProperty(i)) {
								checkRequired(fields[i], form);
							}
						}
						for (let i in fields) {
							if (fields.hasOwnProperty(i)) {
								let field = fields[i];
								if (field.conditional) {
									field.allowBlank = !field.isRequired;
									field.isRequired = undefined;
								}
							}
						}
						form.isValid();
					}
				}
			}
		});
	}
};

Ext.override(UI.components.TagTextField, {
	modifyConfig: function (cfg) {
		const cleanedCfg = Ext.merge({}, cfg);
		setRequiredFields(cleanedCfg);
		return this.callParent([cleanedCfg]);
	}
});

Ext.override(UI.components.TextField, {
	modifyConfig: function (cfg) {
		const cleanedCfg = Ext.merge({}, cfg);
		setRequiredFields(cleanedCfg);
		return this.callParent([cleanedCfg]);
	}
});

Ext.override(UI.components.TextAreaField, {
	modifyConfig: function (cfg) {
		const cleanedCfg = Ext.merge({}, cfg);
		setRequiredFields(cleanedCfg);
		return this.callParent([cleanedCfg]);
	}
});

Ext.override(UI.components.ComboboxField, {
	modifyConfig: function (cfg) {
		const cleanedCfg = Ext.merge({}, cfg);
		setRequiredFields(cleanedCfg);
		return this.callParent([cleanedCfg]);
	}
});

Ext.override(UI.components.DateField, {
	constructor: function (cfg) {
		const cleanedCfg = Ext.merge({}, cfg);
		setRequiredFields(cleanedCfg);
		return this.callParent([cleanedCfg]);
	}
});

Ext.override(UI.components.TimeField, {
	constructor: function (cfg) {
		const cleanedCfg = Ext.merge({}, cfg);
		setRequiredFields(cleanedCfg);
		return this.callParent([cleanedCfg]);
	}
});

/**
 * Crates display field
 * @param	{Object}	[config]	config for new Ext.form.field.Display
 * @returns	{Object}	Ext.form.field.Display instance
 */
const createDisplayField = function (config) {
	config = 'object' == typeof config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	let defaults = {};
	Ext.applyIf(config, defaults);
	if (valueSrc && config.name) {
		config.value = edi.utils.getObjectProperty(valueSrc, config.name);
	}
	return new Ext.form.field.Display(config);
};

/**
 * Creates a wrapper panel consisting labeled input field
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.Panel instance
 */
const createInput = function (config) {
	config = config ? config : {};
	let items = [];
	let title = config.title ? edi.i18n.getMessage(config.title) : '';
	let input = config.input;
	let labelConf = 'object' == typeof config.labelConf ? config.labelConf : {};
	let panelConf = 'object' == typeof config.panelConf ? config.panelConf : {};
	//panelConf.getTitle = function() {
	//	return title;
	//};
	if (!input) {
		edi.core.logMessage('No input defined: ' + title, 'warn');
	}
	Ext.applyIf(labelConf, {
		html: edi.i18n.getMessage(title),
		margin: '5 0 0',
		columnWidth: 0.3,
		forId: input && 'function' == typeof input.getId ? input.getId() : null
	});
	items.push(createLabel(labelConf));
	items.push(input);
	Ext.applyIf(panelConf, {
		layout: 'column',
		margin: '0 0 5'
	});
	panelConf.items = items;
	let panel = createPanel(panelConf);
	panel.input = input;
	return panel;
};

/**
 * Creates pair of field/label according to passed layout type
 * @param	{Object}					inputConfig		config of pair {title, input, containerConfig}
 * @param	{"horisontal"|"vertical"}	[type]			type of label (default is "vertical")
 * @returns	{Object}	instance of Ext.form.FieldContainer
 */
const createField = function (inputConfig, type) {
	let input = inputConfig.input;
	let infoText = edi.utils.safeString(inputConfig?.infoText || inputConfig?.input?.infoText);
	let title = inputConfig.title;
	let labelConf = inputConfig.labelConf || {};
	let useFieldLabel = !!inputConfig.useFieldLabel;
	let containerConfig = inputConfig.containerConfig ? inputConfig.containerConfig : {};
	let containerDefault = {
		layout: 'column',
		getTitle() {
			return title;
		},
		getInput() {
			return input;
		}
	};
	Ext.applyIf(containerConfig, containerDefault);
	let label = null;
	let container = {};
	let iconInfo;

	if (useFieldLabel) {
		container = createFieldContainer(
			Ext.applyIf(inputConfig.fieldContainerConfig || {}, {
				fieldLabel: edi.i18n.getMessage(title),
				layout: 'column',
				items: [input],
				cls: 'edi-form-field'
			})
		);
		container.getInput = function () {
			return input;
		};
		container.getTitle = function () {
			return title;
		};
	} else if ('horisontal' === type) {
		if (title) {
			label = createLabel(
				Ext.applyIf(labelConf, {
					html: edi.i18n.getMessage(title),
					cls: 'edi-fieldset-label',
					forId: input && 'function' == typeof input.getId ? input.getId() : null
				})
			);
		}
		container.label = label;
		containerConfig.items = [label, input];
		containerConfig.layout = 'form';
		container = createFieldContainer(containerConfig);
	} else {
		if (title) {
			let colWidth =
				inputConfig.input && parseFloat(inputConfig.input.columnWidth)
					? 1 - parseFloat(inputConfig.input.columnWidth)
					: 0.5;
			if (infoText) {
				input.columnWidth = inputConfig.input.columnWidth - 0.1;
				input.updateLayout();
				iconInfo = createButton({
					cls: [BUTTON_CLS.light, BUTTON_CLS.small],
					columnWidth: 0.1,
					width: undefined,
					glyph: edi.constants.ICONS.INFO,
					tooltip: infoText
				});
			}
			label = createLabel(
				Ext.applyIf(labelConf, {
					columnWidth: colWidth,
					cls: 'edi-fieldset-label',
					html: edi.i18n.getMessage(title),
					forId: input && 'function' == typeof input.getId ? input.getId() : null
				})
			);
		}
		containerConfig.items = [label, input];
		if (iconInfo) {
			containerConfig.items.push(iconInfo);
		}
		container = createFieldContainer(containerConfig);
	}
	container.label = label;
	if (inputConfig.hidden) {
		container.hide();
	}
	if (input) {
		input.getField = function () {
			return container;
		};
	}
	return container;
};

/**
 * Create array of fields using items array with input configurations
 * @param	{Object[]}		items			array of items configs
 * @param	{Object}	[data]			dta source for fields values
 * @param	{Object}	[fieldConf]
 * @param	{Object}	[inputConf]
 * @returns	{Object[]}	Array of fields
 */
const createFields = function (items, data, fieldConf, inputConf) {
	fieldConf = fieldConf ? fieldConf : {};
	Ext.applyIf(fieldConf, {
		columnWidth: 1
	});
	inputConf = inputConf ? inputConf : {};
	Ext.applyIf(inputConf, {
		columnWidth: 0.5,
		type: 'text',
		readOnly: false,
		valueSrc: data
	});

	let res = [];
	items.forEach((item) => {
		if (!item) {
			return;
		}
		let conf = {};
		Ext.applyIf(conf, item);
		Ext.applyIf(conf, inputConf);
		setRequiredFields(conf);
		let type = conf.type;
		delete conf.type;
		let inputMethod;

		//To prevent disabling readonly field pass conf.disableIfReadonly: false
		if (conf.readOnly && !(conf.hasOwnProperty('disableIfReadonly') && !conf.disableIfReadonly)) {
			conf.disabled = true;
		}

		if (type === 'checkbox') {
			conf.checked = !!edi.utils.getObjectProperty(data || {}, conf.name);
		} else if (type === 'twoColumnsLayout') {
			conf.inputConfig = inputConf;
		}

		if ('function' == typeof type) {
			inputMethod = type;
		} else if (
			(conf.readOnly || type === 'label') &&
			type !== 'combo' &&
			type !== 'twoColumnsLayout' &&
			type !== 'fieldset' &&
			type !== 'deliveryGrid' &&
			type != 'container'
		) {
			if (undefined === conf.valueLabel) {
				conf.valueLabel = true;
			}
			inputMethod = type === 'date' ? createDateLabel : createLabel;
		} else {
			inputMethod = getInputMethodByType(type);
		}

		let field =
			'function' == typeof type ||
			type === 'hidden' ||
			type === 'twoColumnsLayout' ||
			type === 'fieldset' ||
			type === 'deliveryGrid'
				? inputMethod(conf, data)
				: createField(
						Ext.applyIf(
							{
								title: edi.i18n.getMessage(conf.title || 'column.' + edi.utils.formatName(conf.name)),
								input: inputMethod(
									Object.assign(conf, {
										regexText: conf.regexText ? edi.i18n.getMessage(conf.regexText) : ''
									})
								),
								mandatory: !conf.allowBlank && undefined !== conf.allowBlank
							},
							fieldConf
						)
				  );

		if (conf.hasOwnProperty('isHidden')) {
			field.hidden =
				'function' == typeof conf.isHidden
					? conf.isHidden(field, data, data ? data[conf.name] : null)
					: !!conf.isHidden;
		}
		res.push(field);
	});

	return res;
};

/**
 * Creates a file upload field
 * @param	{Object}    [containerConfig]		config options for container
 * @param	{Object}    [config]				config options for file field
 * @param	{Object}    [hiddenConfig]			config options for hidden field
 * @returns	{Object}	Ext.form.field.File		instance
 */
const createFile = function (containerConfig, config, hiddenConfig) {
	config = 'object' == typeof config ? config : {};
	hiddenConfig = 'object' == typeof hiddenConfig ? hiddenConfig : {};
	containerConfig = 'object' == typeof containerConfig ? containerConfig : {};

	let passedChange, field, hidden;
	let defaults = {
		//xtype: 'filefield',
		buttonText: edi.i18n.getMessage('document.upload.select'),
		//anchor: '100%',
		cls: 'edi-file-field',
		focusable: false
	};
	Ext.applyIf(config, defaults);

	if (!config.listeners) {
		config.listeners = {};
	}
	if (config.listeners.change) {
		passedChange = config.listeners.change;
	}
	if (!hiddenConfig.disable) {
		config.listeners.change = function (field, value, eOpts) {
			field.selectedFileName = edi.utils.getFileNameFromPath(value);
			hidden.setValue(edi.utils.base64.encode(field.selectedFileName));
			if (value) {
				delete field.duringFileSelect;
				Ext.form.field.File.superclass.setValue.call(field, value.replace(/C:\\fakepath\\/g, ''));
			}
			'function' == typeof passedChange ? passedChange(field, value, eOpts) : null;
		};
		let hiddenDefaults = {
			name: 'filename'
		};
		Ext.applyIf(hiddenConfig, hiddenDefaults);
		hidden = createHiddenField(hiddenConfig);
	}

	field = createFileField(config);
	let originalReset = field.reset;
	field.reset = function () {
		originalReset.apply(field, arguments);
		hidden?.setValue(null);
		'function' == typeof passedChange ? passedChange(field) : null;
	};

	let containerDefaults = {
		layout: 'anchor'
	};

	Ext.applyIf(containerConfig, containerDefaults);
	containerConfig.items = [field, hidden];
	let panel = createPanel(containerConfig);
	panel.fileField = field;

	return panel;
};

/**
 * Creates a single checkbox field
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.form.field.Checkbox instance
 */
const createCheckbox = function (config) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		text: ''
	};
	Ext.applyIf(config, defaults);
	if (!config.hasOwnProperty('boxLabel')) {
		config.boxLabel = config.text;
	}

	return createChckbx(config);
};

/**
 * Creates a date label element to be associated with a field element on a form
 * @param	{Object}	[config]	config options
 * @returns	{Object}	Ext.form.Label instance
 */
const createDateLabel = function (config) {
	config = config ? config : {};
	let valueSrc = config.valueSrc;
	delete config.valueSrc;
	if (valueSrc && config.name) {
		let dateVal = edi.utils.getObjectProperty(valueSrc, config.name),
			isSrcString = config.typeDate && config.typeDate === 'string';
		config.text = isSrcString ? dateVal : parseInt(dateVal, 10);
	}
	if (config.text) {
		let dateFormat = config.dateFormat || edi.constants.DATE_FORMAT.FNS;
		let srcFormat = config.srcFormat || edi.constants.DATE_FORMAT.CLIENT;
		if (dateFormat !== srcFormat) {
			config.text = edi.utils.formatDate(config.text, dateFormat, srcFormat);
		}
	}
	return createLabel(config);
};

/**
 * Creates container panel for filtering grid by custom or predefined date range
 * @param	{Object}	[config]				config options
 * @param	{Array}		[showConditions]		collection of conditions for inclusion in the condition combobox
 * @param	{Boolean}	[isFieldContainer]	true if we need to render field container
 * @param	{Function}	[validator]			method validate field and condition type
 * @param	{Function}	[constructValueName]	method construct ids for conditions list
 * @returns	{Object}	instance of Ext.form.FieldContainer
 */
const createSearchTypeSelectionField = function (
	config,
	showConditions,
	isFieldContainer,
	validator,
	constructValueName
) {
	config = 'object' === typeof config ? config : {};
	showConditions =
		Array.isArray(showConditions) && showConditions.length ? showConditions : ['exact', 'LikeRight', 'LikeBoth'];
	let field, comboBox;
	let fieldConf = Ext.applyIf(config.fieldConf ? config.fieldConf : {}, {
		name: 'docName',
		validator:
			'function' == typeof validator
				? function () {
						return validator(comboBox, field);
				  }
				: undefined
	});
	let condsConfig = [];

	for (let i = 0; i < showConditions.length; i++) {
		if ('string' == typeof showConditions[i]) {
			condsConfig.push({
				id:
					'function' == typeof constructValueName
						? constructValueName(fieldConf.name, showConditions[i])
						: fieldConf.name + (showConditions[i] !== 'exact' ? showConditions[i] : ''),
				name: edi.i18n.getMessage('filter.form.search.type.' + edi.utils.formatName(showConditions[i]))
			});
		}
	}

	let twoColConf = Ext.applyIf(config.twoColConf ? config.twoColConf : {}, {
		columnWidth: 1,
		items1Conf: {
			style: 'padding-left: 0!important;'
		},
		items2Conf: {
			style: 'padding-right: 0!important;'
		}
	});
	let comboStore = config.comboStore ? config.comboStore : edi.stores.createInlineStore(condsConfig, 'SIMPLE');
	let comboConf = Ext.applyIf(config.comboConf ? config.comboConf : {}, {
		store: comboStore,
		name: fieldConf.name + 'Val',
		allowBlank: false,
		autoSelect: true,
		value: condsConfig.length ? condsConfig[0].id : null,
		ignoreAutoFilter: true,
		ignoreChips: true,
		anyMatch: true,
		margin: '0 5 0 0',
		filterListeners: null,
		validator:
			'function' == typeof validator
				? function () {
						return validator(comboBox, field);
				  }
				: undefined,
		listeners: {
			select(combo) {
				if (field.getValue()) {
					let form = combo.up('form');
					let autoSearchCheckbox = form.down("checkbox[name='filterFormAutoSearchCheckbox']");
					if (
						form &&
						'function' == typeof form.fireSearch &&
						(!autoSearchCheckbox || !!autoSearchCheckbox.getValue())
					) {
						form.fireSearch();
					}
				}
				if (typeof config.onSelect === 'function') {
					config.onSelect(combo, field);
				}
			}
		},
		showQtips: true
	});
	let twoColSize = config.twoColSize ? config.twoColSize : 0.25;
	let containerConf;
	let createMethod = createField;
	let fieldCreateMethod = createTextField;
	if (fieldConf.type) {
		fieldCreateMethod = getInputMethodByType(fieldConf.type);
	}
	if (isFieldContainer) {
		containerConf = Ext.applyIf(config.containerConf ? config.containerConf : {}, {
			fieldLabel: edi.i18n.getMessage('column.doc.name'),
			items: [
				createTwoColumnsLayout(
					[(comboBox = createCombo(comboConf))],
					[(field = fieldCreateMethod(fieldConf))],
					twoColSize,
					twoColConf
				)
			]
		});
		createMethod = createFieldContainer;
	} else {
		containerConf = Ext.applyIf(config.containerConf ? config.containerConf : {}, {
			title: edi.i18n.getMessage('column.doc.name'),
			input: createTwoColumnsLayout(
				[(comboBox = createCombo(comboConf))],
				[(field = fieldCreateMethod(fieldConf))],
				twoColSize,
				twoColConf
			),
			useFieldLabel: true
		});
	}
	return createMethod(containerConf);
};

const createSearchTypeSelectionFieldForGrid = function (
	config,
	showConditions,
	isFieldContainer,
	validator,
	constructValueName
) {
	config = 'object' === typeof config ? config : {};
	showConditions =
		Array.isArray(showConditions) && showConditions.length ? showConditions : ['exact', 'LikeRight', 'LikeBoth'];
	let field, comboBox;
	let fieldConf = Ext.applyIf(config.fieldConf ? config.fieldConf : {}, {
		name: 'docName',
		validator:
			'function' == typeof validator
				? function () {
						return validator(comboBox, field);
				  }
				: undefined
	});
	let condsConfig = [];

	for (let i = 0; i < showConditions.length; i++) {
		if ('string' == typeof showConditions[i]) {
			condsConfig.push({
				id:
					'function' == typeof constructValueName
						? constructValueName(fieldConf.name, showConditions[i])
						: fieldConf.name + (showConditions[i] !== 'exact' ? showConditions[i] : ''),
				name: edi.i18n.getMessage('filter.form.search.type.' + edi.utils.formatName(showConditions[i]))
			});
		}
	}

	if (config.addEmptyCondition) {
		let emptyRecord = {
			id: '',
			name: edi.i18n.getMessage('form.combo.not.selected')
		};
		condsConfig = [emptyRecord, ...condsConfig];
	}

	let label = config.fieldLabel;
	delete config.fieldLabel;

	let comboStore = config.comboStore ? config.comboStore : edi.stores.createInlineStore(condsConfig, 'SIMPLE');
	let comboConf = Ext.applyIf(config.comboConf ? config.comboConf : {}, {
		fieldLabel: label,
		store: comboStore,
		name: fieldConf.name + 'Val',
		editable: false,
		autoSelect: true,
		value: condsConfig.length ? condsConfig[0].id : null,
		ignoreAutoFilter: true,
		ignoreChips: true,
		anyMatch: true,
		filterListeners: null,
		validator:
			'function' == typeof validator
				? function () {
						return validator(comboBox, field);
				  }
				: undefined,
		listeners: {
			select(combo) {
				if (field.getValue()) {
					let form = combo.up('form');
					let autoSearchCheckbox = form.down("checkbox[name='filterFormAutoSearchCheckbox']");
					if (
						form &&
						'function' == typeof form.fireSearch &&
						(!autoSearchCheckbox || !!autoSearchCheckbox.getValue())
					) {
						form.fireSearch();
					}
				}
				if (typeof config.comboConf?.onSelect === 'function') {
					config.comboConf.onSelect(combo, combo.nextSibling());
				}
			}
		},
		showQtips: true
	});

	let fieldCreateMethod = createTextField;
	if (fieldConf.type) {
		fieldCreateMethod = getInputMethodByType(fieldConf.type);
	}

	if (config.addEmptyCondition) {
		const validateComboWithEmptyRecord = (field, record) => {
			const comboBox = field.up('container').down('combobox');
			const inputField = field.up('container').down("[itemId ='inputField']");
			//кнопка поиска в модальном окне вызванном из чипса
			const searchBtn = field?.up('window')?.down('toolbar')?.down('button');
			if (!comboBox || !inputField) {
				return;
			}
			const isComboInValid =
				inputField?.getValue() &&
				(field === comboBox ? record?.id?.indexOf('edi.models') >= 0 : !comboBox.getValue());
			//Переключение класса здесь решает сразу две проблемы:
			//в модальном окне чипса, при невалидном поле не добавлялся класс had-invalid
			//в FilterForm класс добавлялся с задержкой в секунду
			isComboInValid ? comboBox.addCls('had-invalid') : comboBox.removeCls('had-invalid');
			comboBox.allowBlank = !isComboInValid;
			//disable кнопки в модальном окне чипса если comboBox невалиден
			searchBtn ? searchBtn.setDisabled(isComboInValid) : null;
			comboBox.isValid();
		};
		Ext.merge(comboConf, {
			allowBlank: true,
			listeners: {
				beforeselect: validateComboWithEmptyRecord
			}
		});

		Ext.merge(fieldConf, {
			itemId: 'inputField',
			listeners: {
				change: validateComboWithEmptyRecord
			}
		});
	}

	if (fieldConf.useFieldLable) {
		Object.assign(fieldConf, {
			fieldLabel: fieldConf.useFieldLable || label || '',
			chipTitle: label,
			//дополнительный класс нужен, чтобы label не скакал при наведении
			labelClsExtra: 'label-without-hover-effect'
		});
	}

	Object.assign(config, {
		items: [
			(comboBox = createCombo({ ...comboConf, flex: 1 })),
			(field = fieldCreateMethod({ ...fieldConf, flex: 2 }))
		]
	});

	return createCompositeInput(config);
};

/**
 * Create search type selection with parameters passed in config
 * @param	{Object}	[conf]
 * @returns	{Object}	instance of Ext.form.FieldContainer
 */
const createSearchTypeSelectionFromConfig = function (conf) {
	conf = conf || {};
	return createSearchTypeSelectionField(
		conf.config,
		conf.showConditions,
		conf.isFieldContainer,
		conf.validator,
		conf.constructValueName
	);
};

/**
 * Returns field creation method by passed type parameter
 * @param	{String}	[type]	type of the field to create
 * @returns	{Function}	create***Field method (default is createTextField)
 */
const getInputMethodByType = function (type) {
	let inputMethod;
	switch (type) {
		case 'password':
			inputMethod = createPasswordField;
			break;
		case 'okeiCode':
			inputMethod = createOkeiField;
			break;
		case 'email':
			inputMethod = createEmailField;
			break;
		case 'number':
			inputMethod = createNumberField;
			break;
		case 'trigger':
			inputMethod = createTriggerField;
			break;
		case 'date':
			inputMethod = createDateField;
			break;
		case 'time':
			inputMethod = createTimeField;
			break;
		case 'combo':
			inputMethod = createCombo;
			break;
		case 'file':
			inputMethod = createFile;
			break;
		case 'link':
			inputMethod = createLink;
			break;
		case 'hidden':
			inputMethod = createHiddenField;
			break;
		case 'checkbox':
			inputMethod = createCheckbox;
			break;
		case 'daterange':
			inputMethod = createDateRangeField;
			break;
		case 'displayfield':
			inputMethod = createDisplayField;
			break;
		case 'twoColumnsLayout':
			inputMethod = createTwoColumnsLayoutFromConfig;
			break;
		case 'fieldset':
			inputMethod = createFieldSetFromConfig;
			break;
		case 'searchTypeSelection':
			inputMethod = createSearchTypeSelectionFromConfig;
			break;
		case 'tagTextField':
			inputMethod = createTagTextField;
			break;
		case 'detailLabel':
			inputMethod = createDetailLabel;
			break;
		case 'detailDateLabel':
			inputMethod = createDetailDateLabel;
			break;
		case 'container':
			inputMethod = createContainerFromConfig;
			break;
		case 'fieldblock':
			inputMethod = createFieldBlockFromConfig;
			break;
		default:
			inputMethod = createTextField;
	}
	return inputMethod;
};

const createFieldsForProductGrid = function (items, data, fieldConf, inputConf) {
	fieldConf = fieldConf ? fieldConf : {};
	inputConf = inputConf ? inputConf : {};

	delete inputConf.grid;
	Ext.applyIf(inputConf, {
		width: '100%',
		type: 'text',
		readOnly: false,
		valueSrc: data
	});

	let res = [];
	items.forEach((item) => {
		if (!item) {
			return;
		}
		let conf = {};
		Ext.applyIf(conf, item);
		Ext.applyIf(conf, inputConf);
		let type = conf.type;
		delete conf.type;
		if (type === 'daterange') {
			Ext.merge(conf, {
				fieldsConfig: {
					common: {
						valueSrc: data,
						readOnly: conf?.readOnly ?? false
					}
				}
			});
		}
		setRequiredFields(conf);
		let inputMethod;

		//To prevent disabling readonly field pass conf.disableIfReadonly: false
		if (conf.readOnly && !(conf.hasOwnProperty('disableIfReadonly') && !conf.disableIfReadonly)) {
			conf.disabled = true;
		}

		if (type === 'checkbox') {
			conf.checked = !!edi.utils.getObjectProperty(data || {}, conf.name);
		} else if (type === 'twoColumnsLayout') {
			conf.inputConfig = inputConf;
		}

		if ('function' == typeof type) {
			inputMethod = type;
		} else if (
			type === 'label' &&
			type !== 'combo' &&
			type !== 'twoColumnsLayout' &&
			type !== 'fieldset' &&
			type !== 'deliveryGrid' &&
			type !== 'container' &&
			type !== 'fieldblock'
		) {
			if (undefined === conf.valueLabel) {
				conf.valueLabel = true;
			}
			inputMethod = type === 'date' ? createDateLabel : createLabel;
		} else {
			inputMethod = getInputMethodByType(type);
		}

		let field;
		if (
			type === 'hidden' ||
			type === 'twoColumnsLayout' ||
			type === 'fieldset' ||
			type === 'deliveryGrid' ||
			type === 'container' ||
			type === 'fieldblock'
		) {
			field = inputMethod(conf, data);
		} else {
			let panelConf = Ext.applyIf(
				{
					cls: `${FIELD_BLOCK_CLS.small} ${conf.cls || ''}`,
					title:
						conf.title !== null
							? edi.i18n.getMessage(conf.title || 'column.' + edi.utils.formatName(conf.name))
							: '',
					grid: conf?.grid,
					items: inputMethod(
						Ext.apply(conf, {
							regexText: conf.regexText ? edi.i18n.getMessage(conf.regexText) : ''
						}),
						data
					)
				},
				fieldConf
			);
			delete conf.grid;

			field = createFieldBlock(panelConf);
		}

		if (conf.hasOwnProperty('isHidden')) {
			field.hidden =
				'function' == typeof conf.isHidden
					? conf.isHidden(field, data, data ? data[conf.name] : null)
					: !!conf.isHidden;
		}
		res.push(field);
	});

	return res;
};

const createFormActionField = function (config) {
	let { label, actions, actionsColumnsWidth } = config;

	actionsColumnsWidth = actionsColumnsWidth || 0.7;
	label = label || '&nbsp;';

	let labelColumnWidth = 1 - actionsColumnsWidth;
	let labelCmp = createLabel({
		text: label,
		columnWidth: labelColumnWidth
	});

	let actionsContainer = createContainer({
		columnWidth: actionsColumnsWidth,
		items: actions
	});

	return createContainer({
		layout: {
			type: 'column',
			pack: 'end',
			align: 'middle'
		},
		items: [labelCmp, actionsContainer]
	});
};

export {
	createFieldContainer,
	createDateField,
	createTimeField,
	createNumberField,
	createTriggerField,
	createEmailField,
	createPasswordField,
	createOkeiField,
	createPhoneField,
	createHiddenField,
	setRequiredFields,
	createTextField,
	createDisplayField,
	createCombo,
	createInput,
	createField,
	createFields,
	createFieldSet,
	createFile,
	createCheckbox,
	createRadio,
	createDateLabel,
	createLabel,
	createSearchTypeSelectionField,
	createSearchTypeSelectionFromConfig,
	getInputMethodByType,
	createFieldsForProductGrid,
	createFormActionField,
	createSearchTypeSelectionFieldForGrid,
	createTagTextField
};
