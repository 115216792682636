/**
 * JS scripts loading processor
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.loading');
edi.loading.processor = new (function () {
	var __self = this,
		scripts = {},
		registeredPaths = {},
		buildModules = {},
		buildModuleSequence = [],
		dependencyModule = {},
		head = edi.core.getHtmlHead(),
		buildVersion = edi.core.getBuildVersion();
	/**
	 * Loads js file and fires callback after success loading
	 * @param    {Object}   cfg				script loading config
	 * @param    {boolean}	preventLoading	prevent loading if true
	 */
	this.loadScript = function (cfg, preventLoading) {
		cfg = 'object' == typeof cfg ? cfg : {};
		if (cfg.path) {
			if (!(cfg.scriptId = getScriptIdByPath(cfg.path))) {
				cfg.loaded = false;
				cfg.scriptId = edi.core.getId();
				cfg.script = document.createElement('script');
				cfg.script.setAttribute('type', 'text/javascript');
				cfg.script.id = cfg.scriptId;
				registeredPaths[cfg.path] = cfg.scriptId;
				/**
				 * Script handler that called on script load
				 */
				var loaded = function () {
					if (!cfg.loaded) {
						//Prevent IE from calling method 2 times
						cfg.loaded = true;
						edi.core.logMessage('Loaded script ' + cfg.path, 'info');
						'function' == typeof cfg.success ? cfg.success() : null;
					}
				};
				scripts[cfg.scriptId] = cfg;
				cfg.script.src = cfg.path + '?build=' + buildVersion.versionEncoded;
				if (preventLoading) {
					setTimeout(loaded, 10);
				} else {
					if ('function' == typeof cfg.failure) {
						cfg.script.onerror = function () {
							edi.core.logMessage('Failed to load script ' + cfg.path, 'warn');
							'function' == typeof cfg.failure ? cfg.failure() : null;
						};
					}
					edi.core.logMessage('Loading script ' + cfg.path, 'info');
					if (Ext.isIE) {
						cfg.script.onload = loaded;
					} else {
						cfg.script.addEventListener('load', loaded, false);
					}
					head.appendChild(cfg.script);
				}
			}
			return scripts[cfg.scriptId];
		} else {
			edi.core.logMessage('No valid path defined for script loading', 'warn');
		}
	};
	/**
	 * Removes script from head. Note that your should care yourself about any code references left in the global scope
	 * @param    {String}    id    id of the script
	 */
	this.removeScript = function (id) {
		if (id && scripts[id]) {
			scripts[id].script.parentNode.removeChild(scripts[id].script);
			registeredPaths[scripts[id].path] = null;
			delete registeredPaths[scripts[id].path];
			scripts[id] = null;
			delete scripts[id];
		}
	};
	/**
	 * Loads passed build module required files
	 * @param    {Object}    buildModuleCfg    config of the build module dependencies and required scripts
	 */
	this.loadRequired = function (buildModuleCfg) {
		if (buildModuleCfg && buildModuleCfg.name && !buildModules[buildModuleCfg.name]) {
			buildModules[buildModuleCfg.name] = buildModuleCfg;
			if (buildModuleCfg.localizationGroup) {
				edi.core.loadLocalizationGroups = edi.core.loadLocalizationGroups.concat(
					buildModuleCfg.localizationGroup
				);
			}
			if (!dependencyModule[buildModuleCfg.name]) {
				buildModuleSequence.unshift(buildModuleCfg.name);
			} else {
				dependencyModule[buildModuleCfg.name].loaded = true;
			}
			if (buildModuleCfg.dependency && buildModuleCfg.dependency.length) {
				processDependencies(buildModuleCfg);
			} else if (isDependenciesResolved()) {
				processRequiredScripts();
			}
		}
	};
	/**
	 * Processes build module config and initiates loading of the dependent modules
	 * @param    {Object}    buildModuleCfg    config of the build module dependencies and required scripts
	 */
	var processDependencies = function (buildModuleCfg) {
		var i, name;
		for (i = buildModuleCfg.dependency.length - 1; i >= 0; i--) {
			name = buildModuleCfg.dependency[i];
			if (!dependencyModule.hasOwnProperty(name)) {
				dependencyModule[name] = {
					loaded: false
				};
				buildModuleSequence.unshift(name);
				__self.loadScript({
					path: buildPath(
						{
							file: 'loading.cfg.js'
						},
						name
					)
				});
			}
		}
	};
	/**
	 * Checks that all dependency build module configs has been loaded
	 * @returns {boolean}
	 */
	var isDependenciesResolved = function () {
		var i,
			resolved = true;
		for (i in dependencyModule) {
			if (dependencyModule.hasOwnProperty(i)) {
				if (!dependencyModule[i].loaded || !buildModules[i]) {
					resolved = false;
					break;
				}
			}
		}
		return resolved;
	};
	/**
	 * Loads all required scripts in correct order
	 */
	var processRequiredScripts = function () {
		var i,
			j,
			scripts = [],
			cfg,
			scriptsToRemove,
			hasScriptsToRemoveLocal,
			pathMap = {},
			currentPaths,
			loadHandler = null,
			getLoadHandler = function (script, handler) {
				return function () {
					script.success = 'function' == typeof handler ? handler : initSystem;
					script.failure = script.stopOnFailure
						? undefined
						: 'function' == typeof handler
						? handler
						: initSystem;
					__self.loadScript(script);
				};
			},
			initSystem = function () {
				Ext.onReady(function () {
					Ext.WindowManager = Ext.WindowMgr = Ext.create('Ext.windowManagerAdvanced');
					Ext.tip.QuickTipManager.init();
					edi.core.init();
				});
			};
		for (i = 0; i < buildModuleSequence.length; i++) {
			cfg = buildModules[buildModuleSequence[i]];
			if (cfg.requires && cfg.requires.length) {
				scriptsToRemove = [];
				currentPaths = {};
				hasScriptsToRemoveLocal = false;
				for (j = 0; j < cfg.requires.length; j++) {
					cfg.requires[j].path = buildPath(cfg.requires[j], cfg.name);
					if (currentPaths[cfg.requires[j].path]) {
						//Duplicate declaration in one file
						cfg.requires[j].skip = true;
						edi.core.logMessage(
							'Duplicate declaration found for script ' +
								cfg.requires[j].path +
								' in ' +
								buildModuleSequence[i] +
								' loading config',
							'warn'
						);
						hasScriptsToRemoveLocal = true;
					} else {
						if (pathMap[cfg.requires[j].path]) {
							scriptsToRemove.push(cfg.requires[j].path);
						} else {
							pathMap[cfg.requires[j].path] = cfg.requires[j];
							currentPaths[cfg.requires[j].path] = cfg.requires[j];
						}
					}
				}
				scripts = buildRequiredOrder(scripts, cfg.requires, scriptsToRemove, hasScriptsToRemoveLocal);
			}
		}
		scripts.reverse();
		for (i = 0; i < scripts.length; i++) {
			loadHandler = getLoadHandler(scripts[i], loadHandler);
		}
		if ('function' == typeof loadHandler) {
			loadHandler();
		}
	};
	/**
	 * Checks load order for any scripts that present in the addition and excludes them(as they will be loaded in the addition), adds addition to the end of load order and returns result
	 * @param    {Array}      loadOrder                collection that holds current loading order
	 * @param    {Array}      addition                 collection that holds dependency module scripts that should be injected into load order
	 * @param    {Array}      toRemove                 collection that holds script path that should be removed from current place in the loading order
	 * @param    {Boolean}    hasToRemoveInAddition    true to filter addition of items marked skipped
	 */
	var buildRequiredOrder = function (loadOrder, addition, toRemove, hasToRemoveInAddition) {
		if (toRemove && toRemove.length) {
			loadOrder = loadOrder.filter(function (item) {
				return !toRemove.some((it) => it === item.path);
			});
		}
		if (hasToRemoveInAddition) {
			addition = addition.filter(function (item) {
				return !item.skip;
			});
		}
		return loadOrder.concat(addition);
	};
	/**
	 * Builds script path
	 * @param    {Object}    scriptCfg     config of script
	 * @param    {String}    moduleName    name of the build module
	 * @returns {string}
	 */
	var buildPath = function (scriptCfg, moduleName) {
		var path = 'js';
		if (!scriptCfg.folder) {
			//build module root files
			path += '/' + moduleName + '/';
		} else {
			path += scriptCfg.folder + (scriptCfg.folder !== '/' ? '/' : '');
		}
		path += scriptCfg.file;
		return path;
	};
	/**
	 * Returns registered id of the script by path
	 * @param    {String}    path    path of the script
	 */
	var getScriptIdByPath = function (path) {
		return registeredPaths[path];
	};
})();
