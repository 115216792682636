import { createButton, createActionsButton, BUTTON_CLS, createTool, createAddTool } from '@UIkit/components/buttons';

/**
 * Creates add/save button
 * @param	{Object}	config	config options
 * @param	{Boolean}	isEdit	true if button used in edit mode
 * @returns	{Object}	UI.button.Button instance
 */
const createAddSaveButton = function (config, isEdit) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		cls: BUTTON_CLS.primary,
		text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.add'),
		handler() {
			edi.core.logMessage('Empty button handler called', 'info');
		}
	};
	Ext.applyIf(config, defaults);
	return createButton(config);
};

/**
 * Creates add/edit button
 * @param	{Object}	config	config options
 * @param	{Boolean}	isEdit	true if button used in edit mode
 * @returns	{Object}	UI.button.Button instance
 */
const createAddEditButton = function (config, isEdit) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		cls: BUTTON_CLS.primary,
		text: edi.i18n.getMessage(isEdit ? 'form.btn.edit' : 'form.btn.add'),
		glyph: isEdit ? edi.constants.ICONS.EDIT : edi.constants.ICONS.ADD,
		handler() {
			edi.core.logMessage('Empty button handler called', 'info');
		}
	};
	Ext.applyIf(config, defaults);
	return createButton(config);
};

/**
 * Creates save button
 * @param	{Object}	config	config options
 * @param	{Boolean}	isEdit	true if button used in edit mode
 * @returns	{Object}	UI.button.Button instance
 */
const createCreateSaveButton = function (config, isEdit) {
	config = 'object' == typeof config ? config : {};
	let defaults = {
		cls: BUTTON_CLS.primary,
		text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
		handler() {
			edi.core.logMessage('Empty button handler called', 'info');
		}
	};
	Ext.applyIf(config, defaults);
	return createButton(config);
};

/**
 * Create label button
 * @param	{Object}	config	config options
 * @returns	{Object}	UI.button.Button instance
 */
const createLink = function (config) {
	config = 'object' == typeof config ? config : {};
	let cls = ` ${BUTTON_CLS.link}`;
	config.cls ? (config.cls += cls) : (config.cls = cls);
	return createButton(config);
};

const createCreateAndSendButton = function (callback, options, isEdit) {
	return createButton(
		Ext.applyIf(options || {}, {
			text: edi.i18n.getMessage(isEdit ? 'form.btn.save.and.send' : 'form.btn.create.and.send'),
			glyph: edi.constants.ICONS.SEND,
			cls: BUTTON_CLS.primary,
			handler: callback
		})
	);
};

const createCancelButton = function (callback, options) {
	return createButton(
		Ext.applyIf(options || {}, {
			text: edi.i18n.getMessage('form.btn.close'),
			glyph: edi.constants.ICONS.CANCEL,
			cls: BUTTON_CLS.secondary,
			formBind: false,
			disabled: false,
			handler: callback
		})
	);
};

const createDownloadButton = function (callback, options) {
	return createButton(
		Ext.applyIf(options || {}, {
			text: edi.i18n.getMessage('form.btn.download'),
			glyph: edi.constants.ICONS.FILE_DOWNLOAD,
			cls: BUTTON_CLS.secondary,
			formBind: false,
			disabled: false,
			handler: callback
		})
	);
};

const createAddButton = function (callback, options) {
	return createButton(
		Ext.applyIf(options || {}, {
			cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
			tooltip: edi.i18n.getMessage('form.btn.add'),
			text: edi.i18n.getMessage('form.btn.add'),
			glyph: edi.constants.ICONS.PLUS,
			handler: callback
		})
	);
};

const createDeleteButton = function (callback, options) {
	return createButton(
		Ext.applyIf(options || {}, {
			cls: BUTTON_CLS.secondary,
			text: edi.i18n.getMessage('form.btn.delete'),
			tooltip: edi.i18n.getMessage('form.btn.delete'),
			glyph: edi.constants.ICONS.DELETE,
			disabled: true,
			handler: callback
		})
	);
};

const createCreateButton = function (callback, options) {
	return createButton(
		Ext.applyIf(options || {}, {
			cls: BUTTON_CLS.primary,
			glyph: edi.constants.ICONS.CREATE,
			text: edi.i18n.getMessage('form.btn.create'),
			handler: callback
		})
	);
};

const createSendButton = function (callback, options) {
	return createButton(
		Ext.applyIf(options || {}, {
			text: edi.i18n.getMessage('form.btn.send'),
			glyph: edi.constants.ICONS.SEND,
			cls: BUTTON_CLS.primary,
			formBind: true,
			disabled: true,
			handler: callback
		})
	);
};

const createSaveButton = function (callback, options) {
	return createButton(
		Ext.applyIf(options || {}, {
			text: edi.i18n.getMessage('form.btn.save'),
			formBind: true,
			disabled: true,
			handler: callback
		})
	);
};

export {
	createActionsButton,
	createAddSaveButton,
	createAddEditButton,
	createCreateSaveButton,
	createLink,
	createTool,
	createAddTool,
	createCreateAndSendButton,
	createCancelButton,
	createDownloadButton,
	createAddButton,
	createDeleteButton,
	createCreateButton,
	createSendButton,
	createSaveButton
};
